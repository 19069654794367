import { Link, useParams } from "react-router-dom"
import { FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


const Footer = () => {
    const { t } = useTranslation();
    const { lng } = useParams();

    return (
        <>
            <div className="bg-light py-16 mt-16">
                <div className="app-container">
                    <div className="px-8">
                        <p data-aos="fade-down" className="text-secondary font-bold "> {t('WEBSITE_MAP')}</p>
                        <div data-aos="fade-down" className="p-8 grid grid-cols-12 gap-4 gap-y-8">
                            <div className="col-span-12 sm:col-span-6 md:col-span-4 flex flex-col">
                                <div className="p-1.5">
                                    <Link to={`/${lng}/home`} className="hover:text-secondary hover:underline">{t('HOME')}</Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/about`} className="hover:text-secondary hover:underline">{t('ABOUT')}</Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/prices/alalmnyom-aluminium?CHART=AREA&INTERVAL=MONTH`} className="hover:text-secondary hover:underline">{t('MINERALS_PRICES')} </Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/opportunities`} className="hover:text-secondary hover:underline">{t('OPPORTUNITIES')}</Link>
                                </div>
                                <div className="p-1.5">
                                    <Link target="_blank" to={`/region/map`} className="hover:text-secondary hover:underline">{t('REGION_MAP')}</Link>
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-6 md:col-span-4 flex flex-col ">
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals?category=1`} className="font-bold hover:text-secondary"> {t('CLEAN_ENERGY_MINERALS')} </Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/region?category=1`} className="hover:text-secondary hover:underline"> {t('FUTURE_MINERALS_REGION')} </Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/clean-energy/arab-production`} className="hover:text-secondary hover:underline"> {t('ARAB_PRODUCTION')}</Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/value-chains?category=1`} className="hover:text-secondary hover:underline">{t('VALUE_CHAIN')} </Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/clean-energy/global-demand`} className="hover:text-secondary hover:underline">{t('GLOBAL_DEMAND')} </Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/clean-energy/technologies`} className="hover:text-secondary hover:underline">{t('CLEAN_ENERGY_TECHNOLOGIES')}  </Link>
                                </div>
                            </div>
                            <div className=" col-span-12 sm:col-span-6 md:col-span-4 flex flex-col ">
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals?category=2`} className="font-bold hover:text-secondary">{t('INDUSTRIAL_MINERALS')} </Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/region?category=2`} className="hover:text-secondary hover:underline"> {t('FUTURE_MINERALS_REGION')} </Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/industrial/arab-production`} className="hover:text-secondary hover:underline"> {t('ARAB_PRODUCTION')}</Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/value-chains?category=2`} className="hover:text-secondary hover:underline">{t('VALUE_CHAIN')} </Link>
                                </div>
                                <div className="p-1.5">
                                    <Link to={`/${lng}/future-minerals/industrial/industries`} className="hover:text-secondary hover:underline">{t('MAIN_INDUSTRIES')} </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-8">
                        <p data-aos="fade-down" className="text-secondary font-bold">{t('CONTACT_US')} </p>
                        <div data-aos="fade-down" className="p-8 grid grid-cols-12 gap-y-8">
                            <div className="col-span-12 sm:col-span-6 md:col-span-4 ">
                                <div className="item p-2">
                                    <p className="font-bold t">{t('ADDRESS')}</p>
                                    <p className="">
                                        {t('ADDSRESS')}
                                    </p>
                                </div>
                                <div className="item p-2">
                                    <p className="font-bold t">{t('PHONE')}</p>
                                    <p className="">00212537274500</p>
                                </div>
                                <div className="item p-2">
                                    <p className="font-bold t">{t('FAX')}</p>
                                    <p className="">00212537772188</p>
                                </div>
                                <div className="item p-2">
                                    <p className="font-bold t">{t('EMAIL')}</p>
                                    <p className="">aidsmo@aidsmo.org</p>
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-6 md:col-span-8 ">
                                <div className=" max-w-fit mx-auto">

                                    <div className="flex m-auto max-w-fit gap-4 ">
                                        <Link to={`https://aidsmo.org/`} target="_black" className="w-[200px] h-[200px]">
                                            <img src="/assets/logos/aidsmo_logo.webp" alt="" className="w-full object-cover" />
                                        </Link>
                                        <Link to={`/${lng}/home`} className="w-[200px] h-[200px]">
                                            <img src="/assets/logos/app_logo.svg" alt="" className="w-full object-cover" />
                                        </Link>
                                    </div>
                                    {/* social links */}
                                    <div className="flex  justify-center md:justify-start flex-row-reverse gap-4  md:my-8">
                                        <Link target="_blank" className="bg-primary text-white p-3 hover:bg-secondary">
                                            <FaXTwitter />
                                        </Link>
                                        <Link target="_blank" className="bg-primary text-white p-3 hover:bg-secondary">
                                            <FaFacebookF />

                                        </Link>
                                        <Link target="_blank" className="bg-primary text-white p-3 hover:bg-secondary">
                                            <FaYoutube />
                                        </Link>
                                        <Link target="_blank" className="bg-primary text-white p-3 hover:bg-secondary">
                                            <FaLinkedinIn />
                                        </Link>
                                        <Link target="_blank" className="bg-primary text-white p-3 hover:bg-secondary">
                                            <FaWhatsapp />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* copy right */}
            <div className=" bg-secondary">
                <p className="text-white text-center p-4">
                    {t('COPYRIGHT')}
                </p>
            </div>
        </>
    )
}

export default Footer