import { useTranslation } from "react-i18next";
import Spacing from "../componenets/UI/Spacing";
import { Helmet } from "react-helmet";

export default function FutureMineralsIndustrialArabProductionPage() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{`APFM | ${t("ARAB_PRODUCTION")}`}</title>
            </Helmet>
            <Spacing />
            <div className="app-container px-8 lg:px-0">
                <h2
                    data-aos={`fade-down`}
                    data-aos-delay={100}
                    className="text-2xl font-black mb-4"
                >
                    {t("ARAB_PRODUCTION")}
                </h2>
                <div
                    data-aos={`fade-up`}
                    data-aos-delay={100}
                    className="w-full border-2 border-muted"
                >
                    <iframe
                        title={t("ARAB_PRODUCTION")}
                        className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px]"
                        src={t("INDUSTRIES_ARAB_PRODUCTION_POWER_PI")}
                    />
                </div>
            </div>
        </>
    );
}
