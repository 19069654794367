const homeMineralsOptions = {
    direction: "rtl",
    perMove: 1,
    autoplay: true,
    loop: true,
    interval: 2500,
    arrows: false,
    pagination: false,
    rewind: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    gap: 16,
    breakpoints: {
        640: {
            perPage: 1,
        },
        768: {
            perPage: 2,
        },
        1024: {
            perPage: 3,
        },
        1280: {
            perPage: 4,
        },
    },
    perPage: 5,
};
const sponsorDiamondSilderOptions = {
    direction: "rtl",
    perMove: 1,
    autoplay: true,
    loop: true,
    interval: 3500,
    arrows: false,
    pagination: false,
    rewind: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    gap: 16,
    focus: "center",
    breakpoints: {
        640: {
            perPage: 1,
        },
        768: {
            perPage: 2,
        },
        1024: {
            perPage: 3,
        },
        1280: {
            perPage: 4,
        },
    },
    perPage: 5,
};

const homeFutureMineralsLastMonthPricesOptions = {
    direction: "rtl",
    perMove: 1,
    autoplay: true,
    loop: true,
    interval: 3000,
    arrows: false,
    pagination: false,
    rewind: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    gap: 16,
    breakpoints: {
        640: {
            perPage: 1,
        },
        1024: {
            perPage: 2,
        },
    },
    perPage: 3,
};

const OpportunitiesCountriesSliderRTLOptions = {
    direction: "rtl",
    perPage: 6,
    perMove: 1,
    autoplay: true,
    loop: true,
    interval: 2500,
    arrows: false,
    pagination: false,
    rewind: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    gap: 16,
    breakpoints: {
        640: {
            perPage: 2,
        },
        768: {
            perPage: 3,
        },
        1024: {
            perPage: 4,
        },
    },
};
const OpportunitiesCountriesSliderLTROptions = {
    direction: "ltr",
    perPage: 6,
    perMove: 1,
    autoplay: true,
    loop: true,
    interval: 2500,
    arrows: false,
    pagination: false,
    rewind: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    gap: 16,
    breakpoints: {
        640: {
            perPage: 2,
        },
        768: {
            perPage: 3,
        },
        1024: {
            perPage: 4,
        },
    },
};
const OpportunitiesMineralsOptions = {
    direction: "rtl",
    perPage: 4,
    perMove: 1,
    autoplay: true,
    loop: true,
    interval: 2500,
    arrows: false,
    pagination: false,
    rewind: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    gap: 16,
    breakpoints: {
        640: {
            perPage: 2,
        },
        768: {
            perPage: 3,
        },
        1024: {
            perPage: 4,
        },
    },
};
export { homeMineralsOptions, homeFutureMineralsLastMonthPricesOptions, OpportunitiesCountriesSliderLTROptions, OpportunitiesCountriesSliderRTLOptions, OpportunitiesMineralsOptions, sponsorDiamondSilderOptions };
