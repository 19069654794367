import React from 'react'

export default function HomeOpportunitiesMessageEn() {
    return (
        <p className="text-3xl md:text-5xl font-bold  leading-[2] md:leading-[2]">
            <span>
                Mining Investment Opportunities for
            </span>
            <br />
            <span className="text-4xl md:text-6xl font-black text-white">
                Future Minerals
            </span>
            <br />
            <span >
                in the Arab countries
            </span>
        </p>
    )
}
