import React from "react";
import { useTranslation } from "react-i18next";
import Spacing from "../componenets/UI/Spacing";
import { Helmet } from "react-helmet";

export default function FutureMineralsCleanEnergyGlobalDemandPage() {
    const { t } = useTranslation();
    return (
        <div className="app-container px-8 lg:px-0">
            <Helmet>
                <title>{`APFM | ${t("GLOBAL_DEMAND")}`}</title>
            </Helmet>
            <Spacing />
            <h2
                data-aos={`fade-down`}
                data-aos-delay={100}
                className="text-2xl font-black mb-4"
            >
                {t("GLOBAL_DEMAND")}
            </h2>
            <div
                data-aos={`fade-up`}
                data-aos-delay={100}
                className="w-full border-2 border-muted mb-4"
            >
                <iframe
                    title={t("GLOBAL_DEMAND")}
                    className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px]"
                    src={t("CLEAN_ENERGY_GLOBAL_DEMOND_POWER_PI")}
                />
            </div>
            <div
                data-aos={`fade-up`}
                data-aos-delay={100}
                className="flex flex-col relative "
            >
                <div className="font-bold">{t("SOURCE")}</div>
                <p>{t("INDUSTRIES_GLOBAL_DEMOND_SOURCE_POWER_PI")}</p>
            </div>
        </div>
    );
}
