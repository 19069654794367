
export default function HomeFutureMineralsSilderLoading() {
    return (
        <div
            className={`grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4`}
        >
            {/* Loading animation for small screens */}
            <div
                className={`animate-pulse overflow-hidden flex flex-col items-center justify-center space-y-1 border-2 border-muted col-span-1 w-full h-[218px] bg-white `}
            >
                <div className="relative h-full w-full">
                    <img
                        src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                        alt="background icon"
                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                    />
                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                        <div className="absolute top-2 right-2">
                            <img src="/assets/icons/clean-energy-color/clean-energy-icon.svg" alt="icon" className="w-8" />

                        </div>
                        <div className="h-[100px] w-[100px] bg-muted" />
                        <div className='flex flex-col items-center'>
                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                            <p className="h-[16px] w-6 bg-muted"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`animate-pulse overflow-hidden sm:flex flex-col items-center justify-center space-y-1 border-2 border-muted hidden sm:col-span-1 w-full h-[218px] `}>
                <div className="relative h-full w-full">
                    <img
                        src="/assets/icons/industrial-color/industrial-icon.svg"
                        alt="background icon"
                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                    />
                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                        <div className="absolute top-2 right-2">
                            <img src="/assets/icons/industrial-color/industrial-icon.svg" alt="icon" className="w-8" />

                        </div>
                        <div className="h-[100px] w-[100px] bg-muted" />
                        <div className='flex flex-col items-center'>
                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                            <p className="h-[16px] w-6 bg-muted"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`animate-pulse overflow-hidden md:flex flex-col items-center justify-center space-y-1 border-2 border-muted hidden md:col-span-1 w-full h-[218px] `}
            >
                <div className="relative h-full w-full">
                    <img
                        src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                        alt="background icon"
                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                    />
                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                        <div className="absolute top-2 right-2">
                            <img src="/assets/icons/clean-energy-color/clean-energy-icon.svg" alt="icon" className="w-8" />

                        </div>
                        <div className="h-[100px] w-[100px] bg-muted" />
                        <div className='flex flex-col items-center'>
                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                            <p className="h-[16px] w-6 bg-muted"></p>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`animate-pulse overflow-hidden lg:flex flex-col items-center justify-center space-y-1 border-2 border-muted hidden lg:col-span-1 w-full h-[218px] `}
            >
                <div className="relative h-full w-full">
                    <img
                        src="/assets/icons/industrial-color/industrial-icon.svg"
                        alt="background icon"
                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                    />
                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                        <div className="absolute top-2 right-2">
                            <img src="/assets/icons/industrial-color/industrial-icon.svg" alt="icon" className="w-8" />

                        </div>
                        <div className="h-[100px] w-[100px] bg-muted" />
                        <div className='flex flex-col items-center'>
                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                            <p className="h-[16px] w-6 bg-muted"></p>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`animate-pulse overflow-hidden xl:flex flex-col items-center justify-center space-y-1 border-2 border-muted hidden xl:col-span-1 w-full h-[218px] `}
            >
                <div className="relative h-full w-full">
                    <img
                        src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                        alt="background icon"
                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                    />
                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                        <div className="absolute top-2 right-2">
                            <img src="/assets/icons/clean-energy-color/clean-energy-icon.svg" alt="icon" className="w-8" />

                        </div>
                        <div className="h-[100px] w-[100px] bg-muted" />
                        <div className='flex flex-col items-center'>
                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                            <p className="h-[16px] w-6 bg-muted"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
