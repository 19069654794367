import { Link, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import i18next from "i18next";
import CleanEnergyTechnologiesArticleSource from "./technologiesArticleSource";

export default function CleanEnergyMineralsTechnologiesSolarEnergyArticle() {
  const { lng } = useParams();
  if (lng === "en"){
    return (
      <>

        <article className="mb-8">
          <div className="mb-8">
            <img
              className="w-full h-[412px] object-cover object-bottom"
              src={`/assets/images/technologies/solar-energy/solar-energy-banner.webp`}
              alt="A sleek array of solar panels glistening under the sun's rays."
              itemprop="image"
            />
          </div>
          <h1 className="text-2xl font-bold mb-4">
            Solar Energy (PV)
          </h1>

          <p className={`mb-16`}>
            Photovoltaic solar energy is the technology that uses sunlight to
            convert it into electrical energy using semiconductor panels. It is
            considered the most advanced and rapidly spreading source of clean
            energy worldwide. This technology is highly modular, ranging from
            small domestic solar power setups and rooftop installations with a
            capacity of 3-20 kilowatts, up to systems with capacities in the
            hundreds of megawatts.
            <br />
            By 2018, the installed capacity of photovoltaic solar energy reached
            485 gigawatts (according to the 2019 International Renewable Energy
            Agency report), outpacing the growth of all other technologies
            between 2017 and 2018 with a rate of 24%.
            <br />
            This trend in the installed capacity of photovoltaic solar energy is
            expected to reach 8,519 gigawatts by 2050 in countries across
            Africa, Asia, and Europe, with continued savings in costs.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            Minerals used in photovoltaic Solar Energy
          </h2>
          <p className="mb-16">
            <img
              src={`/assets/images/technologies/solar-energy/solar-energy-compound-en.webp`}
              alt="Minerals used in photovoltaic Solar Energy"
              className="max-h-[512px] w-full object-contain"
            />
          </p>

          <h2 className="text-2xl font-bold mb-4">
            Global demand for minerals used in photovoltaic Solar Energy
          </h2>
          <iframe
            className="border-2 border-muted w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px]"
            title="Global demand for minerals used in photovoltaic Solar Energy"
            src={`https://app.powerbi.com/view?r=eyJrIjoiZDRiZWM4YjgtMjFmNy00YWJiLWJhMDUtODcxNjNmYmZkZTI3IiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
          />
        </article>
        <CleanEnergyTechnologiesArticleSource
          language={`en`}
          label={`International Energy Agency 2023`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
}

if (lng === "ar") {
  return (
    <>

      <article className="mb-8">
        <div className="mb-8">
          <img
            className="w-full h-[412px] object-cover object-bottom"
            src={`/assets/images/technologies/solar-energy/solar-energy-banner.webp`}
            itemprop="image"
            alt="مجموعة من الألواح الشمسية اللامعة تحت أشعة الشمس."
          />
        </div>
        <h1 className="text-2xl font-bold mb-4">
          الطاقة الشمسية الكهروضوئية (PV)
        </h1>

        <p className={`mb-16`}>
          هي الطاقة التي تستخدم الأشعة الشمسية لتحويلها إلى طاقة كهربائية
          باستخدام ألواح أشباه المواصلات، وتعد المصدرَ الأكثر تطوراً لإنتاج
          الطاقة النظيفة في وقتنا الحاضر والأسرع انتشارا على مستوى العالم، كما
          أنها عبارة عن وحدات معيارية للغاية ويتراوح حجمها من مجموعات الطاقة
          الشمسية المنزلية الصغيرة والتركيبات على الأسطح بسعة 3-20 كيلوواط ،
          وصولاً إلى الأنظمة ذات السعة في مئات ميجاوات.
          <br />
          وصلت القدرة المركبة للطاقة الشمسية الكهروضوئية
        </p>

        <h2 className="text-2xl font-bold mb-4">
          المعادن المستخدمة في الطاقة الشمسية الكهروضوئية
        </h2>

        <div className="mb-16 flex justify-center">
          <img
            src={`/assets/images/technologies/solar-energy/solar-energy-compound-ar.webp`}
            alt="صورة معادن طاقة شمسية"
            className="max-h-[512px] w-full object-contain"
          />
        </div>
        <h2 className="text-2xl font-bold mb-4">
          الطلب العالمي على المعادن المستخدمة في الطاقة الشمسية الكهروضوئية
        </h2>
        <iframe
          title="الطلب العالمي على المعادن المستخدمة في الطاقة الشمسية الكهروضوئية"
          className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border-2 border-muted"
          src={`https://app.powerbi.com/view?r=eyJrIjoiMzI2YzQyNjUtZDQ2NC00MDEyLWI2OWItYWFhYzI3MmVkNGZjIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
        />
      </article>

      <CleanEnergyTechnologiesArticleSource
        language="ar"
        label="وكالة الطاقة الدولية 2023"
        link={`https://www.iea.org/`}
        target="_blank"
      />
    </>
  );
}
}
