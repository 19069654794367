import { Outlet, useParams } from "react-router";
import Spacing from "../UI/Spacing";
import { IoIosArrowBack, IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import i18next from 'i18next';

export default function CleanEnergyTechnologiesArticleLayout() {
  const { lng } = useParams();
  return (<>
    <Spacing />
    <div className="app-container lg:px-0 px-8">
      <div className="flex mb-4">
        <Link to={`/${lng}/future-minerals/clean-energy/technologies`} className="p-3 flex bg-primary border-2 border-primary text-white hover:bg-muted hover:border-secondary hover:text-primary">
          <IoMdArrowForward className="w-6 h-6 ltr:rotate-180" />
        </Link>
      </div>
      <Outlet />
    </div>
  </>)
}