import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

export default function SponsorsSideBar() {
  const { t } = useTranslation()
  const { lng } = useParams()
  return (
    <div className="fixed right-0 top-1/2 z-10 -translate-y-1/2 transform px-4">
      <div className="mb-2 border-2 border-white bg-info px-2 py-1 text-center text-xs text-white">{t('SPONSORS')}</div>
      <Link
        to={`/${lng}/sponsors/fnrc`}
        className="flex !h-[64px] !w-[64px] overflow-hidden border-2 border-muted bg-light p-2"
      >
        <img
          className="h-full w-full object-cover"
          src="/assets/sponsors/fnrc-logo-1.svg"
          alt=""
        />
      </Link>
    </div>
  )
}
