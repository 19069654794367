import React from 'react'

export default function HomePlatformMessageAr() {
    return (
        <p className='xl:text-5xl text-4xl font-black leading-[1.75] xl:leading-[1.8]'>
            <span className="text-secondary">
                الموارد المعدنية العربية {" "}
            </span>
            <br />
            جسر التنمية لمستقبل  {" "}
            <br />
            <span className="text-third">
                أفضل ومستدام
            </span>
        </p>
    )
}
