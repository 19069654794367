import { Link, NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiHome2Fill } from "react-icons/ri";
import CleanEnergyMineralIcon from "../../../icons/CleanEnergyMineralIcon";
import NavbarMobileDropdown from "./NavbarMobileDropdown";
const cleanEnergyLinks = [
    { label: "MINERALS_OVERVIEW", route: 'future-minerals?category=1' },
    { label: "FUTURE_MINERALS_REGION", route: 'future-minerals/region?category=1' },
    { label: "ARAB_PRODUCTION", route: 'future-minerals/clean-energy/arab-production' },
    { label: "VALUE_CHAIN", route: 'future-minerals/value-chains?category=1' },
    { label: "GLOBAL_DEMAND", route: 'future-minerals/clean-energy/global-demand' },
    { label: "CLEAN_ENERGY_TECHNOLOGIES", route: 'future-minerals/clean-energy/technologies' },
];

const industrialLinks = [
    { label: "MINERALS_OVERVIEW", route: 'future-minerals?category=2' },
    { label: "FUTURE_MINERALS_REGION", route: 'future-minerals/region?category=2' },
    { label: "ARAB_PRODUCTION", route: 'future-minerals/industrial/arab-production' },
    { label: "VALUE_CHAIN", route: 'future-minerals/value-chains?category=2' },
    { label: "MAIN_INDUSTRIES", route: 'future-minerals/industrial/industries' },
];
export default function NavbarMobileMenu({ lng }) {
    const { t } = useTranslation();

    return (
        <div className="absolute top-full left-0 right-0 bg-white shadow-md z-10">
            <ul className="flex flex-col p-4">
                <li className="font-bold py-2" >
                    <NavLink className="block py-2" to={`/${lng}/home`}>
                        {t("HOME")}
                    </NavLink>
                </li>
                <NavbarMobileDropdown lng={lng} />
                <li className="font-bold py-2" >
                    <NavLink className="block py-2" to={`/${lng}/opportunities`}>
                        {t("OPPORTUNITIES")}
                    </NavLink>
                </li>
                <li className="font-bold py-2" >
                    <NavLink className="block py-2" to={`/${lng}/prices/alalmnyom-aluminium?CHART=AREA&INTERVAL=MONTH`}>
                        {t("MINERALS_PRICES")}
                    </NavLink>
                </li>
                <li className="font-bold py-2" >
                    <Link className="block py-2" to={`/region/map`} target="_blank">
                        {t("REGION_MAP")}
                    </Link>
                </li>
            </ul>
        </div>
    );
}