import { Splide, SplideSlide } from '@splidejs/react-splide'
import { sponsorDiamondSilderOptions } from '../../config/splide-slide-options'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

export default function SponsorDiamondSlider() {
  const { lng } = useParams()
  const { t } = useTranslation()
  return (
    <div className="app-container px-8 lg:px-0">
      <div
        data-aos="fade-up"
        className="mt-[32px] flex h-[190px] flex-col justify-evenly"
      >
        <h1 className="mb-4 bg-info py-2 text-center text-[18px] font-bold text-white">{t('SPONSOR_DIAMOND')} </h1>
        <Splide
          aria-label={`Future Minerals Slider`}
          options={sponsorDiamondSilderOptions}
        >
          <SplideSlide
            className={`flex items-center justify-center`}
            title={lng == 'ar' ? 'مؤسسة الفجيرة للموارد الطبيعية' : 'fujairah natural resources corporation'}
          >
            <Link
              to={`/${lng}/sponsors/fnrc`}
              className={`h-[120px] w-[120px] border-2 border-transparent p-4 hover:border-2 hover:border-muted hover:bg-light`}
            >
              <img
                src="/assets/sponsors/fnrc-logo-1.svg"
                alt=""
              />
            </Link>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  )
}
