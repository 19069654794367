import React from 'react'
import { homeMineralsOptions } from '../../../config/splide-slide-options';
import { Splide } from '@splidejs/react-splide';
import FutureMineralCountriesCard from '../../global/FutureMineralCountriesCard';
import { useParams } from 'react-router';

export default function HomeFutureMineralsCountriesSilder({ minerals, futureMineralsCountriesSliderRef, selectedMineralCategoryId }) {
    const { lng } = useParams();

    const filteredMinerals = selectedMineralCategoryId === 0
        ? minerals
        : minerals?.filter(mineral => mineral.category_id === selectedMineralCategoryId);

    return (
        <Splide
            ref={futureMineralsCountriesSliderRef}
            aria-label={`Future Minerals Slider`}
            options={homeMineralsOptions}
        >
            {filteredMinerals?.map((mineral) => <FutureMineralCountriesCard mineral={mineral} lng={lng} link={`/${lng}/future-minerals/region/${mineral.slug}`} />)}
        </Splide>
    );
}
