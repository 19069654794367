import React from 'react'

export default function HeaderFutureMineralsPricesLoading() {
    return (
        <div className="bg-white h-[68px] w-full flex overflow-hidden">
            {[...Array(12)].map((_, i) => (
                <div key={i} className="animate-pulse flex h-full !w-[200px] bg-light after:h-10 after:flex after:my-auto after:w-[2px] after:bg-muted" />
            ))}
        </div>
    )
}
