import React, { useCallback, useEffect, useRef, useState } from "react";
import Spacing from "../componenets/UI/Spacing";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import axiosClient from "../config/axios-client";
import FutureMineralsRegionDetailsMap from "../componenets/future-minerals-region-details/FutureMineralsRegionDetailsMap";
import FutureMineralsRegionDetailsMapLoading from "../componenets/future-minerals-region-details/FutureMineralsRegionDetailsMapLoading";
import FutureMineralsRegionDetailsParagraph from "../componenets/future-minerals-region-details/FutureMineralsRegionDetailsParagraph";

export default function FutureMineralsRegionDetailsPage() {
    const { lng } = useParams();

    return (
        <div className="app-container px-8 lg:px-0">
            <Spacing />
            <div className="flex mb-4 items-center gap-4">
                <Link
                    to={`/${lng}/future-minerals/region`}
                    className="p-3 flex bg-primary border-2 border-primary text-white hover:bg-muted hover:border-secondary hover:text-primary"
                >
                    <IoMdArrowForward className="w-6 h-6 ltr:rotate-180" />
                </Link>
            </div>
            <FutureMineralsRegionDetailsMap />
            <FutureMineralsRegionDetailsParagraph />
        </div>
    );
}
