
import { useTranslation } from "react-i18next";
import HomeOverviewGridCard from "./HomeOverviewGridCard";
import { useParams } from "react-router";

export default function HomeOverviewSection() {
    const { t } = useTranslation();
    const { lng } = useParams();
    return <div className="app-container pt-16">
        <div className="grid grid-cols-12 gap-8 px-8 lg:px-0">
            <HomeOverviewGridCard
                image={"/assets/images/overview/arab-production.webp"}
                title={t('ARAB_PRODUCTION')}
                clean_energy_item={{
                    link: `/${lng}/future-minerals/clean-energy/arab-production`,
                    label: t('CLEAN_ENERGY_MINERALS')
                }}
                industrial_item={{
                    link: `/${lng}/future-minerals/industrial/arab-production`,
                    label: t('INDUSTRIAL_MINERALS')
                }}
                icon={'/assets/icons/overview/power-bi-icon.svg'}
            />
            <HomeOverviewGridCard
                image={"/assets/images/overview/value-chain.webp"}
                title={t('VALUE_CHAIN')}
                clean_energy_item={{
                    link: `/${lng}/future-minerals/value-chains?category=1`,
                    label: t('CLEAN_ENERGY_MINERALS')
                }}
                industrial_item={{
                    link: `/${lng}/future-minerals/value-chains?category=2`,
                    label: t('INDUSTRIAL_MINERALS')
                }}
                icon={'/assets/icons/overview/value-chain-icon.svg'}
            />
            <HomeOverviewGridCard
                image={"/assets/images/overview/global-demand.webp"}
                title={t('GLOBAL_DEMAND')}
                clean_energy_item={{
                    link: `/${lng}/future-minerals/clean-energy/global-demand`,
                    label: t('CLEAN_ENERGY_MINERALS')
                }}
                industrial_item={{
                    link: null,
                    label: t('INDUSTRIAL_MINERALS')
                }}
                icon={'/assets/icons/overview/power-bi-icon.svg'}

            />
            <HomeOverviewGridCard
                image={"/assets/images/overview/clean-energy-technologies.webp"}
                title={t('TECHNOLOGIES_INDUSTRIES')}
                clean_energy_item={{
                    link: `/${lng}/future-minerals/clean-energy/technologies`,
                    label: t('TECHNOLOGIES')
                }}
                industrial_item={{
                    link: `/${lng}/future-minerals/industrial/industries`,
                    label: t('INDUSTRIES')
                }}
                icon={'/assets/icons/overview/clean-energy-technologies-icon.svg'}
            />
        </div>
    </div>
}
