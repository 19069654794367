import { useParams } from "react-router";
import HomeOpportunitiesMessageAr from "./HomeOpportunitiesMessageAr";
import HomeOpportunitiesMessageEn from "./HomeOpportunitiesMessageEn";
import { Link } from "react-router-dom";

const HomeOpportunitiesSection = () => {
    const { lng } = useParams();

    return <Link to={`/${lng}/opportunities`} className="flex group bg-secondary relative h-[458px] overflow-hidden">
        <div className="absolute top-0 left-0 right-0 w-full h-full z-50 ">
            <img className="object-cover opacity-20 w-full h-full group-hover:scale-150 transition duration-300 ease-in-out" src="/assets/backgrounds/opportunities-background.svg" alt="Mining Opportunities pattern" />
        </div>
        <div className="app-container relative h-full px-8">
            <div className="absolute h-full w-full z-50 ">
                <div data-aos="fade-up" className="flex h-full items-center group-hover:scale-105 transition ease-in-out">
                    {
                        lng === "ar"
                            ? <HomeOpportunitiesMessageAr />
                            : <HomeOpportunitiesMessageEn />
                    }
                </div>
            </div>
            <div className="absolute top-0 rtl:left-0 ltr:right-0 h-full w-1/2 rtl:bg-gradient-to-l ltr:bg-gradient-to-r from-secondary via-secondary/80 to-transparent z-40"></div>
        </div>
        <div className="h-full w-1/2 absolute overflow-hidden top-0 rtl:left-0 ltr:right-0">
            <img className="object-cover w-full h-full group-hover:scale-150 transition duration-300 ease-in-out" src="/assets/images/opportunities-banner.webp" alt="Mining Opportunities" />
        </div>
    </Link>;
}

export default HomeOpportunitiesSection;