import React, { useCallback, useEffect, useState } from 'react'
import axiosClient from '../../config/axios-client';
import { useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';

export default function FutureMineralPricesAvailableList() {
    const { mineral_slug, lng } = useParams();

    const [searchParams, setSearchParams] = useSearchParams(); // Reference to the chart
    const [minerals, setMinerals] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getAvailableMinerals = useCallback(() => {
        setIsLoading(true);
        axiosClient
            .get(`/platform/minerals/available-prices`)
            .then(({ data }) => {
                setMinerals(data.minerals);

            })
            .catch((error) => {
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        getAvailableMinerals();
    }, [getAvailableMinerals]);

    if (isLoading) {
        return <div className="col-span-12 lg:col-span-3 p-2 overflow-y-scroll border-2 border-muted animate-pulse">
            <div className='flex flex-col gap-4'>
                {[...Array(10)].map(() => <div className='w-full h-12 bg-muted' />)}
            </div>
        </div>
    }

    return (
        <div className="col-span-12 lg:col-span-3 p-2 overflow-y-scroll border-2 border-muted">
            <div className='flex flex-col gap-4'>
                {minerals?.map((mineral) => <Link to={`/${lng}/prices/${mineral.slug}?${searchParams.toString()}`} className={`border-2  ${mineral_slug == mineral.slug ? "border-secondary " : "border-muted"} flex items-center gap-4`}>
                    <div className={`p-2 bg-muted`}>
                        <img className='w-8 h-8' src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`} alt="" />
                    </div>
                    <div className='font-bold'>
                        {mineral[`name_${lng}`]}
                    </div>
                </Link>)}
            </div>
        </div>
    )
}
