// src/i18n/index.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./../locales/en/translation.json";
import translationAR from "./../locales/ar/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    ar: {
      translation: translationAR,
    },
    en: {
      translation: translationEN,
    },
  },
  fallbackLng: "ar", // Default language if current language files are not found
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
