import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { CgClose } from "react-icons/cg";
import axiosClient from "../config/axios-client";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";

export default function FormPage() {
    const navigate = useNavigate();
    const { lng } = useParams();

    const [files, setFiles] = useState([{ id: uuidv4(), name: null, file: null }]);
    const [files2, setFiles2] = useState([{ id: uuidv4(), name: null, file: null }]);
    const [countries, setCountries] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isSending, setIsSending] = useState(false);

    const countryRef = useRef(null);
    const concernedAuthorityRef = useRef(null);
    const fullNameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const professionRef = useRef(null);

    const handleAddFile = () => {
        setFiles([...files, { id: uuidv4(), name: "", file: null }]);
    };

    const handleFileChange = (id, event) => {
        const newFiles = files.map((file) => (file.id === id ? { ...file, [event.target.name]: event.target.value } : file));
        setFiles(newFiles);
    };

    const handleFileUpload = (id, event) => {
        const newFiles = files.map((file) => (file.id === id ? { ...file, file: event.target.files[0] } : file));
        setFiles(newFiles);
    };

    const handleRemoveFile = (id) => {
        setFiles(files.filter((file) => file.id !== id));
    };

    const handleAddFile2 = () => {
        setFiles2([...files2, { id: uuidv4(), name: "", file: null }]);
    };

    const handleFileChange2 = (id, event) => {
        const newFiles = files2.map((file) => (file.id === id ? { ...file, [event.target.name]: event.target.value } : file));
        setFiles2(newFiles);
    };

    const handleFileUpload2 = (id, event) => {
        const newFiles = files2.map((file) => (file.id === id ? { ...file, file: event.target.files[0] } : file));
        setFiles2(newFiles);
    };

    const handleRemoveFile2 = (id) => {
        setFiles2(files2.filter((file) => file.id !== id));
    };

    const uploadFiles = (countryUser) => {
        const mergedFiles = [...files, ...files2].filter((file) => file.file !== null || file.name !== null);

        for (const file of mergedFiles) {
            const filePayload = {
                country_user_id: countryUser.id,
                file: file.file,
                name: file.name,
            };

            axiosClient
                .post(`/platform/form/countries-users/${countryUser.id}/file`, filePayload, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress((prevProgress) => ({
                            ...prevProgress,
                            [file.id]: percentCompleted,
                        }));
                    },
                })
                .then(({ data }) => {
                    toast.success(`!تم رفع الملف ${file.file.name} بنجاح`);
                })
                .catch((error) => {
                    toast.error(error.data?.error || "File upload failed");
                })
                .finally(() => {});
        }
    };

    const handleSubmitButton = () => {
        setIsSending(true);
        const payload = {
            country_id: countryRef.current.value,
            title: concernedAuthorityRef.current.value,
            full_name: fullNameRef.current.value,
            email: emailRef.current.value,
            phone_number: phoneNumberRef.current.value,
            profession: professionRef.current.value,
        };
        axiosClient
            .post("/platform/form/countries-users", payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(({ data }) => {
                uploadFiles(data.user);
            })
            .catch(() => {
                setIsSending(false);
                // setErrors(err)
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    useEffect(() => {
        axiosClient
            .get(`/platform/countries`)
            .then(({ data }) => {
                setCountries(data.countries);
            })
            .catch(() => {})
            .finally(() => {});
    }, []);

    return (
        <div
            dir="rtl"
            className="px-[16px] lg:px-0 app-container  xl:max-w-[1000px]"
        >
            <ToastContainer />
            <div className="flex py-[8px] justify-between items-center">
                <div>
                    <img
                        src={`/assets/logos/app_logo.svg`}
                        width={"70px"}
                        alt="apfrm logo"
                    />
                </div>
                <div>
                    <img
                        src={`/assets/logos/app_name.png`}
                        width={"280px"}
                        alt="apfrm logo"
                    />
                </div>
                <div>
                    <img
                        src={`/assets/logos/aidsmo_logo.webp`}
                        width={"70px"}
                        alt="apfrm logo"
                    />
                </div>
            </div>

            <div className="border border-primary mb-[32px]">
                <div className="text-[26px] font-bold bg-primary text-white py-[8px] text-center">إستمارة تعبئة بيانات المعادن بالمنطقة العربية</div>
            </div>
            <>
                <div className="flex gap-[16px] mb-[16px]">
                    <div className="flex w-full flex-col gap-[4px]">
                        <label htmlFor="">الدولة</label>
                        <select
                            placeholder="اختر الدولة"
                            ref={countryRef}
                            className="py-[0.5px] px-[8px] border border-primary"
                            name=""
                            id=""
                        >
                            <option value=""></option>
                            {countries && countries.map((country) => <option value={country.id}>{country.name_ar}</option>)}
                        </select>
                    </div>
                    <div className="flex w-full flex-col gap-[4px]">
                        <label htmlFor="">الجهة المعنية </label>
                        <input
                            ref={concernedAuthorityRef}
                            className="py-[4px] outline-none px-[8px] border border-primary"
                            type="text"
                        />
                    </div>
                </div>
                <div className="flex gap-[16px] mb-[16px]">
                    <div className="flex w-full flex-col gap-[4px] ">
                        <label htmlFor="">
                            الاسم الكامل <span className="text-[14px]">(المسؤول عن تعبئة البيانات) </span>{" "}
                        </label>
                        <input
                            ref={fullNameRef}
                            className="py-[4px] outline-none px-[8px] border border-primary"
                            type="text"
                        />
                    </div>
                    <div className="flex w-full flex-col gap-[4px]">
                        <label htmlFor="">
                            {" "}
                            المهنة <span className="text-[14px]"></span>{" "}
                        </label>
                        <input
                            ref={professionRef}
                            className="py-[4px] outline-none px-[8px] border border-primary"
                            type="text"
                        />
                    </div>
                </div>
                <div className="flex gap-[16px] mb-[32px]">
                    <div className="flex w-full flex-col gap-[4px]">
                        <label htmlFor="">
                            البريد الإلكتروني <span className="text-[14px]">(المسؤول عن تعبئة البيانات) </span>{" "}
                        </label>
                        <input
                            ref={emailRef}
                            className="py-[4px] outline-none px-[8px] border border-primary"
                            type="text"
                        />
                    </div>
                    <div className="flex w-full flex-col gap-[4px]">
                        <label htmlFor="">
                            الجوال <span className="text-[14px]">(المسؤول عن تعبئة البيانات) </span>{" "}
                        </label>
                        <input
                            ref={phoneNumberRef}
                            className="py-[4px] outline-none px-[8px] border border-primary"
                            type="text"
                        />
                    </div>
                </div>
            </>
            <h2 className="font-bold text-2xl border-r-[8px] pr-[8px] mb-[4px] border-warning">البيانات الفنية المطلوبة:</h2>
            <p className="text-right mb-[16px]">تجميع البيانات الفنية للمعادن و فرص استثمارها بالدول العربية لإدراجها بالمنصة العربية لمعادن المستقبل</p>
            <table className="border align-top items-start border-primary text-[14px] w-full mt-[16px]">
                <thead>
                    <th className="border-l border-white bg-primary text-white p-[8px]">(1) معادن الطاقة النظيفة</th>
                    <th className="border-l border-white bg-primary text-white p-[8px]">(2) المعادن والصخور الصناعية</th>
                    <th
                        colSpan={2}
                        className="border border-primary/70 bg-primary text-white p-[8px]"
                    >
                        الفرص االستثمارية التعدينية للمعادن في الدول العربية
                    </th>
                </thead>
                <tbody>
                    <tr>
                        <td
                            colSpan={2}
                            rowSpan={2}
                            className="border align-top border-primary p-[8px]"
                        >
                            <ul className="list-disc list-inside leading-7">
                                <li>
                                    <span className="font-bold"> اسم ومكان الموقع/المكمن</span>
                                    <span className="text-[14px]"> (تحديد اسماء ومكان المواقع الرئيسية او المكامن التي يتوفر بها المعدن)</span>
                                </li>
                                <li>
                                    <span className="font-bold">الوضع الجيولوجي</span>
                                    <span className="text-[14px]"> (يتم إضافة فقرة بسيطة ومختصرة عن البيئة الجيولوجية للمعدن بالدولة)</span>
                                </li>
                            </ul>
                        </td>
                        <td className="border align-top border-primary p-[8px]">(1) معادن الطاقة النظيفة</td>
                        <td className="border align-top border-primary p-[8px]">(2) المعادن والصخور الصناعية</td>
                    </tr>
                    <tr>
                        <td
                            colSpan={2}
                            className="border align-top border-primary p-[8px]"
                        >
                            <ul className="list-disc list-inside leading-7">
                                <li>اسم الموقع</li>
                                <li>الخام</li>
                                <li>المساحة (كيلومتر مربع)</li>
                                <li>تاريخ الفرصة</li>
                                <li>نوع الفرصة</li>
                            </ul>
                            <u>بالإضافة الى امكانية رفع ملف كامل عن الفرصة Pdf</u>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h4 className=" my-[16px] text-warning">صيغة الملفات المدعمة: ملف Excel للمعلومات الفنية وPdf لمرفقات الفرص الاستثمارية</h4>

            <div className="flex flex-col gap-[8px] mb-[32px]">
                {files2.map((fileInput) => (
                    <>
                        <div
                            key={fileInput.id}
                            className="w-full flex gap-[8px]"
                        >
                            <select
                                type="text"
                                name="name"
                                placeholder="اسم الملف"
                                className="py-[0.5px] w-3/12 px-[8px] border border-primary"
                                value={fileInput.name}
                                onChange={(e) => handleFileChange2(fileInput.id, e)}
                            >
                                <option value="">اسم الملف </option>
                                <option value="البيانات الفنية لمعادن الطاقة النظيفة"> (Excel) البيانات الفنية لمعادن الطاقة النظيفة </option>
                                <option value="البيانات الفنية للمعادن والصخور الصناعية">(Excel) البيانات الفنية للمعادن والصخور الصناعية</option>
                                <option value="الفرص الاستثمارية التعدينية لمعادن الطاقة النظيفة">(Excel) الفرص الاستثمارية التعدينية لمعادن الطاقة النظيفة</option>
                                <option value="الفرص الاستثمارية التعدينية للمعادن والصخور الصناعية">(Excel) الفرص الاستثمارية التعدينية للمعادن والصخور الصناعية</option>
                                <option value="اضافة مرفقات للفرص الاستثمارية التعدينية">(Pdf) اضافة مرفقات للفرص الاستثمارية التعدينية</option>
                            </select>
                            <input
                                type="file"
                                name="file"
                                accept=".pdf,.xls,.xlsx,.csv"
                                className={`py-[4px] w-full outline-none px-[8px] bg-muted`}
                                onChange={(e) => handleFileUpload2(fileInput.id, e)}
                            />
                            <button
                                type="button"
                                className="bg-danger/70 hover:bg-danger text-white w-[40px] py-[4px] px-[8px] ext-white"
                                onClick={() => handleRemoveFile2(fileInput.id)}
                            >
                                <CgClose />
                            </button>
                        </div>
                        {uploadProgress[fileInput.id] && (
                            <div className="bg-muted relative text-white text-center mb-[16px] h-[16px] transform transition ease-in duration-150">
                                <div className="flex w-full h-full items-center justify-center">
                                    <div
                                        style={{
                                            width: `${uploadProgress[fileInput.id]}%`,
                                        }}
                                        className="bg-success/50 absolute top-0 left-0 bottom-0"
                                    ></div>
                                </div>
                            </div>
                        )}
                    </>
                ))}
                <button
                    type="button"
                    className="bg-muted py-[8px] text-center mb-[16px]"
                    onClick={handleAddFile2}
                >
                    إضافة ملف آخر
                </button>
            </div>

            <h2 className="font-bold text-2xl border-r-[8px] pr-[8px] border-warning">البيانات الجيومكانية المطلوبة:</h2>
            <p className="text-right mb-[16px]">تجميع البيانات الجيومكانية للمعادن بالدول العربية لإدراجها بالخريطة المعدة بنظم المعلومات الجغرافية Arc GIS Server بالمنصة العربية لمعادن المستقبل</p>
            <table className="border border-primary text-[14px] w-full mt-[16px]">
                <thead>
                    <th
                        className="border-l border-white bg-primary text-white p-[8px]"
                        colSpan={2}
                    >
                        معادن المستقبل
                    </th>
                    <th className="border-l border-white bg-primary text-white p-[8px]">المناجم</th>
                    <th className="border-l border-white bg-primary text-white p-[8px]">المصانع</th>
                    <th className="border border-primary/70 bg-primary text-white p-[8px]">الفرص الاستثمارية التعدينية</th>
                </thead>
                <tbody>
                    <tr>
                        <td className="border border-primary p-[8px] align-top">معادن الطاقة النظيفة</td>
                        <td className="border border-primary p-[8px] align-top">المعادن والصخور الصناعية</td>
                        <td
                            className="border border-primary p-[8px] align-top"
                            rowSpan={2}
                        >
                            <ul className="list-disc list-inside">
                                <li>اسم المنجم</li>
                                <li>الإحداثيات (X,Y)</li>
                                <li>معدل الانتاج السنوي</li>
                                <li>تاريخ بدء الإنتاج</li>
                                <li>الخام المستخرج</li>
                            </ul>
                        </td>
                        <td
                            className="border border-primary p-[8px] align-top"
                            rowSpan={2}
                        >
                            <ul className="list-disc list-inside">
                                <li>اسم المصنع</li>
                                <li>الإحداثيات (X,Y)</li>
                                <li>معدل الانتاج السنوي</li>
                                <li>تاريخ بدء الإنتاج</li>
                                <li>المنتج</li>
                            </ul>
                        </td>
                        <td
                            className="border border-primary p-[8px] align-top"
                            rowSpan={2}
                        >
                            <ul className="list-disc list-inside">
                                <li>اسم الموقع</li>
                                <li>الخام</li>
                                <li>الإحداثيات (X,Y)</li>
                                <li>المساحة</li>
                                <li>نوع الفرصة </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-primary p-[8px] align-top">
                            <ul className="list-disc list-inside">
                                <li>اسم الموقع</li>
                                <li>الإحداثيات (X,Y)</li>
                                <li>رمز المعدن</li>
                                <li>المعادن المصاحبة</li>
                                <li>الصخور المحيطة</li>
                            </ul>
                        </td>
                        <td className="border border-primary p-[8px] align-top">
                            <ul className="list-disc list-inside">
                                <li>اسم الموقع</li>
                                <li>الإحداثيات (X,Y)</li>
                                <li>رمز المعدن</li>
                                <li>الصخور المحيطة</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4 className="mt-[32px] mb-[16px] text-warning">صيغة الملفات المدعمة : Shape file (shp), Excel, CSV, mxd, aprx</h4>
            <div className="flex flex-col gap-[8px]">
                {files.map((fileInput) => (
                    <>
                        <div
                            key={fileInput.id}
                            className="w-full flex gap-[8px]"
                        >
                            <select
                                type="text"
                                name="name"
                                placeholder="اسم الملف"
                                className="py-[0.5px] w-3/12 px-[8px] border border-primary"
                                value={fileInput.name}
                                onChange={(e) => handleFileChange(fileInput.id, e)}
                            >
                                <option value="">اسم الملف</option>
                                <option value="معادن الطاقة النظيفة">معادن الطاقة النظيفة</option>
                                <option value="المعادن والصخور الصناعية">المعادن والصخور الصناعية</option>
                                <option value="المناجم">المناجم</option>
                                <option value="المصانع">المصانع</option>
                                <option value="الفرص الاستثمارية التعدينية">الفرص الاستثمارية التعدينية</option>
                            </select>
                            <input
                                type="file"
                                name="file"
                                accept=".shp,.xls,.xlsx,.csv,.mxd,.aprx"
                                className={`py-[4px] w-full outline-none px-[8px] bg-muted`}
                                onChange={(e) => handleFileUpload(fileInput.id, e)}
                            />
                            <button
                                type="button"
                                className="bg-danger/70 hover:bg-danger text-white w-[40px] py-[4px] px-[8px] ext-white"
                                onClick={() => handleRemoveFile(fileInput.id)}
                            >
                                <CgClose />
                            </button>
                        </div>
                        {uploadProgress[fileInput.id] && (
                            <div className="bg-muted relative text-white text-center mb-[16px] h-[16px] transform transition ease-in duration-150">
                                <div className="flex w-full h-full items-center justify-center">
                                    <div
                                        style={{
                                            width: `${uploadProgress[fileInput.id]}%`,
                                        }}
                                        className="bg-success/50 absolute top-0 left-0 bottom-0"
                                    ></div>
                                </div>
                            </div>
                        )}
                    </>
                ))}
                <button
                    type="button"
                    className="bg-muted py-[8px] text-center mb-[16px]"
                    onClick={handleAddFile}
                >
                    إضافة ملف آخر
                </button>
                {!isSending && (
                    <button
                        onClick={handleSubmitButton}
                        className="bg-primary text-white py-[8px] text-center mb-[16px]"
                    >
                        إرسال
                    </button>
                )}
            </div>
        </div>
    );
}
