import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CleanEnergyMineralIcon from '../../../icons/CleanEnergyMineralIcon';
import IndustrialMineralsIcon from '../../../icons/IndustrialMineralsIcon';

const cleanEnergyLinks = [
    { label: "MINERALS_OVERVIEW", route: 'future-minerals?category=1' },
    { label: "FUTURE_MINERALS_REGION", route: 'future-minerals/region?category=1' },
    { label: "ARAB_PRODUCTION", route: 'future-minerals/clean-energy/arab-production' },
    { label: "VALUE_CHAIN", route: 'future-minerals/value-chains?category=1' },
    { label: "GLOBAL_DEMAND", route: 'future-minerals/clean-energy/global-demand' },
    { label: "CLEAN_ENERGY_TECHNOLOGIES", route: 'future-minerals/clean-energy/technologies' },
];

const industrialLinks = [
    { label: "MINERALS_OVERVIEW", route: 'future-minerals?category=2' },
    { label: "FUTURE_MINERALS_REGION", route: 'future-minerals/region?category=2' },
    { label: "ARAB_PRODUCTION", route: 'future-minerals/industrial/arab-production' },
    { label: "VALUE_CHAIN", route: 'future-minerals/value-chains?category=2' },
    { label: "MAIN_INDUSTRIES", route: 'future-minerals/industrial/industries' },
];

export default function NavbarDesktopDropdown({ lng }) {
    const { t } = useTranslation();
    const [isCleanEnergyOpen, setIsCleanEnergyOpen] = useState(false);
    const [isIndustrialOpen, setIsIndustrialOpen] = useState(false);

    const handleToggleCleanEnergy = () => {
        setIsCleanEnergyOpen(!isCleanEnergyOpen);
        setIsIndustrialOpen(false);
    };

    const handleToggleIndustrial = () => {
        setIsIndustrialOpen(!isIndustrialOpen);
        setIsCleanEnergyOpen(false);
    };

    return (
        <div className="absolute mt-6" >
            <div className="relative bg-light p-2 border-2 border-muted">
                <ul className="flex flex-col gap-2">
                    {/* Clean Energy Minerals */}
                    <li>
                        <div
                            onClick={handleToggleCleanEnergy}
                            className={`flex border-2 ${isCleanEnergyOpen ? "text-white bg-cleanEnergy " : "  hover:bg-cleanEnergy hover:text-white bg-white text-cleanEnergy"} border-cleanEnergy  items-center cursor-default`}
                        >
                            <div className="bg-cleanEnergy p-2">
                                <CleanEnergyMineralIcon size={22} color={"#ffff"} />
                            </div>
                            <div className="rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 whitespace-nowrap">
                                {t("CLEAN_ENERGY_MINERALS")}
                            </div>
                        </div>
                        {isCleanEnergyOpen && (
                            <div className="absolute rtl:right-full ltr:left-full rtl:mr-3 ltr:ml-3 -top-[2px] bg-light p-2 border-2 border-muted">
                                <div className="absolute top-6 rtl:-right-5 ltr:-left-5 w-3 h-3 bg-light rtl:border-t-2 rtl:border-l-2 ltr:border-r-2 ltr:border-b-2 -rotate-45" />
                                <ul className="flex flex-col gap-2 whitespace-nowrap text-white">
                                    {cleanEnergyLinks.map((link) => (
                                        <li key={link.label}>
                                            <Link
                                                className={`border-2 border-muted bg-white text-cleanEnergy py-2 rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 hover:bg-cleanEnergy hover:border-cleanEnergy hover:text-white flex`}
                                                to={`/${lng}/${link.route}`}
                                            >
                                                {t(link.label)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </li>

                    {/* Industrial Minerals */}
                    <li>
                        <div
                            onClick={handleToggleIndustrial}
                            className={`flex border-2 ${isIndustrialOpen ? "text-white bg-industrial " : "  hover:bg-industrial hover:text-white bg-white text-industrial"} border-industrial  items-center cursor-default`}
                        >
                            <div className="bg-industrial p-2">
                                <IndustrialMineralsIcon size={22} color={"#ffff"} />
                            </div>
                            <div className="rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 whitespace-nowrap">
                                {t("INDUSTRIAL_MINERALS")}
                            </div>
                        </div>
                        {isIndustrialOpen && (
                            <div className="absolute rtl:right-full ltr:left-full rtl:mr-3 ltr:ml-3 -top-[2px] bg-light border-2 border-muted p-2">
                                <div className="absolute top-[72px] rtl:-right-5 ltr:-left-5 w-3 h-3 bg-light  rtl:border-t-2 rtl:border-l-2 ltr:border-r-2 ltr:border-b-2 -rotate-45" />
                                <ul className="flex flex-col gap-2 whitespace-nowrap text-white">
                                    {industrialLinks.map((link) => (
                                        <li key={link.label}>
                                            <Link
                                                className={`border-2 border-muted bg-white text-industrial py-2 rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 hover:bg-industrial hover:border-industrial hover:text-white flex`}
                                                to={`/${lng}/${link.route}`}
                                            >
                                                {t(link.label)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </li>
                </ul>
                <div className="absolute -top-2 rtl:right-14 ltr:left-14 w-3 h-3 bg-light border-t-2 border-l-2 rotate-45" />
            </div>
        </div>
    );
}