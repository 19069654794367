import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import RegionMapIcon from '../../icons/RegionMapIcon';
import { tailwindColors } from '../../../config/global';
import PlatformNumbersGridCard from './PlatformNumbersGridCard';
import axiosClient from '../../../config/axios-client';

export default function PlatformNumbersSection() {

    const { t } = useTranslation();

    const [countreisCount, setCountreisCount] = useState(0);
    const [isCountreisCountLoading, setIsCountreiesCountLoading] = useState(true);

    const [minesCount, setMinesCount] = useState(0);
    const [isMinesCountLoading, setIsMinesCountLoading] = useState(true);

    const [opportunitiesCount, setOpportunitiesCount] = useState(0);
    const [isOpportunitiesCountLoading, setIsOpportunitiesCountLoading] = useState(true);

    const [cleanEnergyCount, setCleanEnergyCount] = useState(0);
    const [isCleanEnergyCountLoading, setIsCleanEnergyCountLoading] = useState(true);

    const [industrialCount, setIndustrialCount] = useState(0);
    const [isIndustrialCountLoading, setIsIndustrialCountLoading] = useState(true);


    const getCountreisCount = () => {
        setIsCountreiesCountLoading(true);
        axiosClient.get(`/platform/countries/count`)
            .then(({ data }) => {
                setCountreisCount(data.countries_count)
            })
            .catch((error) => { })
            .finally(() => {
                setIsCountreiesCountLoading(false);
            })
    }

    const getMinesCount = () => {
        setIsCountreiesCountLoading(true);
        axiosClient.get(`/platform/mines/count`)
            .then(({ data }) => {
                setMinesCount(data.mines_count)
            })
            .catch((error) => { })
            .finally(() => {
                setIsMinesCountLoading(false);
            })
    }

    const getOpportunitiesCount = () => {
        setIsOpportunitiesCountLoading(true);
        axiosClient.get(`/platform/opportunities/count`)
            .then(({ data }) => {
                setOpportunitiesCount(data.opportunities_count)
            })
            .catch((error) => { })
            .finally(() => {
                setIsOpportunitiesCountLoading(false);
            })
    }

    const getCleanEnergyCount = () => {
        setIsCleanEnergyCountLoading(true);
        axiosClient.get(`/platform/minerals/clean-energy/count`)
            .then(({ data }) => {
                setCleanEnergyCount(data.clean_energy_count)
            })
            .catch((error) => { })
            .finally(() => {
                setIsCleanEnergyCountLoading(false);
            })
    }

    const getIndustrialCount = () => {
        setIsIndustrialCountLoading(true);
        axiosClient.get(`/platform/minerals/industrial/count`)
            .then(({ data }) => {
                setIndustrialCount(data.industrial_count)
            })
            .catch((error) => { })
            .finally(() => {
                setIsIndustrialCountLoading(false);
            })
    }

    useEffect(() => {
        getCountreisCount();
        getMinesCount();
        getOpportunitiesCount();
        getCleanEnergyCount();
        getIndustrialCount();
    }, [])


    return (
        <div className="app-container px-8 py-16 lg:px-0">
            <h2 className='text-2xl font-black' data-aos="fade-up">
                {t('PLATFORM_NUMBERS')}
            </h2>
            <div className='mt-4 grid grid-cols-10 gap-4'>
                <PlatformNumbersGridCard
                    count={countreisCount}
                    isLoading={isCountreisCountLoading}
                    icon={'/assets/icons/primary-color/region-map-icon.svg'}
                    title={t('ARAB_COUNTRIES')}
                    key={1}
                />
                <PlatformNumbersGridCard
                    count={cleanEnergyCount}
                    isLoading={isCleanEnergyCountLoading}
                    icon={'/assets/icons/primary-color/clean-energy-icon.svg'}
                    title={t('CLEAN_ENERGY_MINERALS')}
                    key={2}
                />
                <PlatformNumbersGridCard
                    count={industrialCount}
                    isLoading={isIndustrialCountLoading}
                    icon={'/assets/icons/primary-color/industrial-icon.svg'}
                    title={t('INDUSTRIAL_MINERALS')}
                    key={3}
                />
                <PlatformNumbersGridCard
                    count={minesCount}
                    isLoading={isMinesCountLoading}
                    icon={'/assets/icons/primary-color/location-icon.svg'}
                    title={t('MINES')}
                    subTtile={t('AVAILABLE_NOW_2')}
                    key={4}
                />
                <PlatformNumbersGridCard
                    count={opportunitiesCount}
                    isLoading={isOpportunitiesCountLoading}
                    icon={'/assets/icons/primary-color/opportunities-icon.svg'}
                    title={t('OPPORTUNITIES')}
                    subTtile={t('AVAILABLE_NOW_1')}
                    key={5}
                />

            </div>
        </div>
    )
}
