
import { useParams } from "react-router-dom";
import CleanEnergyTechnologiesArticleSource from "./technologiesArticleSource";

export default function CleanEnergyMineralsTechnologiesWindEnergyArticle() {
  const { lng } = useParams();
  if (lng === "en")
    return (
      <>
        <article className="mb-8">
          <div className="mb-8">
            <img
              src="/assets/images/technologies/wind-energy/wind-energy-banner.webp"
              alt="Wind energy banner showing wind turbines generating electricity"
              className="w-full h-[412px] object-cover"
              itemprop="image"
            />
          </div>
          <h1 className="text-2xl font-bold mb-4">
            Wind Energy
          </h1>
          <p className={`mb-16`}>
            Wind energy is a type of renewable energy that uses the movement of
            wind to generate electricity, by converting the rotational motion of
            wind devices, such as wind turbines, into electrical energy using
            electric generators.
            <br />
            Wind turbines require many Minerals like copper, zinc, manganese,
            nickel, and chromium, in addition to rare earth elements, which play
            a vital role in constructing these turbines that rely on permanent
            magnets.
          </p>
          <h2 className="text-2xl font-bold mb-4">
            Minerals used in Wind Energy
          </h2>
          <div className="mb-16 flex justify-center">
            <img
              src="/assets/images/technologies/wind-energy/wind-energy-compound-en.webp"
              alt="Minerals used in wind energy"
              className="max-h-[512px] w-full object-contain"
            />
          </div>
          <h2 className="text-2xl font-bold mb-4">
            Global Demand for Minerals used in Wind Energy
          </h2>
          <iframe
            title="Global demand for Minerals used in wind energy"
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border-2 border-muted"
            src="https://app.powerbi.com/view?r=eyJrIjoiOTJkYTJhZmMtMTc1MS00OGIyLWEwY2UtM2JjY2E3MWQxOGVhIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9"
          />
        </article>
        <CleanEnergyTechnologiesArticleSource
          language="en"
          label="International Energy Agency 2023"
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
  if (lng === "ar")
    return (
      <>
        <article className="mb-8">
          <div className="mb-8">
            <img
              src="/assets/images/technologies/wind-energy/wind-energy-banner.webp"
              alt="لافتة طاقة الرياح تظهر توربينات الرياح التي تولد الكهرباء"
              className="w-full h-[412px] object-cover"
              itemprop="image"
            />
          </div>
          <h1 className="text-2xl font-bold mb-4">
            طاقة الرياح
          </h1>
          <p className={`mb-16`}>
            طاقة الرياح هي نوع من الطاقة المتجددة تستخدم حركة الرياح لتوليد
            الكهرباء، من خلال تحويل الحركة الدورانية لأجهزة الرياح، مثل
            التوربينات الهوائية، إلى طاقة كهربائية باستخدام مولدات كهربائية.
            <br />
            تتطلب توربينات الرياح العديد من المعادن مثل النحاس، الزنك، المنجنيز،
            النيكل والكروم بالإضافة الى العناصر الأرضية النادرة التي تلعب دورًا
            حيويًا في بناء هذه التوربينات التي تعتمد على المغناطيس الدائم.
          </p>
          <h2 className="text-2xl font-bold mb-4">
            المعادن المستخدمة في طاقة الرياح
          </h2>
          <div className="mb-16 flex justify-center">
            <img
              src="/assets/images/technologies/wind-energy/wind-energy-compound-ar.webp"
              alt="معادن طاقة الرياح"
              className="max-h-[512px] w-full object-contain"
            />
          </div>
          <h2 className="text-2xl font-bold mb-4">
            الطلب العالمي على المعادن المستخدمة في طاقة الرياح
          </h2>
          <iframe
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border-2 border-muted"
            title="الطلب العالمي على المعادن المستخدمة في طاقة الرياح"
            src="https://app.powerbi.com/view?r=eyJrIjoiMmZlZDEzMTQtMGU1NS00M2U2LTgyZDItOTBjOGY0OGFkZjlmIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9"
          />
        </article>
        <CleanEnergyTechnologiesArticleSource
          language="ar"
          label="2023 وكالة الطاقة الدولية"
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
}
