import React from 'react'
import { Link } from 'react-router-dom'
import HeaderFutureMineralsPriceChange from './HeaderFutureMineralsPriceChange'
import { toCurrentTimezoneHHMMA } from '../../../config/global'

export default function HeaderFutureMineralsPriceCard({ mineral, lng }) {
    return (
        <Link
            key={mineral.id}
            title={`$ ${mineral.price} / ${mineral.unit_symbol}`}
            to={`/${lng}/prices/${mineral.slug}?CHART=AREA&INTERVAL=MONTH`}
            className={`flex flex-col gap-1 py-2 px-4 min-w-[256px] border-r-2 border-muted hover:bg-muted`}
        >
            <div className="flex rtl:flex-row-reverse justify-between w-full">
                <div className="font-bold">{mineral[`name_${lng}`]}</div>
                <div className="font-bold text-secondary">{mineral.price}</div>
            </div>
            <div className="flex rtl:flex-row-reverse justify-between w-full">
                <div>{toCurrentTimezoneHHMMA(mineral.date)}</div>
                <HeaderFutureMineralsPriceChange change={mineral.change} />
            </div>
        </Link>
    )
}
