import React from 'react'
import { GoDash } from 'react-icons/go'
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'

export default function HomeFutureMineralsPricesChange({ change }) {
    if (change > 0) {

        return (
            <div className='flex ltr:flex-row-reverse justify-between border-2 bg-success/20 border-success bg-success text-success whitespace-nowrap'>
                <div className='px-2' dir='ltr'>
                    {change} %
                </div>
                <div className='p-1 text-white bg-success'>
                    <MdArrowUpward />
                </div>
            </div>
        )
    } else if (change < 0) {

        return (
            <div className='flex ltr:flex-row-reverse justify-between border-2 bg-danger/20 border-danger text-danger whitespace-nowrap'>
                <div className='px-2' dir='ltr'>
                    {change} %
                </div>
                <div className='p-1 text-white bg-danger'>
                    <MdArrowDownward />
                </div>
            </div>
        )
    } else {

        return (
            <div className='flex ltr:flex-row-reverse justify-between border-2 bg-primary/20 border-primary text-primary bg-primary whitespace-nowrap'>
                <div className='px-2' dir='ltr'>
                    {change} %
                </div>
                <div className='p-1 text-white bg-primary'>
                    <GoDash />
                </div>
            </div>
        )
    }
}