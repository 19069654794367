import React from 'react'

export default function OpportunitiesCountriesSliderLoading() {
    return (
        <div className="w-full grid grid-cols-12 gap-6 mt-4">
            <div className="col-span-1 hidden lg:block lg:col-span-2">
                <div className="h-full flex flex-col items-center justify-center">
                    <div className="w-[101px] bg-muted h-[64px] animate-pulse"></div>
                    <div className="w-[135px] bg-muted h-[18px] mt-3 animate-pulse"></div>
                </div>
            </div>
            <div className="col-span-1 hidden lg:block lg:col-span-2">
                <div className="h-full flex flex-col items-center justify-center">
                    <div className="w-[101px] bg-muted h-[64px] animate-pulse"></div>
                    <div className="w-[99px] bg-muted h-[18px] mt-3 animate-pulse"></div>
                </div>
            </div>
            <div className="col-span-1 hidden md:block md:col-span-3 lg:col-span-2">
                <div className="h-full flex flex-col items-center justify-center">
                    <div className="w-[101px] bg-muted h-[64px] animate-pulse"></div>
                    <div className="w-[120px] bg-muted h-[18px] mt-3 animate-pulse"></div>
                </div>
            </div>
            <div className="col-span-1 sm:col-span-4 hidden sm:block md:col-span-3 lg:col-span-2">
                <div className="h-full flex flex-col items-center justify-center">
                    <div className="w-[101px] bg-muted h-[64px] animate-pulse"></div>
                    <div className="w-[150px] bg-muted h-[18px] mt-3 animate-pulse"></div>
                </div>
            </div>
            <div className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
                <div className="h-full flex flex-col items-center justify-center">
                    <div className="w-[101px] bg-muted h-[64px] animate-pulse"></div>
                    <div className="w-[100px] bg-muted h-[18px] mt-3 animate-pulse"></div>
                </div>
            </div>
            <div className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
                <div className="h-full flex flex-col items-center justify-center">
                    <div className="w-[101px] bg-muted h-[64px] animate-pulse"></div>
                    <div className="w-[140px] bg-muted h-[18px] mt-3 animate-pulse"></div>
                </div>
            </div>
        </div>

    )
}
