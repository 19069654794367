import React from 'react'
import i18n from '../../../config/i18n'
import HomePlatformMessageAr from './HomePlatformMessageAr'
import HomePlatformMessageEn from './HomePlatformMessageEn'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export default function HomePlatformMessageSection() {
    const { lng } = useParams();
    const { t } = useTranslation();
    return (
        <div className="bg-light py-16">
            <div className="app-container px-8 lg:px-0">
                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-8">
                    <div data-aos="fade-down">
                        {lng === "ar" ? <HomePlatformMessageAr /> : <HomePlatformMessageEn />}
                    </div>
                    <img
                        data-aos="fade-up"
                        className={`w-full lg:w-[550px]`}
                        src={`/assets/gifs/platform-message.gif`}
                        alt={`رسم متحرك لمصادر طاقة نظيفة`}
                    />
                </div>
            </div>
        </div>
    )
}
