
export default function FutureMineralsCountriesOpportunitiesContainerLoading() {
    return <div className="mt-8 animate-pulse">
        <div className="mb-4">
            <div className="text-3xl font-black mb-4 h-8 w-52 bg-muted" />
            <div>
                <div className="flex gap-2 items-center justify-start mb-2">

                    <div className="h-10 w-10 border-2 border-muted" />

                    <div className="h-10 w-10 border-2 border-muted" />
                </div>
                <div className='grid grid-cols-12 gap-4 mb-4'>
                    <div className={`col-span-6 sm:col-span-4 md:col-span-3 border-2 flex  items-center group`}>
                        <div className={`bg-light`}>
                            <div className="h-16 w-16 p-2 rtl:border-l-2 ltr:border-r-2" />
                        </div>
                        <div className="text-center capitalize p-2 px-4 font-bold w-64" />
                    </div>
                    <div className={`col-span-6 sm:col-span-4 md:col-span-3 border-2 flex items-center group`}>
                        <div className={`bg-light`}>
                            <div className="h-16 w-16 p-2 rtl:border-l-2 ltr:border-r-2" />
                        </div>
                        <div className="text-center capitalize p-2 px-4 font-bold w-64" />
                    </div>
                    <div className={`hidden sm:col-span-4 sm:flex md:col-span-3 border-2 items-center group`}>
                        <div className={`bg-light`}>
                            <div className="h-16 w-16 p-2 rtl:border-l-2 ltr:border-r-2" />
                        </div>
                        <div className="text-center capitalize p-2 px-4 font-bold w-64" />
                    </div>
                    <div className={`hidden md:col-span-3 md:flex border-2 items-center group`}>
                        <div className={`bg-light`}>
                            <div className="h-16 w-16 p-2 rtl:border-l-2 ltr:border-r-2" />
                        </div>
                        <div className="text-center capitalize p-2 px-4 font-bold w-64" />
                    </div>
                </div>
                <div className="flex items-end gap-4">
                    <div className="h-24 w-24 bg-muted" />
                    <div className="h-10 w-full sm:w-1/2 bg-muted" />
                </div>
            </div>
        </div>
        <div className="grid grid-cols-12 my-8 gap-6">
            {
                [...Array(6)].map(() => <div className="col-span-12 md:col-span-6 border-2 border-muted">
                    <div className="p-4 px-6 bg-light font-bold select-all border-b-2 border-muted h-16" />
                    <div className="p-4 px-6 flex flex-col gap-4">
                        <div className="flex h-5 w-32 bg-muted" />
                        <div className="flex h-5 w-28 bg-muted" />
                        <div className="flex h-5 w-40 bg-muted" />
                        <div className="flex h-5 w-24 bg-muted" />
                    </div>
                    <div className="border-t-2 border-muted bg-light p-4 px-6 h-16" />
                </div>)
            }
        </div>
    </div>;
}
