import { SplideSlide } from '@splidejs/react-splide';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FutureMineralCardWrapper from './FutureMineralCardWrapper';

export default function FutureMineralCard({ mineral, lng, hasLink = true, link, children, isGridChild = false }) {
    const location = useLocation();
    const navigate = useNavigate();

    const icon = mineral.category_id === 1
        ? "/assets/icons/clean-energy-color/clean-energy-icon.svg"
        : "/assets/icons/industrial-color/industrial-icon.svg";

    const handleOnClick = (mineral_slug) => {
        const searchParams = new URLSearchParams(location.search);
        const currentMineral = searchParams.get("mineral");

        // Determine if the mineral is currently selected
        const isSelected = currentMineral === mineral_slug;

        if (!isSelected) {
            // Update the search parameter with the new mineral slug
            searchParams.set("mineral", mineral_slug);
        } else {
            // Remove the mineral parameter if it's already selected
            searchParams.delete("mineral");
        }

        // Push the updated search params to the URL
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };

    // Get the selected mineral once when needed
    const currentMineral = new URLSearchParams(location.search).get("mineral");
    const isSelected = currentMineral === mineral.slug;

    return (
        <SplideSlide
            key={mineral.id}
            className={`group overflow-hidden border-2 ${isGridChild && "col-span-12 md:col-span-4 lg:col-span-3"} ${isSelected ? "border-secondary bg-muted" : "bg-white border-muted hover:border-secondary hover:bg-muted"} text-center relative`}
            title={mineral[`name_${lng}`]}
        >
            <FutureMineralCardWrapper hasLink={hasLink} link={link} onClick={() => handleOnClick(mineral.slug)}>
                <div className="relative h-full z-5">
                    <img
                        src={icon}
                        alt="background icon"
                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                    />
                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative ">
                        <div className="absolute top-2 right-2">
                            <img src={icon} alt="icon" className="w-8" />
                        </div>
                        <img
                            className="h-[100px]"
                            src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`}
                            alt={mineral[`name_${lng}`]}
                        />
                        <div>
                            <p className="text-primary font-bold">{mineral[`name_${lng}`]}</p>
                            <p className="text-secondary font-bold ">{mineral.symbol ?? ' '}</p>
                        </div>
                    </div>
                </div>
                {children}
            </FutureMineralCardWrapper>
        </SplideSlide>
    );
}