import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useParams } from "react-router";
import axiosClient from "../../../config/axios-client";
import HeaderFutureMineralsPricesLoading from "../HeaderFutureMineralsPricesLoading";
import HeaderFutureMineralsPriceCard from "./HeaderFutureMineralsPriceCard";

export default function HeaderFutureMineralsPrices() {
  const { lng } = useParams();
  const [minerals, setMinerals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setIsLoading(true);
    getFutureMineralsPrices();
    const intervalId = setInterval(getFutureMineralsPrices, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const getFutureMineralsPrices = () => {
    axiosClient
      .get(`/platform/minerals/prices/live`)
      .then(({ data }) => {
        setMinerals(data.minerals_prices_live.sort((a, b) => a.mineral_data_source_id - b.mineral_data_source_id));
      })
      .catch((error) => {
      })
      .finally(() => {
        setIsLoading(false);
      });
  };



  if (isLoading) {
    return <HeaderFutureMineralsPricesLoading />;
  }

  return (
    <div dir={`ltr`} className={`bg-white border-b-2 `}>
      <Marquee
        speed={26}
        direction={lng === `ar` ? `left` : `right`}
        pauseOnHover
      >
        {minerals?.map((mineral) => <HeaderFutureMineralsPriceCard key={mineral.mineral_data_source_id} mineral={mineral} lng={lng} />)}
      </Marquee>
    </div>
  );
}