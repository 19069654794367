import React from "react";
import { Link } from "react-router-dom";

export default function CleanEnergyTechnolgiesGridCard({
  link,
  image,
  title,
}) {
  return (
    <Link to={link} className={`col-span-12 md:col-span-6 lg:col-span-4`}>
      <div
        className={`border-2 border-muted hover:bg-light hover:border-secondary p-4 flex flex-col items-center justify-center cursor-pointer duration-500 ease-in-out transform h-[320px]`}
      >
        <img className={`h-[128px]`} src={image} alt={title} />
        <h2 className={`font-bold mt-4 text-lg text-center`}>{title}</h2>
      </div>
    </Link>
  );
}