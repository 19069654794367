import { Link, NavLink, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiHome2Fill, RiMenu3Fill, RiCloseFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import NavbarMobileMenu from "./NavbarMobileMenu";

export default function NavbarMobile() {
    const { lng } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleOnClickChangeLanguage = (newLng) => {
        if (newLng !== lng) {
            const newUrl = location.pathname.replace(`/${lng}`, `/${newLng}`);
            i18n.changeLanguage(newLng);
            navigate(`${newUrl}?${searchParams.toString()}`);
        }
    };

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    useEffect(() => {
        setIsMenuOpen(false);
    }, [location.pathname]);
    return (
        <nav className="bg-light py-2 block md:hidden ">
            <div className="app-container px-8 flex justify-between items-center">
                <button onClick={toggleMenu}>
                    {isMenuOpen ? <RiCloseFill size={32} /> : <RiMenu3Fill className="ltr:rotate-180" size={32} />}
                </button>
                <button
                    className="py-1 px-3 border-2 border-muted hover:bg-muted hover:border-secondary"
                    onClick={() => handleOnClickChangeLanguage(lng === "en" ? "ar" : "en")}
                >
                    {lng === "en" ? "العربية" : "English"}
                </button>

            </div>

            {isMenuOpen && <NavbarMobileMenu lng={lng} closeMenu={toggleMenu} />}
        </nav>
    );
}