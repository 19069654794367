import React from 'react'
import { Link } from 'react-router-dom';

export default function FutureMineralCardWrapper({ hasLink, link, onClick, children }) {
    if (hasLink) {
        return <Link to={link} className="flex flex-col">
            {children}
        </Link>;
    }
    return (
        <div onClick={onClick} className="flex flex-col cursor-pointer">
            {children}
        </div>
    )
}
