
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { OpportunitiesCountriesSliderLTROptions, OpportunitiesCountriesSliderOptions, OpportunitiesCountriesSliderRTLOptions } from '../../config/splide-slide-options';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { useParams, useSearchParams } from 'react-router-dom';
import axiosClient from '../../config/axios-client';
import { useTranslation } from 'react-i18next';
import OpportunitiesCountriesSliderLoading from './OpportunitiesCountriesSliderLoading';
import OpportunitiesCountriesSliderItems from './OpportunitiesCountriesSliderItems';

export default function OpportunitiesCountriesSlider({ handleChangeCountry, selectedCountry }) {

  const { lng } = useParams();
  const { t } = useTranslation();

  const countriesSliderRef = useRef(null);

  const [countries, setCountries] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getCountries = useCallback(() => {
    setIsLoading(true);
    axiosClient
      .get(`/platform/countries`)
      .then(({ data }) => {
        setCountries(data.countries);
      }).catch((err) => {

      }).finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getCountries()
  }, [getCountries])

  const handleNavigation = useCallback((direction) => {
    if (isLoading) { return; }
    const adjustment = (lng === "en") ? (direction === 1 ? "+1" : "-1") : (direction === 1 ? "-1" : "+1");
    countriesSliderRef.current.splide.go(adjustment);
  }, [isLoading, lng]);

  return (
    <div data-aos-delay="300" data-aos="fade-up">
      <div className="flex gap-2 items-center justify-end">
        <button
          onClick={() => handleNavigation(1)}
          aria-label={t("NEXT")}
          className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
        >
          <GrFormNext className="h-5 w-5" />
        </button>
        <button
          onClick={() => handleNavigation(-1)}
          aria-label={t("PREVIOUS")}
          className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
        >
          <GrFormPrevious className="h-5 w-5" />
        </button>
      </div>
      {
        isLoading
          ? <OpportunitiesCountriesSliderLoading />
          : <>
            {lng == 'ar'
              ? <Splide
                ref={countriesSliderRef}
                aria-label={`countries Slider`}
                options={OpportunitiesCountriesSliderRTLOptions}
              >
                <OpportunitiesCountriesSliderItems countries={countries} lng={lng} />
              </Splide>
              : <Splide
                ref={countriesSliderRef}
                aria-label={`countries Slider`}
                options={OpportunitiesCountriesSliderLTROptions}
              >
                <OpportunitiesCountriesSliderItems countries={countries} lng={lng} />
              </Splide>
            }
          </>
      }
    </div>
  )
}
