import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next from "i18next";
import { useCallback, useRef } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useParams, useSearchParams } from "react-router-dom";
import { OpportunitiesMineralsOptions } from "../../config/splide-slide-options";
import { useTranslation } from "react-i18next";

export default function OpportunitiesMineralsSlider({ minerals }) {

  const { lng } = useParams();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const mineralsSilderRef = useRef(null);


  const handleNavigation = useCallback((direction) => {
    const adjustment = (lng === "en") ? (direction === 1 ? "+1" : "-1") : (direction === 1 ? "-1" : "+1");
    mineralsSilderRef.current.splide.go(adjustment);
  }, [lng]);

  function handleOnClickMineral(mineralId) {
    if (mineralId == searchParams.get("MINERAL")) {
      searchParams.delete("MINERAL");
    } else {

      searchParams.set("MINERAL", mineralId);
    }
    setSearchParams(searchParams);
  }



  return (
    <div data-aos-delay="500" data-aos="fade-up" className="mb-4 w-full">
      <div className="flex gap-2 items-center justify-start mb-2">
        <button
          onClick={() => handleNavigation(1)}
          aria-label={t("NEXT")}
          className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
        >
          <GrFormNext className="h-5 w-5" />
        </button>
        <button
          onClick={() => handleNavigation(-1)}
          aria-label={t("PREVIOUS")}
          className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
        >
          <GrFormPrevious className="h-5 w-5" />
        </button>
      </div>
      <div dir="ltr">
        <Splide
          ref={mineralsSilderRef}
          options={OpportunitiesMineralsOptions}
          aria-label="Minerals"
        >
          {minerals && minerals?.map((mineral) => {
            return (
              <SplideSlide
                className="rounded-none cursor-pointer"
                key={mineral.id}
              >
                <div
                  onClick={() => handleOnClickMineral(mineral.id)}
                  className={`border-2 flex  ${lng == "ar" ? "" : "flex-row-reverse"} items-center group  ${searchParams.get("MINERAL") == mineral.id ? "bg-light border-secondary" : "border-muted  hover:border-secondary"} `}
                >
                  <div className={`bg-light ${lng == "ar" ? "border-l-2" : "border-r-2"} ${searchParams.get("MINERAL") == mineral.id ? "border-secondary bg-muted" : "border-muted  group-hover:border-secondary"}`}>
                    <img
                      className="h-16 w-16 p-2"
                      src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`}
                      alt={mineral[`name_${lng}`]}
                    />
                  </div>
                  <h3 className="text-center capitalize p-2 px-4 font-bold">
                    {mineral[`name_${lng}`]}
                  </h3>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </div>

    </div >
  );
}