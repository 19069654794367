import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import axiosClient from '../../config/axios-client';

export default function FutureMineralsValueChainsDetails({ lng }) {
    const { t } = useTranslation();
    const location = useLocation();

    const [mineral, setMineral] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getFutureMineralsValueChainBySlug = () => {
        setIsLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const mineralSlug = searchParams.get("mineral");
        if (mineralSlug) {
            axiosClient
                .get(`/platform/minerals/${mineralSlug}/value-chain`)
                .then(({ data }) => {
                    setMineral(data.mineral);
                })
                .catch((error) => {
                })
                .finally(() => {
                    setIsLoading(false);
                })
        } else {
            setMineral(null)
            setIsLoading(false);
        }

    };


    useEffect(() => {
        getFutureMineralsValueChainBySlug()
    }, [location.search]);


    return <div className="mt-6">
        {isLoading
            ? <div className='animate-pulse'>
                <div className="h-16 w-full p-4 px-8 bg-primary" />
                <div className="h-[600px] w-full bg-light p-4 py-8 border-muted border-x-2" />
                <div className="h-16 w-full p-4 px-8 bg-primary" />
            </div>
            : mineral && <>
                <h2 className="text-xl font-bold bg-primary text-white p-4">
                    {mineral[`name_${lng}`]}
                </h2>
                <div className="w-full min-h-[450px] p-4 border-muted bg-light border-x-2">
                    <img className="w-full" src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.value_chain[`image_${lng}`]}`} alt={`${mineral[`name_${lng}`]} value chain`} />
                </div>
                {
                    mineral.value_chain
                    &&
                    <div className=" p-4 border-2 border-muted">
                        <div className="mb-2 font-bold">{t('SOURCE')}</div>
                        <Link to={mineral.value_chain.link} target='_blank' className="underline hover:text-secondary">{mineral.value_chain[`source_${lng}`]}</Link>
                    </div>
                }
            </>
        }

    </div>;

}
