import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
const timeIntervalItems = [
    {
        label: "1M",
        value: "MONTH",
    },
    {
        label: "3M",
        value: "QUARTER",
    },
    {
        label: "1Y",
        value: "YEAR",
    },
];

export default function FutureMineralPricesChartTimeIntervalSelect() {
    const [searchParams, setSearchParams] = useSearchParams();

    const { t } = useTranslation();
    const [selectedInterval, setSelectedInterval] = useState("MONTH");

    function handleOnClick(type) {
        setSelectedInterval(type);
        searchParams.set('INTERVAL', type);
        setSearchParams(searchParams);
    }

    return (
        <div className={`flex flex-col space-y-2 rtl:space-x-reverse`}>
            <label className={`font-bold`}>
                {t("TIME_INTERVAL")}
            </label>
            <div className={`flex space-x-2 rtl:space-x-reverse`}>
                {timeIntervalItems.map((item) => (
                    <button
                        onClick={() => handleOnClick(item.value)}
                        className={`font-bold border-2 w-[38px] h-[38px] ${searchParams.get('INTERVAL') == item.value
                            ? "border-secondary bg-light text-secondary"
                            : "hover:border-secondary hover:bg-light hover:text-secondary"
                            } p-1.5`}
                    >
                        {item.label}
                    </button>
                ))}
            </div>
        </div>
    );
}
