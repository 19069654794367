import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAreaChart, MdOutlineBarChart, MdOutlineShowChart } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom';

export default function FutureMineralPricesChartTypeSelect({ onChange }) {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedType, setSelectedType] = useState("AREA");
    function handleOnClick(type) {
        setSelectedType(type);
        searchParams.set('CHART', type);
        setSearchParams(searchParams);
    }
    return (
        <div className="flex flex-col space-y-2 rtl:space-x-reverse">
            <label className={`font-bold`}>{t("CHART_TYPE")}</label>
            <div className="flex space-x-2 rtl:space-x-reverse">
                <button
                    onClick={() => handleOnClick("AREA")}
                    className={`font-bold border-2 ${selectedType == "AREA"
                        ? "border-secondary bg-light text-secondary"
                        : "hover:border-secondary hover:bg-light hover:text-secondary"
                        } p-1.5`}
                >
                    <MdAreaChart size={22} />
                </button>
                <button
                    onClick={() => handleOnClick("BAR")}
                    className={`font-bold border-2 ${selectedType == "BAR"
                        ? "border-secondary bg-light text-secondary"
                        : "hover:border-secondary hover:bg-light hover:text-secondary"
                        } p-1.5`}
                >
                    <MdOutlineBarChart size={22} />
                </button>
                <button
                    onClick={() => handleOnClick("LINE")}
                    className={`font-bold border-2 ${selectedType == "LINE"
                        ? "border-secondary bg-light text-secondary"
                        : "hover:border-secondary hover:bg-light hover:text-secondary"
                        } p-1.5`}
                >
                    <MdOutlineShowChart size={22} />
                </button>
            </div>
        </div>
    )
}
