import { Link } from "react-router-dom";

export default function CleanEnergyTechnologiesArticleSource({ language, label, link }) {
  if (language === "ar") {
    return (
      <div>
        <div className="font-bold">
          المصدر
        </div>
        {link && (
          <Link
            className="underline hover:text-secondary"
            to={link}
            target="_blank"
          >
            {label}
          </Link>
        )}
        {!link && <u>{label}</u>}
      </div>
    );
  }
  if (language === "en") {
    return (
      <p
        className={`cursor-default`}
      >
        <b>Source</b>
        <br />
        {link && (
          <Link
            target="_blank"
            className="underline hover:text-secondary"
            to={link}
          >
            {label}
          </Link>
        )}
        {!link && <u>{label}</u>}
      </p>
    );
  }
}