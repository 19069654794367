import { Link, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import i18next from "i18next";
import CleanEnergyTechnologiesArticleSource from "./technologiesArticleSource";

export default function CleanEnergyMineralsTechnologiesElectricVehiclesArticle() {
  const { lng } = useParams();
  if (lng === "en")
    return (
      <>
        <article className="mb-8">
          <div className="mb-8">
            <img
              className="w-full h-[412px] object-cover"
              src="/assets/images/technologies/electric-vehicles/electric-vehicles-banner.webp"
              alt="A lineup of electric vehicles charging at stations, symbolizing sustainable transportation."
              itemprop="image"
            />
          </div>
          <h1 className="text-2xl font-bold mb-4">
            Electric Vehicles
          </h1>
          <p className={`mb-16`}>
            The metallic requirements for the electric vehicle sector and
            battery storage represent the largest share of demand for clean
            energy technology Minerals over the next two decades. The demand for
            Minerals used in this sector has increased nearly tenfold in the
            Stated Policies Scenario and about 30 times in the Sustainable
            Development Scenario for the period until 2040.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            The Minerals Used In Electric Vehicles
          </h2>
          <div className="mb-16 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/electric-vehicles/electric-vehicles-compound-en.webp`}
              alt={`Minerals used in electric vehicles`}
            />
          </div>

          <h2 className="text-2xl font-bold mb-4">
            Global Demand For Minerals Used In Electric Vehicles
          </h2>
          <iframe
            title={`Global demand for Minerals used in electric vehicles`}
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border-2 border-muted"
            src={`https://app.powerbi.com/view?r=eyJrIjoiMjhjZDFkZjEtNGIzYy00YjU0LWI0ZGYtNjMwYjM4OTJkNWE3IiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
          />
        </article>
        <CleanEnergyTechnologiesArticleSource
          language={`en`}
          label={`International Energy Agency 2023`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
  if (lng === "ar")
    return (
      <>

        <article className="mb-8">
          <div className="mb-6">
            <img
              className="w-full h-[412px] object-cover"
              src={`/assets/images/technologies/electric-vehicles/electric-vehicles-banner.webp`}
              alt="مجموعة من السيارات الكهربائية التي يتم شحنها في المحطات، ترمز إلى النقل المستدام."
              itemprop="image"
            />
          </div>

          <h1 className="text-2xl font-bold mb-4">
            المركبات الكهربائية
          </h1>

          <p className={`mb-16`}>
            تمثل المتطلبات المعدنية لقطاع المركبات الكهربائية وتخزين البطاريات
            النصيب الأكبرمن الطلب على معادن تقنيات الطاقة النظيفة على مدى
            العقدين المقبلين، حيث زاد الطلب على المعادن المستخدمة في هذا القطاع
            ما يقرب من عشرة أضعاف في سيناريو السياسات المعلنة وحوالي 30 مرة في
            سيناريو التنمية المستدامة خلال الفترة حتى عام 2040.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            المعادن المستخدمة في المركبات الكهربائية
          </h2>

          <div className="mb-16 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/electric-vehicles/electric-vehicles-compound-ar.webp`}
              alt="صورة المعادن المستخدمة في المركبات الكهربائية"
            />
          </div>

          <h2 className="text-2xl font-bold mb-4">
            الطلب العالمي على المعادن المستخدمة في المركبات الكهربائية
          </h2>

          <iframe
            title="الطلب العالمي على المعادن المستخدمة في المركبات الكهربائية"
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border-2 border-muted"
            src="https://app.powerbi.com/view?r=eyJrIjoiY2JmNDFjODMtMTIzZS00NTVkLThjMjktZjIxZGJjZTA1MTYwIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9"
          />
        </article>
        <CleanEnergyTechnologiesArticleSource
          language={`ar`}
          label={`2023 وكالة الطاقة الدولية`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
}
