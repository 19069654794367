import React from 'react'
import { toCurrentTimezoneHHMMA } from '../../config/global'
import HomeFutureMineralsPricesChange from '../home/future-minerals-last-month-prices/HomeFutureMineralsPricesChange'

export default function FutureMineralPricesMineralDetails({ mineral, lng }) {
    if (mineral)
        return (
            <div className='flex  border-2 border-muted'>
                <div className='bg-muted p-2'>
                    <img className='!w-16 !h-16 min-w-16 min-h-16' src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`} alt="" />
                </div>
                <div className='p-2 flex flex-col justify-between min-w-64'>
                    <div className='flex gap-4 justify-between items-center'>
                        <div className='font-bold text-xl'>
                            {mineral[`name_${lng}`]} 
                        </div>
                        <p className='font-bold text-lg text-secondary'>
                             {` (${mineral?.unit_symbol}) `}
                            <span dir='ltr'>
                                $ {mineral?.price}
                            </span>
                        </p>
                    </div>
                    <div className='flex gap-4 justify-between items-center'>
                        <div dir='ltr' className='flex bg-light border-2 border-muted py-0.25 px-2'>
                            {toCurrentTimezoneHHMMA(mineral.price_date)}
                        </div>
                        <HomeFutureMineralsPricesChange change={mineral?.change} />
                    </div>
                </div>
            </div>
        )
}
