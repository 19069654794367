import React from 'react'

export default function HomePlatformMessageEn() {
    return (
        <p className='xl:text-5xl text-4xl font-black leading-[1.75] xl:leading-[1.8]'>
            <span className="text-secondary">
                Arab Mineral Resources {" "}
            </span>
            <br />
            A bridge to a better  {" "}
            <br />
            <span className="text-third">
                & sustainable future
            </span>
        </p>
    )
}
