import React from 'react';

export default function FutureMineralsRegionGridLoading() {
  // Helper function to generate a random number for order
  const getRandomOrder = () => Math.floor(Math.random() * 12) + 1;

  return <>
    {[...Array(12)].map((_, index) => (
      <div
        key={`clean-${index}`}
        style={{ order: getRandomOrder() }} // Apply random order to clean-energy cards
        className={`animate-pulse overflow-hidden flex flex-col items-center justify-center space-y-1 border-2 border-muted col-span-12 md:col-span-4 lg:col-span-3 w-full bg-white`}
      >
        <div className="relative w-full h-[218px]">
          <img
            src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
            alt="background icon"
            className="w-36 absolute -bottom-10 -right-10 opacity-25"
          />
          <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
            <div className="absolute top-2 right-2">
              <img src="/assets/icons/clean-energy-color/clean-energy-icon.svg" alt="icon" className="w-8" />

            </div>
            <div className="h-[100px] w-[100px] bg-muted" />
            <div className='flex flex-col items-center'>
              <p className="h-[16px] w-20 bg-muted mb-2"></p>
              <p className="h-[16px] w-6 bg-muted"></p>
            </div>
          </div>
        </div>
        <div className="pt-2 pb-1.5 bg-muted w-full flex rtl:flex-row-reverse gap-2 overflow-hidden z-10">
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
        </div>
      </div>
    ))}

    {[...Array(12)].map((_, index) => (
      <div
        key={`industrial-${index}`}
        style={{ order: getRandomOrder() }} // Apply random order to industrial cards
        className={`animate-pulse overflow-hidden sm:flex flex-col items-center justify-center space-y-1 border-2 border-muted hidden sm:col-span-12 md:col-span-4 lg:col-span-3 w-full bg-white`}
      >
        <div className="relative h-[218px] w-full">
          <img
            src="/assets/icons/industrial-color/industrial-icon.svg"
            alt="background icon"
            className="w-36 absolute -bottom-10 -right-10 opacity-25"
          />
          <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
            <div className="absolute top-2 right-2">
              <img src="/assets/icons/industrial-color/industrial-icon.svg" alt="icon" className="w-8" />

            </div>
            <div className="h-[100px] w-[100px] bg-muted" />
            <div className='flex flex-col items-center'>
              <p className="h-[16px] w-20 bg-muted mb-2"></p>
              <p className="h-[16px] w-6 bg-muted"></p>
            </div>
          </div>
        </div>
        <div className="pt-2 pb-1.5 bg-muted w-full flex rtl:flex-row-reverse gap-2 overflow-hidden z-10">
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
          <div className='min-w-14 bg-white h-8'></div>
        </div>
      </div>
    ))}
  </>
}