import React from 'react'

export default function FutureMineralPricesMineralDetailsLoading() {
    return <div className='flex  border-2 border-muted'>
        <div className='bg-muted p-2 !w-20 !h-20' />
        <div className='p-2 flex gap-1 flex-col justify-between min-w-64'>
            <div className='flex gap-4 justify-between items-center'>
                <div className='h-6 w-32 bg-muted' />
                <div className='h-6 w-28 bg-muted ' />
            </div>
            <div className='flex gap-4 justify-between items-center'>
                <div className='h-5 w-20 bg-muted' />
                <div className='h-5 w-16 bg-muted' />
            </div>
        </div>
    </div>
}
