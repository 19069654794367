import React, { useCallback, useState } from "react";
import Spacing from "../componenets/UI/Spacing";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import axiosClient from "../config/axios-client";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import FutureMineralCountriesCard from "../componenets/global/FutureMineralCountriesCard";
import { Helmet } from "react-helmet";

export default function SearchPage() {
    const { t } = useTranslation();
    const { query, lng } = useParams();

    const [results, setResults] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getFutureMinerals = useCallback(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        axiosClient
            .get(`/platform/search/${query}`)
            .then(({ data }) => {
                setResults(data.results);
            })
            .catch((error) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, [query]);

    useEffect(() => {
        getFutureMinerals();
    }, [getFutureMinerals]);

    return (
        <div>
            <Helmet>
                <title>{`APFM | ${t("SEARCH_RESULTS")} : ${query}`}</title>
            </Helmet>
            <Spacing />
            <div className="app-container px-8 lg:px-0">
                <h2
                    data-aos={`fade-down`}
                    data-aos-delay={100}
                    className="text-2xl font-black mb-8"
                >
                    {t("SEARCH_RESULTS")}
                </h2>
                <div className="mb-16">
                    <h3 className="font-bold text-xl mb-4">{t("MINERALS")}</h3>
                    {isLoading ? (
                        <div className="flex flex-col gap-4">
                            {[...Array(3)].map((_, index) => (
                                <div key={index}>
                                    <div className="flex border-2 border-muted animate-pulse">
                                        <div className="rtl:border-l-2 ltr:border-r-2 border-muted">
                                            <div className="!h-32 !w-32 min-w-32 p-4 bg-light" />
                                        </div>
                                        <div className="flex flex-col w-full p-4">
                                            <div className="h-7 w-32 bg-muted mb-4" />
                                            <div className="w-full h-3 bg-muted mb-2" />
                                            <div className="w-full h-3 bg-muted mb-2" />
                                            <div className="w-1/2 h-3 bg-muted" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-col gap-4">
                            {results?.minerals.map((mineral) => (
                                <div
                                    data-aos="fade-left"
                                    data-aos-delay={200}
                                    key={mineral.id}
                                >
                                    <Link
                                        to={`/${lng}/future-minerals/${mineral.slug}`}
                                        className="flex cursor-pointer hover:bg-light transform transition duration-300 ease-in-out border-2 border-muted"
                                    >
                                        <div className="rtl:border-l-2 ltr:border-r-2 border-muted">
                                            <img
                                                className="!h-32 min-w-32 p-4"
                                                src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
                                                alt={mineral[`name_${lng}`]}
                                            />
                                        </div>
                                        <div className="flex flex-col  p-4">
                                            <h3 className="flex font-bold text-xl capitalize">
                                                {mineral[`name_${lng}`]} (<span className="text-secondary">{mineral.symbol}</span>)
                                            </h3>
                                            <div
                                                className="line-clamp-3"
                                                dangerouslySetInnerHTML={{ __html: mineral[`description_${lng}`] }}
                                            />
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div>
                    <h3 className="font-bold text-xl mb-4">{t("FUTURE_MINERALS_REGION")}</h3>
                    {isLoading ? (
                        <div className={`grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4`}>
                            <div className={`animate-pulse overflow-hidden flex flex-col items-center justify-center space-y-1 border-2 border-muted col-span-1 w-full bg-white `}>
                                <div className="relative w-full  h-[218px]">
                                    <img
                                        src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                                        alt="background icon"
                                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                                    />
                                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                                        <div className="absolute top-2 right-2">
                                            <img
                                                src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                                                alt="icon"
                                                className="w-8"
                                            />
                                        </div>
                                        <div className="h-[100px] w-[100px] bg-muted" />
                                        <div className="flex flex-col items-center">
                                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                                            <p className="h-[16px] w-6 bg-muted"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2 pb-1.5 bg-muted w-full flex rtl:flex-row-reverse gap-2 overflow-hidden z-10">
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                </div>
                            </div>
                            <div className={`animate-pulse overflow-hidden sm:flex flex-col items-center justify-center space-y-1 border-2 border-muted hidden sm:col-span-1 w-full bg-white`}>
                                <div className="relative w-full h-[218px]">
                                    <img
                                        src="/assets/icons/industrial-color/industrial-icon.svg"
                                        alt="background icon"
                                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                                    />
                                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                                        <div className="absolute top-2 right-2">
                                            <img
                                                src="/assets/icons/industrial-color/industrial-icon.svg"
                                                alt="icon"
                                                className="w-8"
                                            />
                                        </div>
                                        <div className="h-[100px] w-[100px] bg-muted" />
                                        <div className="flex flex-col items-center">
                                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                                            <p className="h-[16px] w-6 bg-muted"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2 pb-1.5 bg-muted w-full flex rtl:flex-row-reverse gap-2 overflow-hidden z-10">
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                </div>
                            </div>
                            <div className={`animate-pulse overflow-hidden md:flex flex-col items-center justify-center space-y-1 border-2 border-muted hidden md:col-span-1 w-full bg-white`}>
                                <div className="relative w-full h-[218px]">
                                    <img
                                        src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                                        alt="background icon"
                                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                                    />
                                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                                        <div className="absolute top-2 right-2">
                                            <img
                                                src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                                                alt="icon"
                                                className="w-8"
                                            />
                                        </div>
                                        <div className="h-[100px] w-[100px] bg-muted" />
                                        <div className="flex flex-col items-center">
                                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                                            <p className="h-[16px] w-6 bg-muted"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2 pb-1.5 bg-muted w-full flex rtl:flex-row-reverse gap-2 overflow-hidden z-10">
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                </div>
                            </div>
                            <div className={`animate-pulse overflow-hidden lg:flex flex-col items-center justify-center space-y-1 border-2 border-muted hidden lg:col-span-1 w-full bg-white`}>
                                <div className="relative h-[218px] w-full">
                                    <img
                                        src="/assets/icons/industrial-color/industrial-icon.svg"
                                        alt="background icon"
                                        className="w-36 absolute -bottom-10 -right-10 opacity-25"
                                    />
                                    <div className="p-4 pt-10 flex flex-col items-center gap-4 relative z-10">
                                        <div className="absolute top-2 right-2">
                                            <img
                                                src="/assets/icons/industrial-color/industrial-icon.svg"
                                                alt="icon"
                                                className="w-8"
                                            />
                                        </div>
                                        <div className="h-[100px] w-[100px] bg-muted" />
                                        <div className="flex flex-col items-center">
                                            <p className="h-[16px] w-20 bg-muted mb-2"></p>
                                            <p className="h-[16px] w-6 bg-muted"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2 pb-1.5 bg-muted w-full flex rtl:flex-row-reverse gap-2 overflow-hidden z-10">
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                    <div className="min-w-14 bg-white h-8"></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            data-aos={`fade-up`}
                            data-aos-delay={100}
                            className="grid grid-cols-12 gap-4"
                        >
                            {results?.minerals_region.map(
                                (mineral) =>
                                    mineral.countries.length != 0 && (
                                        <FutureMineralCountriesCard
                                            isGridChild={true}
                                            mineral={mineral}
                                            link={`/${lng}/future-minerals/region/${mineral.slug}${mineral.slug == "altylyryom-tellurium" ? "?country=22" : ""}`}
                                            key={mineral.id}
                                            lng={lng}
                                            isMarqueeSlider={false}
                                        />
                                    )
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
