import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CleanEnergyMineralIcon from '../../../icons/CleanEnergyMineralIcon';
import IndustrialMineralsIcon from '../../../icons/IndustrialMineralsIcon';

const cleanEnergyLinks = [
    { label: "MINERALS_OVERVIEW", route: 'future-minerals?category=1' },
    { label: "FUTURE_MINERALS_REGION", route: 'future-minerals/region?category=1' },
    { label: "ARAB_PRODUCTION", route: 'future-minerals/clean-energy/arab-production' },
    { label: "VALUE_CHAIN", route: 'future-minerals/value-chains?category=1' },
    { label: "GLOBAL_DEMAND", route: 'future-minerals/clean-energy/global-demand' },
    { label: "CLEAN_ENERGY_TECHNOLOGIES", route: 'future-minerals/clean-energy/technologies' },
];

const industrialLinks = [
    { label: "MINERALS_OVERVIEW", route: 'future-minerals?category=2' },
    { label: "FUTURE_MINERALS_REGION", route: 'future-minerals/region?category=2' },
    { label: "ARAB_PRODUCTION", route: 'future-minerals/industrial/arab-production' },
    { label: "VALUE_CHAIN", route: 'future-minerals/value-chains?category=2' },
    { label: "MAIN_INDUSTRIES", route: 'future-minerals/industrial/industries' },
];

export default function NavbarDesktopDropdown({ lng }) {
    const { t } = useTranslation();
    const [isFutureMineralsOpen, setIsFutureMineralsOpen] = useState(false); // Toggle for Future Minerals
    const [isCleanEnergyOpen, setIsCleanEnergyOpen] = useState(false); // Toggle for Clean Energy
    const [isIndustrialOpen, setIsIndustrialOpen] = useState(false); // Toggle for Industrial Minerals

    // Handle toggling the main Future Minerals dropdown
    const handleToggleFutureMinerals = () => {
        setIsFutureMineralsOpen(!isFutureMineralsOpen);
        setIsCleanEnergyOpen(false);
        setIsIndustrialOpen(false);
    };

    // Handle toggling Clean Energy sub-section
    const handleToggleCleanEnergy = () => {
        setIsCleanEnergyOpen(!isCleanEnergyOpen);
        setIsIndustrialOpen(false);
    };

    // Handle toggling Industrial Minerals sub-section
    const handleToggleIndustrial = () => {
        setIsIndustrialOpen(!isIndustrialOpen);
        setIsCleanEnergyOpen(false);
    };

    return (
        <ul>
            {/* Future Minerals Main Dropdown */}
            <li className="font-bold py-2">
                <div className="cursor-pointer select-none whitespace-nowrap" onClick={handleToggleFutureMinerals}>
                    {t("FUTURE_MINERALS")}
                </div>

                {/* If Future Minerals is open, show both sub-options */}
                {isFutureMineralsOpen && (
                    <div className="p-4 pb-0 flex flex-col gap-4">
                        {/* Clean Energy Minerals Sub-dropdown */}
                        <div>
                            <div onClick={handleToggleCleanEnergy} className="flex border-2  border-cleanEnergy  items-center cursor-default">
                                <div className="bg-cleanEnergy p-2">
                                    <CleanEnergyMineralIcon size={22} color={"#ffff"} />
                                </div>
                                <div className="rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 whitespace-nowrap text-cleanEnergy">
                                    {t("CLEAN_ENERGY_MINERALS")}
                                </div>
                            </div>
                            {isCleanEnergyOpen && (
                                <ul className="flex flex-col p-4 bg-cleanEnergy">
                                    {cleanEnergyLinks.map((link) => (
                                        <li key={link.label}>
                                            <Link className="p-2 flex text-white" to={`/${lng}/${link.route}`}>
                                                {t(link.label)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div>

                            {/* Industrial Minerals Sub-dropdown */}
                            <div onClick={handleToggleIndustrial} className="flex border-2  border-industrial  items-center cursor-default">
                                <div className="bg-industrial p-2">
                                    <IndustrialMineralsIcon size={22} color={"#ffff"} />
                                </div>
                                <div className="rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 whitespace-nowrap text-industrial">
                                    {t("INDUSTRIAL_MINERALS")}
                                </div>
                            </div>
                            {isIndustrialOpen && (
                                <ul className="flex flex-col p-4 bg-industrial">
                                    {industrialLinks.map((link) => (
                                        <li key={link.label}>
                                            <Link className="p-2 flex text-white" to={`/${lng}/${link.route}`}>
                                                {t(link.label)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                )}
            </li>
        </ul>
    );
}