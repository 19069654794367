import React from "react";
import Spacing from "../componenets/UI/Spacing";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function FutureMineralsIndustriesPage() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{`APFM | ${t("INDUSTRIES")}`}</title>
            </Helmet>
            <Spacing />
            <div className="app-container lg:px-0 px-8">
                <h2
                    data-aos={`fade-down`}
                    data-aos-delay={100}
                    className="text-2xl font-black mb-4"
                >
                    {t("INDUSTRIES")}
                </h2>

                <div className="grid grid-cols-12 gap-4 ">
                    {t("INDUSTRIES_LIST", { returnObjects: true }).map((industria) => (
                        <div
                            data-aos={`fade-up`}
                            data-aos-delay={100}
                            className=" col-span-12 lg:col-span-4 border-2 bg-light border-muted p-4 relative min-h-[320px]"
                        >
                            <h1 className="font-bold text-2xl">{industria.TITLE}</h1>
                            <h2 className="font-bold mt-2 text-info">{industria.SUB_TITLE}</h2>
                            <div className="p-8">
                                <ul className="list-disc space-y-1 text-third">
                                    {industria.MINERALS.map((mineral) => (
                                        <li>{mineral}</li>
                                    ))}
                                </ul>
                            </div>
                            <img
                                className="absolute bottom-4 rtl:left-4 ltr:right-4 h-32 w-auto object-contain "
                                src={industria.ICON}
                                alt=""
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
