import { useEffect, useState } from "react";
import { FiArrowUp } from "react-icons/fi";

export default function BackToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY;
            setIsVisible(scrolled > 200);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        isVisible && (
            <div className="fixed right-4 bottom-20">
                <button
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    className="border-2 border-secondary bg-secondary/60 hover:bg-secondary p-2 text-white"
                >
                    <FiArrowUp size={28} />
                </button>
            </div>
        )
    );
}