import { Link, NavLink, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiHome2Fill } from "react-icons/ri";
import NavBarDesktopSearchButton from "./NavBarDesktopSearchButton";
import NavbarDesktopFutureMineralsMenu from "./NavbarDesktopFutureMineralsMenu";

export default function NavbarDesktop() {
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  // Functions
  const handleOnClickChangeLanguage = (newLng) => {
    if (newLng !== lng) {
      const newUrl = location.pathname.replace(`/${lng}`, `/${newLng}`);
      i18n.changeLanguage(newLng);
      navigate(`${newUrl}?${searchParams.toString()}`);
    }
  };



  return <nav className="bg-light md:block hidden py-2">
    <div className="app-container px-8 lg:px-0">
      <div className="flex justify-between items-center font-bold">
        <div className="flex items-center gap-8">
          <Link className="text-secondary" title={t("HOME")} to={`/${lng}/home`}>
            <RiHome2Fill size={32} />
          </Link>
          <ul className="flex items-center gap-1">
            <NavbarDesktopFutureMineralsMenu />
            <li>
              <div className="h-6 w-[2px] bg-muted " />
            </li>
            <li>
              <NavLink className={`py-1.5 px-3 whitespace-nowrap `} to={`/${lng}/opportunities`}>{t("OPPORTUNITIES")}</NavLink>
            </li>
            <li>
              <div className="h-6 w-[2px] bg-muted" />
            </li>
            <li>
              <NavLink className={`py-1.5 px-3 whitespace-nowrap `} to={`/${lng}/prices/alalmnyom-aluminium?CHART=AREA&INTERVAL=MONTH`}>{t("MINERALS_PRICES")}</NavLink>
            </li>
            <li>
              <div className="h-6 w-[2px] bg-muted" />
            </li>
            <li>
              <Link className={`py-1.5 px-3 whitespace-nowrap `} to={`/region/map`} target="_blank">{t("REGION_MAP")}</Link>
            </li>
          </ul>
        </div>
        <div className="flex gap-2 items-center">
          <button className="py-1.5 px-5 border-2 border-muted hover:bg-muted hover:border-secondary" onClick={() => handleOnClickChangeLanguage(lng === "en" ? "ar" : "en")}>
            {lng === "en" ? "العربية" : "English"}
          </button>
          <NavBarDesktopSearchButton />
        </div>
      </div>
    </div>
  </nav>;
}
