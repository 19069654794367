import React from "react";
import Spacing from "../componenets/UI/Spacing";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaLocationArrow, FaPhone, FaSnapchatGhost, FaVoicemail, FaYoutube } from "react-icons/fa";
import { FaLinkedin, FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function SponsorDetailsPage() {
    const { sponsor_slug } = useParams();
    const { t } = useTranslation();

    return (
        <div className="app-container px-8 lg:px-0">
            <Helmet>
                <title>{`APFM | ${t("SPONSOR_DIAMOND")}`}</title>
            </Helmet>
            <Spacing />
            <div
                data-aos={`fade-down`}
                data-aos-delay={100}
                className="mb-4"
            >
                <h2 className="text-2xl font-black">{t("SPONSOR_DIAMOND")}</h2>
            </div>
            <div
                data-aos={`fade-up`}
                data-aos-delay={100}
            >
                {t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug] && (
                    <div>
                        <div className="bg-light p-8 border-2 border-muted flex flex-col lg:flex-row mb-16">
                            <div className="lg:rtl:border-l-2 lg:rtl:pl-8 lg:ltr:border-r-2 lg:ltr:pr-8">
                                <img
                                    className="object-cover !w-64 !h-64 "
                                    src={t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug].logo}
                                    alt=""
                                />
                            </div>
                            <div className="lg:px-8">
                                <h2 className="text-3xl font-bold mb-8 ">{t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug].name}</h2>
                                <div className="space-y-2">
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <FaLocationDot />
                                        </div>
                                        <div>{t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug].country}</div>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <FaPhone />
                                        </div>
                                        <div dir="ltr">{t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug].phone}</div>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <MdEmail />
                                        </div>
                                        <div>{t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug].email}</div>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <TbWorldWww />
                                        </div>
                                        <Link
                                            target="_blank"
                                            to={t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug].website}
                                        >
                                            {t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug].website}
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex gap-2 mt-8">
                                    <Link
                                        target="_blank"
                                        className="p-2 bg-secondary text-white hover:bg-muted hover:border-secondary border-2 border-secondary hover:text-secondary"
                                        to={`https://www.linkedin.com/company/fujairah-natural-resources-corporation`}
                                    >
                                        <FaLinkedinIn />
                                    </Link>
                                    <Link
                                        target="_blank"
                                        className="p-2 bg-secondary text-white hover:bg-muted hover:border-secondary border-2 border-secondary hover:text-secondary"
                                        to={`https://twitter.com/fnrcfujairah`}
                                    >
                                        <FaXTwitter />
                                    </Link>
                                    <Link
                                        target="_blank"
                                        className="p-2 bg-secondary text-white hover:bg-muted hover:border-secondary border-2 border-secondary hover:text-secondary"
                                        to={`https://www.youtube.com/user/fnrcfujairah`}
                                    >
                                        <FaYoutube />
                                    </Link>
                                    <Link
                                        target="_blank"
                                        className="p-2 bg-secondary text-white hover:bg-muted hover:border-secondary border-2 border-secondary hover:text-secondary"
                                        to={`https://www.facebook.com/fnrcuae/`}
                                    >
                                        <FaFacebookF />
                                    </Link>
                                    <Link
                                        target="_blank"
                                        className="p-2 bg-secondary text-white hover:bg-muted hover:border-secondary border-2 border-secondary hover:text-secondary"
                                        to={`https://www.snapchat.com/add/fnrcgov`}
                                    >
                                        <FaSnapchatGhost />
                                    </Link>
                                    <Link
                                        target="_blank"
                                        className="p-2 bg-secondary text-white hover:bg-muted hover:border-secondary border-2 border-secondary hover:text-secondary"
                                        to={`https://www.instagram.com/fnrcfujairah/?igshid=MmU2YjMzNjRlOQ%3D%3D`}
                                    >
                                        <FaInstagram />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-2xl font-bold mb-4">{t("DESCRIPTION")}</h2>
                            <article>
                                <p
                                    className="text-justify "
                                    dangerouslySetInnerHTML={{
                                        __html: t("SPONSORS_LIST", { returnObjects: true })[sponsor_slug].description,
                                    }}
                                />
                            </article>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
