import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom'
import NavbarDesktopDropdown from './NavbarDesktopDropdown';

export default function NavbarDesktopFutureMineralsMenu() {
    const { t } = useTranslation();
    const { lng } = useParams();
    const location = useLocation();
    const [isFutureMineralsOpen, setIsFutureMineralsOpen] = useState(false);

    const handleToggleFutureMinerals = () => {
        setIsFutureMineralsOpen(!isFutureMineralsOpen);
    };

    useEffect(() => {
        setIsFutureMineralsOpen(false);
    }, [location.pathname]);


    return (
        <>
            {isFutureMineralsOpen && <div onClick={handleToggleFutureMinerals} className="absolute z-10 top-0 left-0 bottom-0 min-h-screen w-full" />}
            <li className="relative z-[100]" >
                <div className="cursor-pointer select-none whitespace-nowrap py-1.5 px-3" onClick={handleToggleFutureMinerals}>
                    {t("FUTURE_MINERALS")}
                </div>
                {isFutureMineralsOpen && <NavbarDesktopDropdown lng={lng} />}
            </li>
        </>

    );
}
