
import HeroMessageAr from "./HeroMessageAr";
import HeroMessageEn from "./HeroMessageEn";
import { useParams } from "react-router";

export default function Hero() {
  const { lng } = useParams()

  return (
    <div className="relative h-screen z-0 bg-primary">
      <video autoPlay loop muted className="object-cover w-full h-full">
        <source
          src="/assets/videos/home/hero_background.webm"
          type="video/webm"
        />
      </video>
      <div className="absolute inset-0 opacity-20 from-black to-dark bg-gradient-to-t" />
      <div className="mt-28  absolute inset-0 flex items-center justify-center lg:justify-start">
        <div className="app-container" data-aos="fade-up">
          {lng === "ar" ? <HeroMessageAr /> : <HeroMessageEn />}
        </div>
      </div>
    </div>
  );
}
