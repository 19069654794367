import { useCallback, useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { homeFutureMineralsLastMonthPricesOptions } from '../../../config/splide-slide-options';
import axiosClient from '../../../config/axios-client';
import { getPriceChangeValue } from '../../../config/global';
import HomeFutureMineralsPricesChange from './HomeFutureMineralsPricesChange';
import moment from 'moment';
import HomeFutureLastMonthPricesSlider from './HomeFutureLastMonthPricesSlider';
import HomeFutureLastMonthPricesSliderLoading from './HomeFutureLastMonthPricesSliderLoading';




export default function HomeFutureLastMonthPricesSection() {
    const { lng } = useParams();
    const { t } = useTranslation();
    const futureMineralsLastMonthPricesSliderRef = useRef(null);
    const chartRefs = useRef([]); // Create array of refs for multiple charts

    const [minerals, setMinerals] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    const handleNavigation = useCallback((direction) => {
        const adjustment = (lng === "en") ? (direction === 1 ? "+1" : "-1") : (direction === 1 ? "-1" : "+1");
        futureMineralsLastMonthPricesSliderRef.current.splide.go(adjustment);
    }, [lng]);

    const getFutureMineralsPricesHome = useCallback(() => {
        axiosClient.get('/platform/minerals/prices/home')
            .then(({ data }) => {
                setMinerals(data.minerals);
            })
            .catch((error) => {
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);


    // Resize the charts when the slides change
    useEffect(() => {
        getFutureMineralsPricesHome();
    }, [getFutureMineralsPricesHome]);


    return (
        <div className="app-container px-8 py-16 lg:px-0">
            <div className="flex gap-4 flex-col">
                <div data-aos="fade-down" className="flex flex-col gap-2 md:gap-2 md:flex-row md:items-center justify-between">
                    <h2 className='text-2xl font-black'>
                        {t('MINERALS_PRICES')}
                    </h2>
                    <div className="flex gap-2 items-center justify-end">
                        <button
                            onClick={() => handleNavigation(1)}
                            aria-label={t("NEXT")}
                            className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
                        >
                            <GrFormNext className="h-5 w-5" />
                        </button>
                        <button
                            onClick={() => handleNavigation(-1)}
                            aria-label={t("PREVIOUS")}
                            className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
                        >
                            <GrFormPrevious className="h-5 w-5" />
                        </button>
                        <Link title={t("MORE")} to={`/${lng}/prices/alalmnyom-aluminium?CHART=AREA&INTERVAL=MONTH`} className="flex select-none items-center px-3 py-1.5 border-2 border-muted hover:border-secondary hover:bg-light bg-white">
                            {t("MORE")}
                        </Link>
                    </div>
                </div>
                <div data-aos="fade-up">
                    {isLoading
                        ? <HomeFutureLastMonthPricesSliderLoading />
                        : <HomeFutureLastMonthPricesSlider minerals={minerals} lng={lng} futureMineralsLastMonthPricesSliderRef={futureMineralsLastMonthPricesSliderRef} />
                    }
                </div>
            </div>
        </div>
    );
}