import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineSearch, MdOutlineSearchOff } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router';

export default function NavBarDesktopSearchButton() {
    const { lng } = useParams();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const queryRef = useRef('')
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleSearch = () => {
        setIsOpen(!isOpen);
    };

    const handleOnSubmitSearchForm = (event) => {
        event.preventDefault();
        if (queryRef.current.value === "") {
            return;
        }
        navigate(`/${lng}/search/${queryRef.current.value}`);
        setIsOpen(false);
    }

    return <>
        {isOpen && <div onClick={handleToggleSearch} className="absolute z-1 top-0 left-0 bottom-0 h-screen w-full" />}
        <div className="relative w-full z-5">
            {isOpen && (
                <div className="absolute top-full rtl:left-0 ltr:right-0 mt-6">
                    <form onSubmit={handleOnSubmitSearchForm} className="relative bg-light p-2 border-2 border-muted flex ltr:flex-row-reverse">
                        <div className="absolute -top-2 rtl:left-3 ltr:right-3 w-3 h-3 bg-light  border-t-2 border-l-2 rotate-45" />
                        <input
                            type="search"
                            ref={queryRef}
                            placeholder={`${t('SEARCH')}...`}
                            className="font-normal py-1.5 outline-none border-2 w-[400px] px-2"
                            aria-label={t('SEARCH')}
                            name='query'
                        />
                    </form>
                </div>
            )}
            <button
                onClick={handleToggleSearch}
                className="p-1.5 px-2 text-white bg-secondary border-2 border-secondary hover:bg-muted hover:text-secondary"
                aria-expanded={isOpen}
                aria-label={isOpen ? 'Close Search' : 'Open Search'}
                label={isOpen ? 'Close Search' : 'Open Search'}
            >
                {isOpen ? <MdOutlineSearchOff size={22} /> : <MdOutlineSearch size={22} />}
            </button>
        </div>
    </>;
}