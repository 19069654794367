import React from 'react'

export default function AboutAr() {
  return (
    <>
      <h2
        data-aos={`fade-up`}
        data-aos-delay={300}
        className="mb-2 text-xl font-black"
      >
        مقدمة
      </h2>

      <p
        data-aos={`fade-up`}
        data-aos-delay={300}
        className={`mb-16`}
      >
        المنطقة العربية تزخر بالعديد من الثروات المعدنية المطلوبة في الصناعات المستقبلية المواكبة للتوجهات العالمية، فالعالم يسعى إلى تحقيق الحياد الكربوني وخلق بيئة نظيفة ومستدامة وتوفير تكنولوجيات حديثة، وهذا ما يؤدي إلى زيادة الطلب على الموارد المعدنية التي تمثل مصدرا رئيسيا لإقامة صناعات متطورة كتقنيات الطاقة النظيفة (الطاقة الكهروضوئية، توربينات الرياح، المركبات الكهربائية، شبكة الكهرباء، الهيدروجين الأخضر،....)، أنظمة الأتصالات، الهواتف، الذكاء الأصطناعي، انترنت الأشياء وغيرها. وتبعا لذلك
        وتماشيا مع التحول الرقمي وتنفيذا لمخرجات المبادرة العربية للمعادن المستخدمة في تقنيات الطاقة النظيفة، قامت المنظمة العربية للتنمية الصناعية والتقييس والتعدين بإعداد "المنصة العربية لمعادن المستقبل" لخدمة الفاعلين والمهتمين بقطاعات الطاقة، الصناعة والتعدين عربيأ ودولياً.
      </p>
      <div
        data-aos={`fade-up`}
        data-aos-delay={300}
        className={`mb-16 flex flex-col items-center md:flex-row md:items-start md:space-x-6 md:rtl:space-x-reverse`}
      >
        <img
          className={`h-40 w-40 md:h-64 md:w-64`}
          src={`/assets/logos/app_logo.svg`}
          alt={`platform logo`}
        />
        <p className={`mt-4 w-full md:mt-0 md:w-3/5 lg:w-4/5`}>
          <span>اول قاعدة معلومات رقمية وتفاعلية لمعادن المستقبل كالمعادن الإستراتيجية والحرجة وغيرها في المنطقة العربية وتشمل على:</span>
          <ul>
            <li>المعلومات الفنية (مثل التعريف بالمعادن، تواجدها وفرص استثمارها في الدول العربية، سلسلة القيمة، الطلب العالمي، أسعار المعادن،......الخ)</li>
            <li>الخريطة العربية (كنافذة تفاعلية بإستخدام Arc GIS Server لتوفير المعلومات الجيومكانية عن معادن المستقبل في المنطقة العربية ومعلومات أخرى ذات العلاقة).</li>
          </ul>
        </p>
      </div>
      <h2
        data-aos={`fade-up`}
        data-aos-delay={300}
        className="mb-4 text-xl font-black"
      >
        رسالة المنصة
      </h2>
      <div className={`mb-16 flex flex-col gap-4`}>
        <div
          data-aos={`fade-up`}
          data-aos-delay={300}
          className={`flex items-center border-2 border-success bg-light text-success`}
        >
          <div className={`bg-success p-4`}>
            <img
              className={`h-20 w-20`}
              src={`/assets/icons/about/interactive-icon.svg`}
              alt="interactive"
            />
          </div>
          <div className={`px-12 py-2 text-center text-xl`}>التعريف بمعادن المستقبل</div>
        </div>
        <div
          data-aos={`fade-up`}
          data-aos-delay={300}
          className={`flex items-center border-2 border-success/80 bg-light text-success`}
        >
          <div className={`bg-success/80 p-4`}>
            <img
              className={`h-20 w-20`}
              src={`/assets/icons/about/role-of-minerals-icon.svg`}
              alt="role-of-minerals"
            />
          </div>
          <div className={`px-12 py-2 text-center text-xl`}>الترويج للمعادن ذات البعد الاستراتيجي وفرص استثمارها في الدول العربية</div>
        </div>
        <div
          data-aos={`fade-up`}
          data-aos-delay={300}
          className={`flex items-center border-2 border-success/60 bg-light text-success`}
        >
          <div className={`bg-success/60 p-4`}>
            <img
              className={`h-20 w-20`}
              src={`/assets/icons/about/promoting-icon.svg`}
              alt="promoting"
            />
          </div>
          <div className={`px-12 py-2 text-center text-xl`}>ابراز دور المعادن والتعدين في مواكبة التوجهات العالمية نحو التنمية المستدامة</div>
        </div>
      </div>
      <div
        data-aos={`fade-up`}
        data-aos-delay={300}
        className="mb-8"
      >
        <h2 className="bg-info p-4 text-xl font-bold text-white">الاصدار الأول</h2>
        <div className="border-2 border-t-0 border-muted p-8">
          <div className="mb-8 flex w-full flex-col gap-8 md:flex-row">
            <img
              className="h-64 w-full border-2 border-muted object-cover md:!w-1/2"
              src="/assets/images/about-first-stage-1.jpg"
              alt=""
              srcset=""
            />
            <p className="w-full md:!w-1/2">تم الإطلاق الرسمي للمنصة في إصدارها الأول من قبل أصحاب المعالي الوزراء العرب المعنيين بشؤون الثروة المعدنية خلال اجتماعهم التشاوري التاسع يوم 09 يناير 2024 في مدينة الرياض بالمملكة العربية السعودية.</p>
          </div>
          <p>يرتكز هذا الإصدار على معادن الطاقة النظيفة وذلك تماشيا مع مخرجات المبادرة العربية للمعادن المستخدمة في تقنيات الطاقة النظيفة المقترحة من طرف المنظمة العربية للتنمية الصناعية والتقييس والتعدين.</p>
        </div>
        <div className="border-2 border-t-0 border-muted bg-light p-8">
          <h2 className="mb-2 text-lg font-black">أهداف الاصدار الاول</h2>
          <p>
            <ul>
              <li>تنفيذ السياسات الرامية إلى تعزيز الاقتصاد الاخضر في سياق التنمية المستدامة عبر الاستفاذة من الثروات المعدنية العربية،</li>
              <li>مواكبة التوجهات العالمية نحو مواجهة التغير المناخي والوصول للحياد الكربوني</li>
              <li>الاطلاع والتعريف بالفرص الاستثمارية التعدينية العربية وخاصة ذات العلاقة بمعادن الطاقة النظيفة.</li>
              <li>تعزيز مكانة المنطقة العربية في دعم الطلب العالمي وجعلها مركز تعديني عالمي مستدام.</li>
              <li>دعم سلسلة القيمة لمعادن الطاقة النظيفة.</li>
            </ul>
          </p>
        </div>
      </div>
      <div
        data-aos={`fade-up`}
        data-aos-delay={300}
        className="mb-4"
      >
        <h2 className="bg-secondary p-4 text-xl font-bold text-white">الاصدار الثاني</h2>
        <div className="border-2 border-t-0 border-muted p-8">
          <div className="mb-8 flex w-full flex-col gap-8 md:flex-row">
            <img
              className="h-64 w-full border-2 border-muted object-cover object-top md:!w-1/2"
              src="/assets/images/about-secondary-stage-1.jpg"
              alt=""
              srcset=""
            />
            <p className="w-full md:!w-1/2">تم الاطلاق لهذا الاصدار بإضافة المعادن الصناعية خلال حفل افتتاح ملتقى الفجيرة الدولي التاسع للتعدين والمعرض المصاحب له ( إمارة الفجيرة- دولة الإمارات العربية المتحدة: 29-31 أكتوبر 2024).</p>
          </div>
          <p>يرتكز هذا الإصدار على المعادن الصناعية، التي تُعد اللبنة الأساسية للعديد من التطبيقات الصناعية والاقتصادية. حيث تلعب هذه المعادن دورًا حيويًا في تعزيز الإنتاجية والكفاءة في القطاعات المختلفة، كما تساهم في خلق فرص الشغل وتحفيز الابتكار.</p>
        </div>
        <div className="border-2 border-t-0 border-muted bg-light p-8">
          <h2 className="mb-2 text-lg font-black">أهداف الاصدار الثاني</h2>
          <p>
            <ul>
              <li>توفير معلومات دقيقة ومحدثة حول المعادن الصناعية وأماكن تواجدها في الدول العربية.</li>
              <li>التعريف بالفرص الاستثمارية التعدينية العربية المتعلقة بالمعادن الصناعية، بهدف تعزيز القدرة الإنتاجية والنمو الاقتصادي.</li>
              <li>دعم سلسلة القيمة للمعادن الصناعية لتحقيق الاستخدام المستدام للموارد المعدنية.</li>
              <li>تسليط الضوء على أهم الصناعات التحويلية التي تدخل فيها المعادن الصناعية.</li>
            </ul>
          </p>
        </div>
      </div>
      <div
        data-aos={`fade-left`}
        data-aos-duration={500}
        data-aos-delay={700}
        className={`text-sm font-bold`}
      >
        المعلومات المتوفرة على المنصة مصدرها الجهات المعنية بقطاع الثروة المعدنية في الدول العربية والمواقع الرسمية والتقارير الدولية ذات العلاقة
      </div>
    </>
  )
}
