import { Outlet, useLocation, useParams } from 'react-router-dom'
import Header from '../componenets/inc/Header'
import Footer from '../componenets/inc/footer'
import { useEffect, useLayoutEffect } from 'react'
import SponsorsSideBar from '../componenets/sponsor/SponsorsSideBar'
import SponsorDiamondBanner from '../componenets/sponsor/SponsorDiamondBanner'
import AOS from 'aos'
import BackToTopButton from '../componenets/UI/BackToTopButton'
import { useTranslation } from 'react-i18next'

export default function Layout() {
  const { lng } = useParams()
  const { t } = useTranslation()
  const location = useLocation()

  // Determine if the current path is the home page
  const isHome = location.pathname.includes(['/home'])
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      once: true, // whether animation should happen only once
    })
  }, [])

  return (
    <div
      className="relative"
      dir={lng === 'ar' ? 'rtl' : 'ltr'}
    >
      {!isHome && <SponsorsSideBar />}
      <Header />
      <Outlet />
      {!isHome && <SponsorDiamondBanner isHome={isHome} />}
      <Footer />
      <BackToTopButton />
    </div>
  )
}
