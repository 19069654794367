import React from 'react'

export default function FutureMineralsRegionDetailsMapLoading() {
    return (
        <div className='animate-pulse'>
            <div className='flex mb-4'>
                <div className='border-2 border-muted flex items-center'>
                    <div className='w-20 h-20 bg-light p-2 rtl:border-l-2 ltr:border-r-2 border-muted '>

                    </div>
                    <div className='py-1 px-4'>
                        <div className='font-black h-4 w-32 bg-muted' />
                    </div>
                </div>
            </div>
            <div className={`relative w-full h-full p-6 border-2 border-muted bg-light mb-4`}>
                <img
                    style={{
                        zIndex: -1,
                    }}
                    src={`/assets/images/region-map.webp`}
                    alt={`loading map`}
                />
            </div>
        </div>
    )
}
