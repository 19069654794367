
import { Link, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import i18next from "i18next";
import CleanEnergyTechnologiesArticleSource from "./technologiesArticleSource";

export default function CleanEnergyMineralsTechnologiesElectricityNetworksArticle() {
  const { lng } = useParams();
  if (lng === "en")
    return (
      <>

        <article className="mb-8">
          <div className="mb-8">
            <img
              className="w-full h-[412px] object-cover object-bottom"
              src={`/assets/images/technologies/electricity-networks/electricity-networks-banner.webp`}
              alt="A network of electricity transmission lines and towers, powering communities with clean energy."
              itemprop="image"
            />
          </div>
          <h1 className="text-2xl font-bold mb-4">
            Electricity Networks
          </h1>

          <p className={`mb-16`}>
            Electricity networks are distribution systems that transmit
            electrical energy from power stations to homes, factories, and other
            facilities. They aim to safely and sustainably deliver electricity,
            relying on advanced technologies to ensure the stability of the
            electrical current and improve energy efficiency.
            <br />
            The Minerals primarily used in electricity networks are copper and
            aluminum. These Minerals are used in the structure of electrical
            networks and their connections to ensure the efficient and
            sustainable transmission of electricity.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            The Minerals used in electricity networks
          </h2>

          <div className="mb-4 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/electricity-networks/electricity-networks-compound-en.webp`}
              alt="An electricity distribution compound, a hub of interconnected infrastructure ensuring reliable power supply."
            />
          </div>

          <p className={`mb-16`}>
            These Minerals are used in the structure of electrical networks and
            their connections to ensure the efficient and sustainable
            transmission of electricity.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            Global demand for Minerals used in electricity networks
          </h2>
          <iframe
            title={`Global demand for Minerals used in electricity networks`}
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border-2 border-muted"
            src={`https://app.powerbi.com/view?r=eyJrIjoiOWQ4OTQ1YzgtNGYyNi00NzRiLTlmMjctMDg0NDhjMGU1NDE5IiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9`}
          />
        </article>
        <CleanEnergyTechnologiesArticleSource
          language={`en`}
          label={`International Energy Agency 2023`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
  if (lng === "ar")
    return (
      <>

        <article className="mb-8">
          <div className="mb-8">
            <img
              className="w-full h-[412px] object-cover object-bottom"
              src={`/assets/images/technologies/electricity-networks/electricity-networks-banner.webp`}
              alt="شبكة من خطوط وأبراج نقل الكهرباء، تمد المجتمعات بالطاقة النظيفة."
              itemprop="image"
            />
          </div>

          <h1 className="text-2xl font-bold mb-4">
            شبكات الكهرباء
          </h1>

          <p className={`mb-16`}>
            شبكات الكهرباء هي أنظمة توزيع الكهرباء التي تنقل الطاقة الكهربائية
            من محطات توليد الكهرباء إلى المنازل والمصانع وغيرها من المنشأت. تهدف
            إلى توصيل الكهرباء بأمان واستدامة وتعتمد على تقنيات متقدمة لضمان
            استقرار التيار الكهربائي وتحسين الكفاءة الطاقية. المعادن المستخدمة
            بشكل رئيسي في شبكات الكهرباء هي النحاس والألومنيوم. وهذه المعادن
            تستخدم في بنية الشبكات الكهربائية وتوصيلاتها لضمان نقل الكهرباء
            بكفاءة واستدامة.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            المعادن المستخدمة في شبكات الكهرباء
          </h2>

          <div className="mb-4 flex justify-center">
            <img
              className="max-h-[512px] w-full object-contain"
              src={`/assets/images/technologies/electricity-networks/electricity-networks-compound-ar.webp`}
              alt="مجمع لتوزيع الكهرباء، وهو مركز للبنية التحتية المترابطة التي تضمن إمدادات طاقة موثوقة."
            />
          </div>

          <p className={`mb-16`}>
            وهذه المعادن تستخدم في بنية الشبكات الكهربائية وتوصيلاتها لضمان نقل
            الكهرباء بكفاءة واستدامة.
          </p>

          <h2 className="text-2xl font-bold mb-4">
            الطلب العالمي على المعادن المستخدمة في شبكات الكهرباء
          </h2>
          <iframe
            title="الطلب العالمي على المعادن المستخدمة في شبكات الكهرباء"
            className="w-full h-96 md:h-[600px] lg:h-[650px] xl:h-[728px] border-2 border-muted"
            src="https://app.powerbi.com/view?r=eyJrIjoiYzc1ZTNjYjItMTMxMi00NzBkLWIwMDctOWQxYjc0OTlhMGJlIiwidCI6IjJhODM5MDc1LWE3MGYtNDM0Ni1hZWJmLTBkYTZjY2ZmZGI0YyJ9"
          />
        </article>
        <CleanEnergyTechnologiesArticleSource
          language={`ar`}
          label={`2023 وكالة الطاقة الدولية`}
          link={`https://www.iea.org/`}
          target="_blank"
        />
      </>
    );
}
