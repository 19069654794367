import React, { useMemo } from 'react';
import FutureMineralsCountriesOpportunitiesGridItem from './FutureMineralsCountriesOpportunitiesGridItem';
import { useParams, useSearchParams } from 'react-router-dom';

export default function FutureMineralsCountriesOpportunitiesGrid({ opportunities = [], onChangeCount }) {
    const [searchParams] = useSearchParams();
    const selectedMineralId = searchParams.get("MINERAL");

    // Memoize filtered opportunities to optimize re-rendering
    const filteredOpportunities = useMemo(() => {
        const filteredOpportunities = selectedMineralId != null
            ? opportunities.filter(opportunity =>
                opportunity.minerals.some(mineral => mineral.id == selectedMineralId)
            )
            : opportunities;
        onChangeCount(filteredOpportunities.length)
        return filteredOpportunities
    }, [onChangeCount, opportunities, selectedMineralId]);

    return (
        <div className="grid grid-cols-12 my-8 gap-6">
            {filteredOpportunities?.map((opportunity) => <FutureMineralsCountriesOpportunitiesGridItem opportunity={opportunity} />)}
        </div>
    );
}