import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

export default function TopHeader() {
  const { lng } = useParams();
  const { t } = useTranslation();
  const location = useLocation();

  // Determine if the current path is the home page
  const isHome = location.pathname.includes(["/home"]);
  return (
    <div className="flex flex-row-reverse justify-between w-full mx-auto lg:max-w-[1000px] xl:max-w-[1200px] m-auto py-2 px-8 lg:px-0">
      <div>
        <Link to={"https://aidsmo.org/"} target="_blank">
          <img
            className="!w-16 !h-16"
            title="المنظمة العربية للتنمية الصناعية والتقييس والتعدين"
            src="/assets/logos/aidsmo_logo.webp"
            alt="aidsmo_logo"
          />
        </Link>
      </div>
      <div>
        <div className="flex gap-2 items-center">
          <Link to={`/${lng}/home`}>
            <img
              className="!w-16 !h-16"
              title=""
              src="/assets/logos/app_logo.svg"
              alt="aidsmo_logo"
            />
          </Link>
          <h1 className={`text-xl font-bold ${isHome ? "text-white" : "text-primary"} px-4  hidden md:block`}>{t("PLATFORM_NAME")}</h1>
        </div>
      </div>
    </div>
  );
}
