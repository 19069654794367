import momentTimezone from "moment-timezone";
const supportedLanguages = ["en", "ar"];

const tailwindColors = {
  primary: "#067697", // Blue-green
  secondary: "#dfb662", // Light brown
  third: "#808080", // Gray
  warning: "#FFCC00", // Bright Yellow
  light: "#F6F9FC", // Very light gray-blue
  muted: "#E0EBEF", // Light gray-blue
  cleanEnergy: "#263D47", // Dark teal
  industrial: "#EAAA00", // Gold yellow
  danger: "#dc3545", // Soft red
  success: "#198754", // Soft green
  dark: "#1F1F1F", // Almost black
  info: "#17a2b8", // Cyan
  highlight: "#FF5722", // Vivid orange
  accent: "#6A1B9A", // Deep purple
  link: "#0D47A1", // Dark blue
  hover: "#003366", // Navy blue
  background: "#F0F2F5", // Light gray
};

const formatPriceChange = (change) => {
  const absChange = Math.abs(parseFloat(change));

  if (absChange < 0.00001) {
    return "0.00"; // Round to 0.00 for small values
  }

  return parseFloat(change).toFixed(5); // Keep 5 decimal places for larger values
}

const getPriceChangeValue = (change) => {
  if (change > 0) {
    return tailwindColors.success;
  } else if (change < 0) {

    return tailwindColors.danger;
  } else {

    return tailwindColors.primary;
  }
}

const toCurrentTimezoneHHMMA = (utcDate) => {
  // Parse the UTC timestamp with the specified format, if provided
  const utcMoment = momentTimezone.utc(utcDate);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC to user's local time
  const localMoment = utcMoment.clone().tz(userTimeZone);

  // Format the local date and time
  const localDate = localMoment.format("hh:mm A");

  return localDate;
};


const getMineralCategoryKey = (key) => {
  const categories = {
    "CLEAN_ENERGY": 1,
    "INDUSTRIAL": 2,
  }
  return categories[key] ?? 0;
}
const getMineralCategoryId = (id) => {
  const categories = {
    1: "CLEAN_ENERGY",
    2: "INDUSTRIAL",
  }
  return categories[id] ?? 0;
}



export { supportedLanguages, tailwindColors, formatPriceChange, getPriceChangeValue, toCurrentTimezoneHHMMA, getMineralCategoryKey, getMineralCategoryId };
