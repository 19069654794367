import React from 'react'

export default function PlatformNumberGridCardLoading() {
    return (
        <div className='col-span-10 md:col-span-5 lg:col-span-2 relative animate-pulse'>
            <div className="h-full p-8 flex flex-col items-center justify-center gap-6 border-2 border-muted bg-light">
                <div className='h-16 w-16 bg-muted animate-pulse' />
                <div className='flex flex-col gap-2 items-center'>
                    <div className='h-4 w-12 bg-muted animate-pulse' />
                    <div className='h-4 w-28 bg-muted animate-pulse' />
                </div>
            </div>
        </div>
    )
}
