import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axiosClient from '../../config/axios-client';
import FutureMineralsRegionDetailsMapLoading from './FutureMineralsRegionDetailsMapLoading';
import { Helmet } from 'react-helmet';
const WIDTH = 1522;
const HEIGHT = 813;

export default function FutureMineralsRegionDetailsMap() {
    const { lng, mineral_slug } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const imageMapRef = useRef(null);

    const [mineral, setMineral] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [imageSize, setImageSize] = useState({ width: WIDTH, height: HEIGHT });


    const getFutureMineralCountries = useCallback(() => {
        setIsLoading(true);

        axiosClient
            .get(`/platform/minerals/${mineral_slug}/region`)
            .then(({ data }) => {
                setMineral(data.mineral);
                if (data.mineral.countries.length == 0) {
                    navigate(`/${lng}/not-found`, { replace: true });
                }
            })
            .catch((error) => {

                navigate(`/${lng}/not-found`, { replace: true });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [mineral_slug]);



    function handleOnResize() {
        if (imageMapRef.current && imageMapRef.current.width) {
            setImageSize({
                width: imageMapRef.current.width,
                height: imageMapRef.current.height,
            });
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleOnResize);
        return () => window.removeEventListener("resize", handleOnResize);
    }, []);

    useEffect(() => {
        getFutureMineralCountries()
    }, [getFutureMineralCountries]);

    if (isLoading) {
        return <FutureMineralsRegionDetailsMapLoading />
    }
    if (mineral)
        return (
            <div>
                <Helmet>
                    <title>
                        {`APFM | ${mineral ? mineral[`name_${lng}`] : ''}`}
                    </title>
                </Helmet>
                <div className='flex mb-4'>
                    <div className='border-2 border-muted flex items-center'>
                        <div className='w-20 bg-light p-2 rtl:border-l-2 ltr:border-r-2 border-muted'>
                            <img src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.image}`} alt="" />
                        </div>
                        <div className='py-1 px-4'>
                            <h3 className='font-black'>
                                {mineral[`name_${lng}`]}
                            </h3>
                        </div>
                    </div>
                </div>
                {
                    mineral.countries[0]?.id != 22 &&
                    <div className={`relative w-full h-full p-6 border-2 border-muted bg-light mb-4`}>
                        <div
                            style={{
                                zIndex: 0,
                            }}
                            className={`p-6 absolute right-0 bottom-0 top-0 left-0 z-50`}
                        >
                            <div className='w-full h-full relative '>
                                {
                                    mineral.countries.map((country) =>

                                        <img
                                            title={country[`name_${lng}`]}
                                            key={country.id}
                                            style={{
                                                left: ((imageSize.width / WIDTH) * (country.virtual_position_x - 30)),
                                                top: ((imageSize.height / HEIGHT) * (country.virtual_position_y - 20)),
                                                width: (imageSize.width / WIDTH) * 60,
                                                height: (imageSize.height / HEIGHT) * 40,
                                            }}
                                            onClick={() => {
                                                searchParams.set('country', country.id);
                                                setSearchParams(searchParams);
                                            }}
                                            className={`absolute transition duration-300 ease-in-out border-double border-2 ${searchParams.get('country') == country.id
                                                ? `z-10 scale-150 border-highlight`
                                                : `hover:z-50 hover:scale-150 border-background`
                                                } cursor-pointer`}
                                            src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                                            alt={country[`name_${lng}`]} // Alt text based on the country name
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <img
                            style={{
                                zIndex: -1,
                            }}
                            ref={imageMapRef}
                            onLoad={handleOnResize}
                            src={`/assets/images/region-map.webp`}
                            alt={`Map showing distribution of ${mineral[`name_${lng}`]} mineral`}
                        />
                    </div>
                }
            </div>
        )
}
