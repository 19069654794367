import React from 'react'

export default function AboutEn() {
  return (
    <>
      <h2
        data-aos={`fade-up`}
        data-aos-delay={300}
        className="mb-2 text-xl font-black"
      >
        INTRODUCTION
      </h2>

      <p
        data-aos={`fade-up`}
        data-aos-delay={300}
        className={`mb-16`}
      >
        The Arab region is rich in many mineral resources that are required in future industries to keep up with global trends. The world is striving to achieve carbon neutrality and create a clean, sustainable environment, as well as to provide modern technologies. This leads to an increased demand for mineral resources, which represent a primary source for establishing advanced industries such as clean energy technologies (photovoltaics, wind turbines, electric vehicles, electric grids, green
        hydrogen...), communication systems, phones, artificial intelligence, the Internet of Things, and others. Accordingly, and in line with digital transformation and the implementation of the outputs of the Arab initiative for minerals used in clean energy technologies, the Arab Industrial Development, Standardization, and Mining Organization (AIDSMO) has prepared the "Arab Platform for Future Minerals" to serve stakeholders and those interested in the energy, industry, and mining sectors
        both regionally and internationally.
      </p>
      <div
        data-aos={`fade-up`}
        data-aos-delay={300}
        className={`mb-16 flex flex-col items-center md:flex-row md:items-start md:space-x-6 md:rtl:space-x-reverse`}
      >
        <img
          className={`h-40 w-40 md:h-64 md:w-64`}
          src={`/assets/logos/app_logo.svg`}
          alt={`platform logo`}
        />
        <p className={`mt-4 w-full md:mt-0 md:w-3/5 lg:w-4/5`}>
          <span>The first digital and interactive database for future minerals such as strategic and critical minerals in the Arab region includes:</span>
          <ul>
            <li>Technical information (such as mineral identification, their presence and investment opportunities in Arab countries, value chain, global demand, mineral prices, etc.).</li>
            <li>Arab map (as an interactive window using ArcGIS Server to provide geospatial data about future minerals in the Arab region and other related information).</li>
          </ul>
        </p>
      </div>
      <h2
        data-aos={`fade-up`}
        data-aos-delay={300}
        className="mb-4 text-xl font-black"
      >
        Platform's mission
      </h2>
      <div className={`mb-16 flex flex-col gap-4`}>
        <div
          data-aos={`fade-up`}
          data-aos-delay={300}
          className={`flex items-center border-2 border-success bg-light text-success`}
        >
          <div className={`bg-success p-4`}>
            <img
              className={`h-20 w-20`}
              src={`/assets/icons/about/interactive-icon.svg`}
              alt="interactive"
            />
          </div>
          <div className={`px-12 py-2 text-center text-lg`}>Introducing future minerals.</div>
        </div>
        <div
          data-aos={`fade-up`}
          data-aos-delay={300}
          className={`flex items-center border-2 border-success/80 bg-light text-success`}
        >
          <div className={`bg-success/80 p-4`}>
            <img
              className={`h-20 w-20`}
              src={`/assets/icons/about/promoting-icon.svg`}
              alt="promoting"
            />
          </div>
          <div className={`px-12 py-2 text-center text-lg`}>Highlighting the role of minerals and mining in keeping pace with global trends towards sustainable development.</div>
        </div>
        <div
          data-aos={`fade-up`}
          data-aos-delay={300}
          className={`flex items-center border-2 border-success/60 text-success`}
        >
          <div className={`bg-success/60 p-4`}>
            <img
              className={`h-20 w-20`}
              src={`/assets/icons/about/role-of-minerals-icon.svg`}
              alt="role-of-minerals"
            />
          </div>
          <div className={`px-12 py-2 text-center text-lg`}>Promoting minerals with a strategic dimension and their investment opportunities in Arab countries.</div>
        </div>
      </div>
      <div
        data-aos={`fade-up`}
        data-aos-delay={300}
        className="mb-8"
      >
        <h2 className="bg-info p-4 text-xl font-bold text-white">
          1<sup>st</sup> Edition
        </h2>
        <div className="border-2 border-t-0 border-muted p-8">
          <div className="mb-8 flex w-full flex-col gap-8 md:flex-row">
            <img
              className="h-64 w-full border-2 border-muted object-cover md:!w-1/2"
              src="/assets/images/about-first-stage-1.jpg"
              alt=""
              srcset=""
            />
            <p className="w-full md:!w-1/2">The official launch of the platform at its 1 st edition took place by Their Excellencies the Arab Ministers concerned with Mineral Resources during their ninth consultative meeting on January 9, 2024, in Riyadh, Saudi Arabia.</p>
            {/* <img className='border-2 border-muted w-full md:!w-1/2 h-64 object-cover' src="/assets/images/about-first-stage-2.jpg" alt="" srcset="" /> */}
          </div>
          <p>This edition focuses on “Clean Energy Minerals” in line with the outputs of the Arab Initiative for Minerals Used in Clean Energy Technologies, proposed by the Arab Industrial Development, Standardization and Mining Organization (AIDSMO).</p>
        </div>
        <div className="border-2 border-t-0 border-muted bg-light p-8">
          <h2 className="mb-2 text-lg font-black">
            1<sup>st</sup> Edition Objectives
          </h2>
          <p>
            <ul>
              <li>Implementing policies aimed at enhancing the green economy within the framework of sustainable development by leveraging Arab mineral resources.</li>
              <li>Aligning with global trends addressing climate change and achieving carbon neutrality.</li>
              <li>Exploring and introducing Arab mining investment opportunities, particularly those related to Clean Energy Minerals.</li>
              <li>Boosting the Arab region&#39;s position in supporting global demand and establishing it as a sustainable global mining hub.</li>
              <li>Supporting the value chain for Clean Energy Minerals.</li>
            </ul>
          </p>
        </div>
      </div>
      <div
        data-aos={`fade-up`}
        data-aos-delay={300}
        className="mb-4"
      >
        <h2 className="bg-secondary p-4 text-xl font-bold text-white">
          2<sup>nd</sup> Edition
        </h2>
        <div className="border-2 border-t-0 border-muted p-8">
          <div className="mb-8 flex w-full flex-col gap-8 md:flex-row">
            <img
              className="h-64 w-full border-2 border-muted object-cover md:!w-1/2"
              src="/assets/images/about-secondary-stage-1.jpg"
              alt=""
              srcset=""
            />
            <p className="w-full md:!w-1/2">The official launch of this edition took place during the 9 th Fujairah International Mining Forum and accompanying exhibition (Emirate of Fujairah, United Arab Emirates: October 29-31, 2024).</p>
          </div>
          <p>This edition focuses on industrial minerals, which are the cornerstone of many industrial and economic applications. These minerals play a vital role in enhancing productivity and efficiency across various sectors, as well as contributing to creating job opportunities and stimulating innovation.</p>
        </div>
        <div className="border-2 border-t-0 border-muted bg-light p-8">
          <h2 className="mb-2 text-lg font-black">
            2<sup>nd</sup> Edition Objectives
          </h2>
          <p>
            <ul>
              <li>Providing accurate and up-to-date data about industrial minerals and their locations in Arab countries.</li>
              <li>Introducing Arab mining investment opportunities related to industrial minerals, aiming to enhance production capacity and economic growth.</li>
              <li>Supporting the value chain of industrial metals to achieve sustainable use of mineral resources.</li>
              <li>Highlighting the key manufacturing industries that incorporate industrial minerals.</li>
            </ul>
          </p>
        </div>
      </div>
      <div
        data-aos={`fade-left`}
        data-aos-delay={700}
        data-aos-duration={500}
        className={`text-sm font-bold`}
      >
        The data available on the platform originates from entities concerned with the mineral resources sector in Arab countries, official websites, and related international reports.
      </div>
    </>
  )
}
