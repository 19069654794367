import { useInView } from 'react-intersection-observer';
import PlatformNumberGridCardLoading from './PlatformNumberGridCardLoading'
import { CountUp } from 'use-count-up';

export default function PlatformNumbersGridCard({ subTtile = null, title, count, isLoading, icon }) {
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    if (isLoading || count === 0) {
        return <PlatformNumberGridCardLoading />
    }

    return (
        <div ref={ref} data-aos="fade-up" className='col-span-10 md:col-span-5 lg:col-span-2 relative'>
            {
                subTtile
                &&
                <span className='absolute top-0 left-0 bg-muted px-2 py-1 text-xs'>
                    {subTtile}
                </span>
            }
            <div className="h-full p-8 flex flex-col items-center justify-center gap-6 border-2 border-muted bg-light">
                <img className='h-20 md:h-16' alt={title} src={icon} />
                <div className='flex flex-col gap-2 items-center'>
                    <h4 className='text-3xl text-secondary font-black'>
                        <CountUp
                            isCounting={inView}
                            start={0}
                            end={count}
                            duration={count > 100 ? 2 : 4}
                        />
                    </h4>
                    <p className='font-bold whitespace-nowrap'>
                        {title}
                    </p>
                </div>
            </div>
        </div>
    )
}
