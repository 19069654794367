import { useTranslation } from "react-i18next";
import Spacing from "../componenets/UI/Spacing";
import { useParams } from "react-router";
import AboutAr from "../componenets/about/AboutAr";
import AboutEn from "../componenets/about/AboutEn";
import { Helmet } from "react-helmet";

export default function About() {
    const { t } = useTranslation();
    const { lng } = useParams();
    return (
        <div>
            <Helmet>
                <title>{`APFM | ${t("ABOUT")}`}</title>
            </Helmet>
            <Spacing />
            <div className={`app-container px-8 lg:px-0`}>
                <article>
                    <h2
                        data-aos={`fade-down`}
                        data-aos-delay={200}
                        className="text-2xl font-black mb-4"
                    >
                        {t("ABOUT")}
                    </h2>
                    <div
                        data-aos={`fade-up`}
                        data-aos-delay={200}
                        className={`h-[312px] mb-8`}
                    >
                        <img
                            className={`h-full w-full object-cover object-bottom`}
                            src={`/assets/images/about_image.webp`}
                            alt={`صورة حول المنصة`}
                            itemprop="image"
                        />
                    </div>
                    {lng == "ar" ? <AboutAr /> : <AboutEn />}
                </article>
            </div>
        </div>
    );
}
