import TopHeader from "./TopHeader";
import NavbarDesktop from "./menu/desktop/NavbarDesktop";
import HeaderFutureMineralsPrices from "./header-future-minerals-prices/HeaderFutureMineralsPrices";
import NavbarMobile from "./menu/mobile/NavbarMobile";

export default function Header() {
  return (
    <header data-aos="fade-down" className="absolute top-0 left-0 z-10 right-0">
      <HeaderFutureMineralsPrices />
      <TopHeader />
      <NavbarDesktop />
      <NavbarMobile />
    </header>
  );
}
