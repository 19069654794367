import React from 'react'
import { homeFutureMineralsLastMonthPricesOptions } from '../../../config/splide-slide-options'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import HomeFutureLastMonthPricesChart from './HomeFutureLastMonthPricesSilderItem'

export default function HomeFutureLastMonthPricesSlider({ minerals, lng, futureMineralsLastMonthPricesSliderRef }) {
    return (
        <Splide
            ref={futureMineralsLastMonthPricesSliderRef}
            aria-label={`Future Minerals last month prices Slider`}
            options={homeFutureMineralsLastMonthPricesOptions}
        >
            {minerals?.map((mineral) => <HomeFutureLastMonthPricesChart mineral={mineral} lng={lng} />)}
        </Splide>
    )
}
