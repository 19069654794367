import {
    FiChevronLeft,
    FiChevronRight,
    FiChevronsLeft,
    FiChevronsRight,
  } from 'react-icons/fi';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { Link, useLocation } from 'react-router-dom';

export default function Pagination({ pagination }) {
  
  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  queryParams.delete('page')
  
  if (!pagination) return null;
  const hasPrevious = pagination.current_page > 1;
  const hasNext = pagination.current_page < pagination.last_page;
  
  return (
    <div className="flex items-center gap-2" dir="ltr">
      {hasNext ? 
        <Link
          to={hasNext ? `?${queryParams.toString()}&page=${pagination.current_page + 1}` : '#'}
          className={`flex items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white ${!hasNext ? 'opacity-50 cursor-not-allowed' : ''}`}
          aria-disabled={!hasNext}
          disables={hasNext}
        >
          <GrFormPrevious className="h-5 w-5" />
        </Link>
      :
        <div
          className={`flex items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white opacity-50 cursor-not-allowed`}
          aria-disabled={!hasNext}
          disables={hasNext}
        >
          <GrFormPrevious className="h-5 w-5" />
        </div>
      }
      
      {hasPrevious ? 
        <Link
          to={hasPrevious ? `?${queryParams.toString()}&page=${pagination.current_page - 1}` : '#'}
          className={`flex items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white ${!hasPrevious ? 'opacity-50 cursor-not-allowed' : ''}`}
          aria-disabled={!hasPrevious}
        >
          <GrFormNext className="h-5 w-5" />
        </Link>
      :
      <div
        className={`flex items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white opacity-50 cursor-not-allowed`}
        aria-disabled={!hasPrevious}
      >
        <GrFormNext className="h-5 w-5" />
      </div>
    }

    </div>
  );
}