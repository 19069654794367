import { useTranslation } from "react-i18next";
import { SlSizeFullscreen } from "react-icons/sl";
import { Link, useParams } from "react-router-dom";

export default function HomeArabMapSection() {
    const { t } = useTranslation();
    const { lng } = useParams();

    return <div className="bg-light py-16">
        <div className="app-container px-8 lg:px-0">
            <div data-aos="fade-down" className="flex justify-between items-center">
                <h2 className='text-2xl font-black'>
                    {t("REGION_MAP")}
                </h2>
                <div className="">
                    <Link to={`/region/map`} target="_blank" className="flex p-2 border-2 border-muted bg-white hover:border-secondary hover:bg-light">
                        <SlSizeFullscreen className="h-5 w-5" />
                    </Link>
                </div>
            </div>
            <div data-aos="fade-up" className="w-full mt-4">
                <iframe title={t("REGION_MAP")}
                    className="h-[532px] w-full border-2 border-muted"
                    src="https://gis.aidmo.org/portal/apps/webappviewer/index.html?id=8ec7ff8605e046459da6ac0643c3f440">
                </iframe>
            </div>
        </div>

    </div>
}
