import React from "react";

export default function HeroMessageAr() {
  return (
    <p className="text-center sm:px-8 text-3xl text-white md:text-right lg:p-0 px-8 md:text-5xl font-bold !leading-[2.5] md:!leading-[2]">
      أول منصة معلوماتية وتفاعلية
      <br />
      <span className="text-secondary font-black text-5xl md:text-6xl">
        {" "}
        لمعادن{" "}
      </span>
      <span className="text-primary font-black text-5xl md:text-6xl">
        {" "}
        المستقبل{" "}
      </span>
      <br />
      في المنطقة العربية
    </p>
  );
}
