import React, { useCallback, useEffect, useState } from "react";
import Spacing from "../componenets/UI/Spacing";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import axiosClient from "../config/axios-client";
import ChartComponent from "../componenets/future-mineral-prices/FutureMineralPricesChart";
import FutureMineralPricesAvailableList from "../componenets/future-mineral-prices/FutureMineralPricesAvailableList";
import FutureMineralPricesChartTypeSelect from "../componenets/future-mineral-prices/FutureMineralPricesChartTypeSelect";
import FutureMineralPricesMineralDetails from "../componenets/future-mineral-prices/FutureMineralPricesMineralDetails";
import FutureMineralPricesChartLoading from "../componenets/future-mineral-prices/FutureMineralPricesChartLoading";
import FutureMineralPricesMineralDetailsLoading from "../componenets/future-mineral-prices/FutureMineralPricesMineralDetailsLoading";
import FutureMineralPricesChartTimeIntervalSelect from "../componenets/future-mineral-prices/FutureMineralPricesChartTimeIntervalSelect";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function FutureMineralPricePage() {
    const { mineral_slug, lng } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const { t } = useTranslation();

    const [mineral, setMineral] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getMineralPrice = useCallback(() => {
        setIsLoading(true);
        axiosClient
            .get(`/platform/minerals/${mineral_slug}/prices?${searchParams.toString()}`)
            .then(({ data }) => {
                setMineral(data.mineral);
            })
            .catch((error) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, [mineral_slug, searchParams.get("INTERVAL")]);

    useEffect(() => {
        getMineralPrice();
    }, [getMineralPrice]);

    return (
        <div>
            <Helmet>
                <title>{`APFM | ${t("MINERALS_PRICES")} : ${mineral ? mineral[`name_${lng}`] : ""}`}</title>
            </Helmet>
            <Spacing />
            <div className="app-container lg:px-0 px-8">
                <h2 className="text-2xl font-black mb-4">{t("MINERALS_PRICES")}</h2>
                <div className="flex flex-col justify-start items-start">
                    <div className="flex flex-col md:flex-row md:items-end gap-4 items-start">
                        {isLoading ? (
                            <FutureMineralPricesMineralDetailsLoading />
                        ) : (
                            <FutureMineralPricesMineralDetails
                                mineral={mineral}
                                lng={lng}
                            />
                        )}
                        <FutureMineralPricesChartTypeSelect />
                        <FutureMineralPricesChartTimeIntervalSelect />
                    </div>
                    <div className="grid grid-cols-12 gap-4 w-full h-[500px] mt-4">
                        {isLoading ? (
                            <FutureMineralPricesChartLoading />
                        ) : (
                            <ChartComponent
                                prices={mineral?.prices}
                                dates={mineral?.dates}
                                unit={mineral?.unit_symbol}
                            />
                        )}
                        <FutureMineralPricesAvailableList />
                    </div>
                </div>
                <div className="mt-8">
                    <h4 className="mb-1 font-bold">{t("SOURCE")}</h4>
                    <span className="uppercase">metals-api</span>
                </div>
            </div>
        </div>
    );
}
