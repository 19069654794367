import { GoDash } from 'react-icons/go'
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'

export default function HeaderFutureMineralsPriceChange({ change }) {
    if (change > 0) {

        return (
            <div className='flex gap-2 rtl:flex-row-reverse items-center border-success text-success'>
                <div className=''>
                    {change} %
                </div>
                <div className='text-white bg-success p-1'>
                    <MdArrowUpward />
                </div>
            </div>
        )
    } else if (change < 0) {

        return (
            <div className='flex gap-2 rtl:flex-row-reverse items-center border-danger text-danger'>
                <div className=''>
                    {change} %
                </div>
                <div className='text-white bg-danger p-1'>
                    <MdArrowDownward />
                </div>
            </div>
        )
    } else {

        return (
            <div className='flex gap-2 rtl:flex-row-reverse items-center border-primary text-primary'>
                <div className=''>
                    {change} %
                </div>
                <div className='text-white bg-primary p-1'>
                    <GoDash />
                </div>
            </div>

        )
    }
}
