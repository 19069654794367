import { createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "../pages/Layout";
import Home from "../pages/Home";
import LanguageRedirect from "../componenets/global/LanguageRedirect";
import RegionMapPage from "../pages/RegionMapPage";

import CleanEnergyMineralsTechnologiesSolarEnergyArticle from "../componenets/technolgies/articles/CleanEnergyMineralsTechnologiesSolarEnergyArticle";
import CleanEnergyMineralsTechnologiesWindEnergyArticle from "../componenets/technolgies/articles/CleanEnergyMineralsTechnologiesWindEnergyArticle";
import CleanEnergyMineralsTechnologiesBatteriesArticle from "../componenets/technolgies/articles/CleanEnergyMineralsTechnologiesBatteriesArticle";
import CleanEnergyMineralsTechnologiesElectricityNetworksArticle from "../componenets/technolgies/articles/CleanEnergyMineralsTechnologiesElectricityNetworksArticle";
import CleanEnergyMineralsTechnologiesElectricVehiclesArticle from "../componenets/technolgies/articles/CleanEnergyMineralsTechnologiesElectricVehiclesArticle";
import CleanEnergyMineralsTechnologiesHydrogenArticle from "../componenets/technolgies/articles/CleanEnergyMineralsTechnologiesHydrogenArticle";

import CleanEnergyTechnologiesArticleLayout from "../componenets/technolgies/CleanEnergyTechnologiesArticleLayout";
import FutureMineralsValueChainsPage from "../pages/FutureMineralsValueChainsPage";
import FutureMineralsPage from "../pages/FutureMineralsPage";
import FutureMineralsDetailsPage from "../pages/FutureMineralsDetailsPage";
import FutureMineralsCleanEnergyArabProductionPage from "../pages/FutureMineralsCleanEnergyArabProductionPage";
import FutureMineralsIndustrialArabProductionPage from "../pages/FutureMineralsIndustrialArabProductionPage";
import FutureMineralsCleanEnergyGlobalDemandPage from "../pages/FutureMineralsCleanEnergyGlobalDemandPage";
import FutureMineralsCleanEnergyTechnologiesPage from "../pages/FutureMineralsCleanEnergyTechnologiesPage";
import FutureMineralsIndustriesPage from "../pages/FutureMineralsIndustriesPage";
import FutureMineralsRegionPage from "../pages/FutureMineralsRegionPage";
import SponsorDetailsPage from "../pages/SponsorDetailsPage";
import About from "../pages/About";
import FutureMineralsRegionDetailsPage from "../pages/FutureMineralsRegionDetailsPage";
import FutureMineralPricePage from "../pages/FutureMineralPricePage";
import FutureMineralsCountriesOpportunitiesPage from "../pages/FutureMineralsCountriesOpportunitiesPage";
import SearchPage from "../pages/SearchPage";
import FormPage from "../pages/FormPage";
import Error404Page from "../pages/Error404Page";

const router = createBrowserRouter([
    {
        path: "",
        element: (
            <Navigate
                to="/ar/home"
                replace
            />
        ),
    },
    {
        path: ":lng",
        element: (
            <LanguageRedirect>
                <Layout />
            </LanguageRedirect>
        ),
        children: [
            {
                path: "not-found",
                element: <Error404Page />,
            },
            {
                path: "home",
                element: <Home />,
            },
            {
                path: "about",
                element: <About />,
            },
            {
                path: "search/:query",
                element: <SearchPage />,
            },
            {
                path: "",
                element: (
                    <Navigate
                        to="/ar/home"
                        replace
                    />
                ),
            },
            {
                path: "prices/:mineral_slug?",
                element: <FutureMineralPricePage />,
            },
            {
                path: "opportunities",
                element: <FutureMineralsCountriesOpportunitiesPage />,
            },
            {
                path: "future-minerals",
                children: [
                    {
                        path: "",
                        element: <FutureMineralsPage />,
                    },

                    {
                        path: "region",
                        children: [
                            {
                                path: "",
                                element: <FutureMineralsRegionPage />,
                            },
                            {
                                path: ":mineral_slug",
                                element: <FutureMineralsRegionDetailsPage />,
                            },
                        ],
                    },

                    {
                        path: "value-chains",
                        element: <FutureMineralsValueChainsPage />,
                    },
                    {
                        path: "industrial",
                        children: [
                            {
                                path: "industries",
                                element: <FutureMineralsIndustriesPage />,
                            },
                        ],
                    },
                    {
                        path: "clean-energy",
                        children: [
                            {
                                path: "arab-production",
                                element: <FutureMineralsCleanEnergyArabProductionPage />,
                            },
                            {
                                path: "global-demand",
                                element: <FutureMineralsCleanEnergyGlobalDemandPage />,
                            },
                            {
                                path: "technologies",
                                children: [
                                    {
                                        path: "",
                                        element: <FutureMineralsCleanEnergyTechnologiesPage />,
                                    },
                                    {
                                        path: "",
                                        element: <CleanEnergyTechnologiesArticleLayout />,
                                        children: [
                                            {
                                                path: "batteries",
                                                element: <CleanEnergyMineralsTechnologiesBatteriesArticle />,
                                            },
                                            {
                                                path: "solar-energy",
                                                element: <CleanEnergyMineralsTechnologiesSolarEnergyArticle />,
                                            },
                                            {
                                                path: "wind-energy",
                                                element: <CleanEnergyMineralsTechnologiesWindEnergyArticle />,
                                            },
                                            {
                                                path: "electricity-networks",
                                                element: <CleanEnergyMineralsTechnologiesElectricityNetworksArticle />,
                                            },
                                            {
                                                path: "hydrogen",
                                                element: <CleanEnergyMineralsTechnologiesHydrogenArticle />,
                                            },
                                            {
                                                path: "electric-vehicles",
                                                element: <CleanEnergyMineralsTechnologiesElectricVehiclesArticle />,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "industrial",
                        children: [
                            {
                                path: "arab-production",
                                element: <FutureMineralsIndustrialArabProductionPage />,
                            },
                        ],
                    },
                    {
                        path: ":mineral_slug",
                        element: <FutureMineralsDetailsPage />,
                    },
                ],
            },
            {
                path: "sponsors",
                children: [
                    {
                        path: ":sponsor_slug",
                        element: <SponsorDetailsPage />,
                    },
                ],
            },
            {
                path: "*",
                element: <div>Error 404</div>,
            },
        ],
    },
    {
        path: "region/map",
        element: <RegionMapPage />,
    },
    {
        path: "/form",
        element: <FormPage />,
    },
    {
        path: "*",
        element: <div>Error 404</div>,
    },
]);

export default router;
