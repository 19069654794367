import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "./../../config/i18n";
import { supportedLanguages } from "../../config/global";

const LanguageRedirect = ({ children }) => {
  const { lng } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!supportedLanguages.includes(lng)) {
      // Redirect to default language if language is not supported
      navigate("/ar/home", { replace: true });
    } else {
      // Change i18n language
      i18n.changeLanguage(lng);
    }
  }, [lng, navigate]);

  return <>{children}</>; // Render children components if language is valid
};

export default LanguageRedirect;
