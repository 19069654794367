import React from 'react'

export default function HomeOpportunitiesMessageAr() {
  return (
    <p className="text-3xl md:text-5xl font-bold  leading-[2] md:leading-[2]">
      <span>
        الفرص الاستثمارية التعدينية
      </span>
      <br />
      <span className="text-4xl md:text-6xl font-black text-white">
        لمعادن المستقبل
      </span>
      <br />
      <span >
        في الدول العربية
      </span>
    </p>
  )
}
