import { useTranslation } from "react-i18next";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import Spacing from "../componenets/UI/Spacing";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import axiosClient from "../config/axios-client";
import FutureMineralsValueChainsSlider from "../componenets/value-chains/FutureMineralsValueChainsSlider";
import FutureMineralsValueChainsDetails from "../componenets/value-chains/FutureMineralsValueChainsDetails";
import FutureMineralsValueChainsSliderLoading from "../componenets/value-chains/FutureMineralsValueChainsSliderLoading";
import { Helmet } from "react-helmet";

export default function FutureMineralsValueChainsPage() {
    const { lng } = useParams();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const futureMineralsValueChainsSliderRef = useRef(null);

    const [minerals, setMinerals] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMineralCategoryId, setSelectedMineralCategoryId] = useState(searchParams.get("category") || 0);

    // Funtions
    const handleOnChangeMineralCategoryId = useCallback(
        (id) => {
            // Reset mineral query param
            searchParams.delete("mineral");

            // If the selected id is the same, reset the category
            if (id == selectedMineralCategoryId) {
                searchParams.delete("category");
                setSelectedMineralCategoryId(0);
            } else {
                searchParams.set("category", id);
                setSelectedMineralCategoryId(id);
            }

            setSearchParams(searchParams);
        },
        [searchParams, selectedMineralCategoryId, setSearchParams]
    );

    const handleNavigation = useCallback(
        (direction) => {
            const adjustment = lng === "en" ? (direction === 1 ? "+1" : "-1") : direction === 1 ? "-1" : "+1";
            futureMineralsValueChainsSliderRef.current.splide.go(adjustment);
        },
        [lng]
    );

    const getFutureMineralsValueChains = useCallback(() => {
        setIsLoading(true);
        axiosClient
            .get("/platform/minerals/value-chains")
            .then(({ data }) => {
                setMinerals(data.minerals);
            })
            .catch((error) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        getFutureMineralsValueChains();
    }, [getFutureMineralsValueChains]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const categoryId = query.get("category");
        setSelectedMineralCategoryId(categoryId || "");
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Helmet>
                <title>{`APFM | ${t("VALUE_CHAIN")}`}</title>
            </Helmet>
            <Spacing />
            <div className="app-container px-8 lg:px-0">
                <h2
                    data-aos={`fade-down`}
                    data-aos-delay={100}
                    className="text-2xl font-black mb-4"
                >
                    {t("VALUE_CHAIN")}
                </h2>

                <div
                    data-aos={`fade-up`}
                    data-aos-delay={100}
                    className="h-[321px] w-full overflow-hidden mb-6"
                >
                    <img
                        src="/assets/images/banners/value-chain-banner.webp"
                        alt="value chain banner"
                        className="object-cover object-center w-full h-full"
                    />
                </div>

                <div
                    data-aos={`fade-up`}
                    data-aos-delay={200}
                    className="flex justify-between md:justify-end gap-4 items-center"
                >
                    <div className="flex gap-2 items-center">
                        <button
                            onClick={() => handleOnChangeMineralCategoryId(1)}
                            title={t("CLEAN_ENERGY_MINERALS")}
                            className={`flex items-center p-2 border-2 outline-none ${selectedMineralCategoryId == 1 ? "border-cleanEnergy bg-muted" : " border-muted bg-white hover:border-cleanEnergy hover:bg-muted"}`}
                        >
                            <img
                                src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                                alt=""
                                className="w-5 h-5"
                            />
                        </button>
                        <button
                            onClick={() => handleOnChangeMineralCategoryId(2)}
                            title={t("INDUSTRIAL_MINERALS")}
                            className={`flex items-center p-2 border-2 outline-none ${selectedMineralCategoryId == 2 ? "border-industrial bg-muted" : "hover:border-industrial hover:bg-muted border-muted bg-white"}`}
                        >
                            <img
                                src="/assets/icons/industrial-color/industrial-icon.svg"
                                alt=""
                                className="w-5 h-5"
                            />
                        </button>
                    </div>
                    <div className="flex gap-2 items-center">
                        <button
                            onClick={() => handleNavigation(1)}
                            aria-label={t("NEXT")}
                            className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
                        >
                            <GrFormNext className="h-5 w-5" />
                        </button>
                        <button
                            onClick={() => handleNavigation(-1)}
                            aria-label={t("PREVIOUS")}
                            className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
                        >
                            <GrFormPrevious className="h-5 w-5" />
                        </button>
                    </div>
                </div>
                <div
                    data-aos={`fade-up`}
                    data-aos-delay={200}
                    className="mt-4"
                >
                    {isLoading ? (
                        <FutureMineralsValueChainsSliderLoading />
                    ) : (
                        <div>
                            <FutureMineralsValueChainsSlider
                                minerals={minerals}
                                futureMineralsValueChainsSliderRef={futureMineralsValueChainsSliderRef}
                                selectedMineralCategoryId={selectedMineralCategoryId}
                            />
                        </div>
                    )}
                </div>
                <FutureMineralsValueChainsDetails lng={lng} />
            </div>
        </>
    );
}
