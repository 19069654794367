import { SplideSlide } from '@splidejs/react-splide';
import { useSearchParams } from 'react-router-dom';

export default function OpportunitiesCountriesSliderItems({ countries, lng }) {

    const [searchParams, setSearchParams] = useSearchParams();

    const handleOnClickCountry = (countryId) => {
        searchParams.set("COUNTRY", countryId);
        setSearchParams(searchParams);
    }

    return countries.map((country) => (
        <SplideSlide
            className={`rounded-none mt-2 cursor-pointer`}
        >
            <div
                onClick={() => handleOnClickCountry(country.id)}
                className={`flex items-center h-full flex-col space-y-4 transform z-50 px-1 py-4 border-2 ${searchParams.get("COUNTRY") == country.id ? " border-secondary text-secondary bg-light" : "hover:border-secondary hover:text-secondary hover:bg-light border-transparent"} `}
            >
                <div>
                    <img
                        className="border-2 border-muted h-[64px] tv:h-[128px]"
                        src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                        alt={`${country[`name_${lng}`]} flag`}
                        aria-hidden={true}
                    />
                </div>
                <h3 className="text-center font-semibold">
                    {country[`name_${lng}`]}
                </h3>
            </div>
        </SplideSlide>
    ));
}
