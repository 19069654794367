import React, { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts'; // Import echarts to access `graphic`
import moment from 'moment';
import { tailwindColors } from '../../config/global';
import { useSearchParams } from 'react-router-dom';
import html2canvas from 'html2canvas'; // Import html2canvas

// Function to return the appropriate style based on chart type
const getSeriesStyle = (chartType) => {
    if (chartType === 'LINE') {
        // Line chart style: Simple line with no fill
        return {
            type: 'line',
            lineStyle: {
                color: tailwindColors.secondary, // Line color
                width: 2, // Line width
            },
            areaStyle: undefined, // Ensure areaStyle is removed
        };
    } else if (chartType === 'AREA') {
        // Area chart style: Line with a gradient area fill
        return {
            type: 'line',
            lineStyle: {
                color: tailwindColors.secondary, // Line color
                width: 2,
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: `${tailwindColors.secondary}cc` }, // Top color
                    { offset: 1, color: `${tailwindColors.secondary}1a` },  // Bottom color
                ]),
            },
        };
    } else if (chartType === 'BAR') {
        // Bar chart style: Simple bars with solid color
        return {
            type: 'bar',
            itemStyle: {
                color: tailwindColors.secondary, // Bar color
            },
            areaStyle: undefined, // Ensure areaStyle is removed
        };
    }
    return {}; // Default style
};

export default function FutureMineralPricesChart({ chartRef, prices, dates, unit }) {
    const [searchParams, setSearchParams] = useSearchParams(); // Reference to the chart

    const [chartType, setChartType] = useState(searchParams.get("CHART"));


    const getChartOption = () => {

        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        const priceRange = maxPrice - minPrice;
        const safePriceRange = priceRange === 0 ? Math.abs(minPrice) || 1 : priceRange;
        const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(safePriceRange)));
        const stepSize = orderOfMagnitude / 10;
        const adjustedMin = minPrice - stepSize;
        const adjustedMax = maxPrice + stepSize;

        return {
            xAxis: {
                type: 'category',
                data: dates,
                axisLabel: {
                    color: tailwindColors.primary,
                    rotate: 30, // Rotate the labels slightly for better readability
                    formatter: (value, index) => {
                        const currentMoment = moment(value);
                        const prevMoment = index > 0 ? moment(dates[index - 1]) : null;
                        let label = currentMoment.format('MMM D'); // Default label format

                        // Add year label at the start of January or when transitioning between years
                        if (currentMoment.month() === 0) {  // Check if it's January
                            label = `${currentMoment.format('YYYY')} - ${label}`;
                        } else if (prevMoment && currentMoment.year() !== prevMoment.year()) {
                            label = `${currentMoment.format('YYYY')} - ${label}`;
                        }

                        return label;
                    },
                },
            },
            yAxis: {
                type: 'value', // Keep a linear scale but with better adjustments
                min: adjustedMin, // Set a reasonable minimum (avoid 0)
                max: adjustedMax, // Ensure a buffer above max price
                axisLabel: {
                    color: tailwindColors.primary,
                    formatter: value => {
                        // Handle formatting for small and large values
                        return value < 1 ? `$${value.toFixed(6)}` : `$${value.toFixed(2)}`;
                    },
                },
            },
            series: [
                {
                    data: prices,
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    ...getSeriesStyle(chartType),
                },
            ],
            tooltip: {
                trigger: 'axis',
                formatter: ({ 0: { value, name } }) => {
                    return `
                    <div dir="ltr" class="flex flex-col gap-1">
                      <div class="flex gap-2 items-end">
                        <div class="font-bold">Date</div>
                        <div>${moment(name).format('MMMM Do YYYY')}</div>
                      </div>
                      <div class="flex  gap-2 items-end">
                        <div class="font-bold">Price</div>
                          <div dir="ltr">$ ${Number.isInteger(value) ? value.toFixed(2) : value} / ${unit}</div>
                      </div>
                    </div>
                  `;
                },
            },
            grid: {
                left: '1%',
                right: '1%',
                bottom: '10%',
                top: '5%',
                containLabel: true,
            },
            dataZoom: {
                type: 'slider',
                backgroundColor: '#ffffff',
                fillerColor: `${tailwindColors.secondary}55`,
                dataBackground: {
                    areaStyle:
                    {
                        color: tailwindColors.muted
                    }
                },

            }
        };
    };

    useEffect(() => {
        setChartType(searchParams.get("CHART"))
    }, [searchParams])


    return (
        <div ref={chartRef} className='col-span-12 lg:col-span-9 p-2  border-2 border-muted'>
            <ReactECharts option={getChartOption()} className='!w-full !h-full' />
        </div>
    );
};