import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axiosClient from '../../config/axios-client';
import { useTranslation } from 'react-i18next';
import FutureMineralsRegionDetailsParagraphLoading from './FutureMineralsRegionDetailsParagraphLoading';

export default function FutureMineralsRegionDetailsParagraph() {
    const { lng, mineral_slug } = useParams();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const [country, setCountry] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const contentRef = useRef(null);

    const scrollToContent = () => {
        contentRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const getMineralDetails = useCallback(() => {
        const country_id = searchParams.get('country');
        if (country_id) {
            setIsLoading(true);
            axiosClient
                .get(`/platform/minerals/${mineral_slug}/region/${country_id}`)
                .then(({ data }) => {
                    setCountry(data.country);
                })
                .catch((error) => {
                })
                .finally(() => {
                    setIsLoading(false);
                    scrollToContent();  // Scroll after loading completes
                });
        }
    }, [mineral_slug, searchParams]);

    useEffect(() => {
        getMineralDetails();
    }, [getMineralDetails]);

    return (
        <div ref={contentRef} className=" scroll-mt-8">
            {isLoading ? <FutureMineralsRegionDetailsParagraphLoading /> : country ? (
                <article>
                    {country.id != 22 && <h2 className="text-xl font-bold bg-light text-primary p-4 border-2 border-b-0 ">
                        {country[`name_${lng}`]}
                    </h2>}

                    <div className="p-4 border-2  border-muted">
                        {country.id != 22 && <img
                            className="h-20 border-2 border-muted mb-4"
                            src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                            alt={`${country[`name_${lng}`]} flag`}
                        />
                        }
                        <p
                            dangerouslySetInnerHTML={{
                                __html: country.pivot[`description_${lng}`],
                            }}
                        />
                    </div>
                    {country.id != 22 &&
                        <div className="bg-light p-4 border-2 border-t-0 border-muted">
                            <div className="font-bold">{t('SOURCE')}</div>
                            <p
                                className="underline"
                                dangerouslySetInnerHTML={{
                                    __html: country.pivot[`source_${lng}`],
                                }}
                            />
                        </div>
                    }
                </article>
            ) : null}
        </div>
    );
}