import { useTranslation } from "react-i18next";
import Spacing from "../componenets/UI/Spacing";
import { useCallback, useEffect, useState } from "react";
import axiosClient from "../config/axios-client";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import OpportunitiesCountriesSlider from "../componenets/opportunities/OpportunitiesCountriesSlider";
import FutureMineralsCountriesOpportunitiesContainer from "../componenets/home/opportunities-section/FutureMineralsCountriesOpportunitiesContainer";
import FutureMineralsCountriesOpportunitiesContainerLoading from "../componenets/home/opportunities-section/FutureMineralsCountriesOpportunitiesContainerLoading";
import { Helmet } from "react-helmet";

export default function FutureMineralsCountriesOpportunitiesPage() {
    const { lng } = useParams();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [country, setCountry] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getCountryOpportunities = useCallback(() => {
        if (searchParams.get("COUNTRY") == null) {
            return;
        }
        setIsLoading(true);
        searchParams.delete("MINERAL");
        setSearchParams(searchParams);
        axiosClient
            .get(`/platform/opportunities?${searchParams.toString()}`)
            .then(({ data }) => {
                setCountry(data.country);
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, [searchParams.get("COUNTRY")]);

    useEffect(() => {
        getCountryOpportunities();
    }, [getCountryOpportunities]);

    return (
        <div className="lg:px-0 px-8 app-container">
            <Helmet>
                <title>{`APFM | ${t("OPPORTUNITIES") || "Default Opportunity Title"} : ${country ? country["name_" + lng] : ""}`}</title>
            </Helmet>
            <Spacing />
            <h2
                data-aos="fade-down"
                data-aos-delay="300"
                className="text-2xl font-black mb-4"
            >
                {t("OPPORTUNITIES")}
            </h2>
            <div
                data-aos="fade-up"
                data-aos-delay="300"
                className="h-[321px] w-full overflow-hidden mb-4"
            >
                <img
                    className={`object-cover object-bottom w-full h-full`}
                    src={`/assets/images/banners/opportunities-banner.webp`}
                    alt={"opportunities banner"}
                />
            </div>

            {/*Countries  Slider  */}
            <OpportunitiesCountriesSlider />
            {searchParams.get("COUNTRY") &&
                (isLoading ? (
                    <FutureMineralsCountriesOpportunitiesContainerLoading />
                ) : (
                    <FutureMineralsCountriesOpportunitiesContainer
                        country={country}
                        lng={lng}
                    />
                ))}
        </div>
    );
}
