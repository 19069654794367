import { useNavigate, useParams } from "react-router";
import Spacing from "../componenets/UI/Spacing";
import { IoMdArrowForward } from "react-icons/io";
import { useCallback, useEffect, useState } from "react";
import axiosClient from "../config/axios-client";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function FutureMineralsDetailsPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mineral_slug, lng } = useParams();
    const [mineral, setMineral] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleOnClickBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const getFutureMineralDetails = useCallback(() => {
        setIsLoading(true);
        axiosClient
            .get(`/platform/minerals/${mineral_slug}/details`)
            .then(({ data }) => {
                setMineral(data.mineral);
            })
            .catch((error) => {
                navigate(`/${lng}/not-found`, { replace: true });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [mineral_slug]);

    useEffect(() => {
        getFutureMineralDetails();
    }, [getFutureMineralDetails]);

    return (
        <div>
            <Helmet>
                <title>{`APFM | ${mineral ? mineral[`name_${lng}`] : ""}`}</title>
            </Helmet>
            <Spacing />
            <article className="app-container px-8 lg:px-0 text-[18px]">
                <div className="flex mb-4 gap-2 items-center">
                    <button
                        className="p-3 bg-primary border-2 border-primary text-white hover:bg-muted hover:border-secondary hover:text-primary"
                        onClick={handleOnClickBack}
                    >
                        <IoMdArrowForward className="w-6 h-6 ltr:rotate-180" />
                    </button>
                </div>
                {isLoading ? (
                    <div className="animate-pulse">
                        <div className="h-10 w-40 bg-muted mb-4" />
                        <div className="w-64 h-64 bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-2/3 bg-muted mb-16" />
                        <div className="h-10 w-40 bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-2/3 bg-muted mb-16" />
                        <div className="h-10 w-48 bg-muted mb-4" />
                        <div className="w-64 h-64 bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-2/3 bg-muted mb-16" />
                        <div className="h-10 w-40 bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-full bg-muted mb-4" />
                        <div className="h-4 w-2/3 bg-muted mb-4" />
                    </div>
                ) : (
                    <>
                        <h1 className="text-4xl font-bold">
                            {mineral[`name_${lng}`]}{" "}
                            {mineral.symbol && (
                                <>
                                    (<span className="text-secondary">{mineral.symbol}</span>)
                                </>
                            )}
                        </h1>
                        <div className="mb-2">
                            <img
                                title={mineral[`name_${lng}`]}
                                className="w-64 h-64"
                                src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
                                alt={mineral[`name_${lng}`]}
                            />
                        </div>
                        <p
                            className="mb-16"
                            dangerouslySetInnerHTML={{
                                __html: mineral[`description_${lng}`],
                            }}
                        />
                        <div className="text-justify mb-16">
                            <h2 className="text-2xl font-bold mb-2 ">
                                {t("FUTURE_MINERAL_HISTORY")} {mineral[`name_${lng}`]}
                            </h2>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: mineral[`history_description_${lng}`],
                                }}
                            />
                        </div>
                        <div className="text-justify mb-16">
                            <h2 className="text-2xl font-bold mb-2">{t("FUTURE_MINERAL_SOURCE").replace("####", mineral[`name_${lng}`])}</h2>
                            <div className="mb-2 ">
                                <img
                                    className="w-64 h-64"
                                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.source_image}`}
                                    alt="source_image"
                                />
                            </div>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: mineral[`source_description_${lng}`],
                                }}
                            />
                        </div>
                        {mineral[`compound_description_${lng}`] && (
                            <div className="text-justify">
                                <h2 className="text-2xl font-bold mb-2">{t("FUTURE_MINERAL_COMPOUNDS").replace("####", mineral[`name_${lng}`])}</h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: mineral[`compound_description_${lng}`],
                                    }}
                                />
                            </div>
                        )}
                        {mineral.studies_link && (
                            <div className={`mt-16`}>
                                <h4 className={`mb-1 font-bold`}>{t("FOR_MORE_INFORMATION")}</h4>
                                {/* Link to studies file */}
                                <Link
                                    target={`_blank`}
                                    className={`hover:underline-none hover:text-secondary`}
                                    to={mineral.studies_link}
                                >
                                    {t("DOWNLOAD_STUDIES_FILE")}
                                </Link>
                            </div>
                        )}
                    </>
                )}
            </article>
        </div>
    );
}
