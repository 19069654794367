import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom';

export default function FutureMineralsCountriesOpportunitiesGridItem({ opportunity }) {
    const { lng } = useParams();
    const { t } = useTranslation();
    return <div data-aos="fade-up" className={"col-span-12 md:col-span-6 border-2 border-muted"} >
        <h3 title={opportunity[`title_${lng}`]} className="p-4 px-6 bg-light font-bold select-all border-b-2 border-muted line-clamp-1">
            {opportunity[`title_${lng}`]}
        </h3>
        <ul className="p-4 px-6 flex flex-col gap-4 group-hover:bg-muted/10 transform transition duration-300 ease-in-out">
            <li className="flex flex-row space-x-2">
                <p>
                    <span className="font-bold"> {t("ROW_MENIRALS")} : </span>
                    <span>{opportunity[`raw_materials_${lng}`]}</span>
                </p>
            </li>
            <li className="flex flex-row space-x-2">
                <p>
                    <span className="font-bold">{t("DATE")}  : </span>
                    <span>
                        {
                            opportunity.started_at == null
                                ? t("NOT_SPECIFIED")
                                : new Date(opportunity.started_at).getFullYear()
                        }
                    </span>
                </p>
            </li>
            <li className="flex flex-row space-x-2">
                <p>
                    <span className="font-bold">{t("AREA")} : </span>
                    <span>
                        {
                            opportunity.area == null
                                ? t("NOT_SPECIFIED")
                                : opportunity.area
                        }
                    </span>
                </p>
            </li>
            <li className="flex flex-row space-x-2">
                <p>
                    <span className="font-bold">{t("TYPE")} : </span>
                    <span>{opportunity[`type_${lng}`]}</span>
                </p>
            </li>
        </ul>
        <div className="border-t-2 border-muted">
            <AdditionalLink link={opportunity?.attachment_link} label={t("ADDITIONAL_INFORMATION")} />
        </div>
    </div>
}



const AdditionalLink = ({ link, label }) => {
    if (link == null) {
        return <p className="flex justify-center py-4 px-4 w-full font-semibold text-dark bg-light cursor-default">
            {label}
        </p>
    }
    return <Link
        target="_blank"
        to={link}
        className="flex justify-center py-4 px-4 w-full font-semibold text-dark bg-secondary/40 hover:bg-secondary/50 transform transition duration-300 ease-in-out"
    >
        {label}
    </Link>

}
