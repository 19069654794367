import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const RegionMapPage = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Helmet>
                <title>{`APFM | ${t("REGION_MAP")}`}</title>
            </Helmet>
            <iframe
                title="الخريطة العربية"
                className="h-screen w-full"
                src="https://gis.aidmo.org/portal/apps/webappviewer/index.html?id=8ec7ff8605e046459da6ac0643c3f440"
            ></iframe>
        </div>
    );
};

export default RegionMapPage;
