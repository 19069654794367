import React from "react";
import Spacing from "../componenets/UI/Spacing";
import { useTranslation } from "react-i18next";
import CleanEnergyTechnolgiesGridCard from "../componenets/technolgies/CleanEnergyTechnolgiesGridCard";
import { Helmet } from "react-helmet";

export default function FutureMineralsCleanEnergyTechnologiesPage() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{`APFM | ${t("CLEAN_ENERGY_TECHNOLOGIES")}`}</title>
            </Helmet>
            <Spacing />
            <div className="app-container lg:px-0 px-8">
                <h2
                    data-aos={`fade-down`}
                    data-aos-delay={100}
                    className="text-2xl font-black mb-4"
                >
                    {t("CLEAN_ENERGY_TECHNOLOGIES")}
                </h2>

                <div
                    data-aos={`fade-up`}
                    data-aos-delay={100}
                    className="grid grid-cols-12 gap-4 w-full"
                >
                    <CleanEnergyTechnolgiesGridCard
                        title={t("SOLAR_ENERGY")}
                        link={`solar-energy`}
                        image={`/assets/images/technologies/solar-energy/solar-energy.webp`}
                    />
                    <CleanEnergyTechnolgiesGridCard
                        title={t("WIND_ENERGY")}
                        link={`wind-energy`}
                        image={`/assets/images/technologies/wind-energy/wind-energy.webp`}
                    />
                    <CleanEnergyTechnolgiesGridCard
                        title={t("BATTERY")}
                        link={`batteries`}
                        image={"/assets/images/technologies/batteries/battery.webp"}
                    />
                    <CleanEnergyTechnolgiesGridCard
                        title={t("ELECTRICAL_GRID")}
                        link={`electricity-networks`}
                        image={"/assets/images/technologies/electricity-networks/electricity-networks.webp"}
                    />
                    <CleanEnergyTechnolgiesGridCard
                        title={t("HYDROGEN")}
                        link={`hydrogen`}
                        image={"/assets/images/technologies/hydrogen/hydrogen.webp"}
                    />
                    <CleanEnergyTechnolgiesGridCard
                        title={t("ELECTRIC_VEHICLES")}
                        link={`electric-vehicles`}
                        image={"/assets/images/technologies/electric-vehicles/electric-vehicles.webp"}
                    />
                </div>
            </div>
        </>
    );
}
