import { SplideSlide } from '@splidejs/react-splide';
import React, { useRef, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import HomeFutureMineralsPricesChange from './HomeFutureMineralsPricesChange';
import moment from 'moment';
import { getPriceChangeValue } from '../../../config/global';
import { Link } from 'react-router-dom';

export default function HomeFutureLastMonthPricesChart({ mineral, lng }) {
  const chartRef = useRef(null);

  // Force chart resize after mounting
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
  }, []);

  // Prepare the chart option for the single chart
  const getChartOption = (prices, dates, change, unit) => {
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const priceRange = maxPrice - minPrice;
    const safePriceRange = priceRange === 0 ? Math.abs(minPrice) || 1 : priceRange;
    const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(safePriceRange)));
    const stepSize = orderOfMagnitude / 10;
    const adjustedMin = minPrice - stepSize;
    const adjustedMax = maxPrice + stepSize;

    return {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dates,
        show: false,
      },
      yAxis: {
        type: 'value',
        min: adjustedMin,
        max: adjustedMax,
        interval: stepSize,
        splitNumber: 5,
        show: false,
      },
      series: [
        {
          data: prices,
          type: 'line',
          smooth: true, // Enable smooth line
          symbol: 'none', // Ensure no markers on data points
          areaStyle: {
            color: `${getPriceChangeValue(change)}5a`,
          },
          lineStyle: {
            color: getPriceChangeValue(change),
          },
        },
      ],
      tooltip: {
        trigger: 'axis',
        formatter: ({ 0: { value, name } }) => {
          return `
            <div class="flex flex-col gap-1">
              <div class="flex flex-row-reverse gap-2 items-end">
                <div class="font-bold">Date</div>
                <div>${moment(name).format('MMMM Do YYYY')}</div>
              </div>
              <div class="flex flex-row-reverse gap-2 items-end">
                <div class="font-bold">Price</div>
                  <div dir="ltr">$ ${Number.isInteger(value) ? value.toFixed(2) : value} / ${unit}</div>
              </div>
            </div>
          `;
        },
      },
      grid: {
        left: '0%',
        right: '0%',
        bottom: '0%',
        top: '10%',
        containLabel: false,
      },
    };
  };

  return (
    <SplideSlide key={mineral.id} className="bg-white overflow-hidden border-2 border-muted text-center relative hover:bg-muted hover:border-secondary cursor-pointer">
      <Link to={`/${lng}/prices/${mineral.slug}?CHART=AREA&INTERVAL=MONTH`} className='flex flex-col justify-between p-2' >
        <div className='flex ltr:flex-row-reverse justify-between items-start'>
          <div className='flex ltr:flex-row-reverse rtl:text-start ltr:text-end gap-2'>
            <div className='bg-light p-1 border-2 border-muted'>
              <img className='w-10' src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`} alt={mineral[`name_${lng}`]} />
            </div>
            <div className='font-bold'>
              <div>{mineral[`name_${lng}`].replace("(", "").replace(")", "")}</div>
              <div className='text-secondary'>{mineral.price} $</div>
            </div>
          </div>
          <HomeFutureMineralsPricesChange change={mineral.change} />
        </div>
        <div className='flex !h-full mt-4'>
          <ReactECharts
            ref={chartRef}
            option={getChartOption(mineral.prices, mineral.dates, mineral.change, mineral[`unit_symbol`])}
            className='!max-h-32 w-full'
          />
        </div>
      </Link>
    </SplideSlide>
  );
}