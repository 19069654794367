import Hero from "../componenets/home/hero/Hero";
import HomeArabicMapSection from "../componenets/home/map/HomeArabMapSection";
import HomeMineralsCountriesSection from "../componenets/home/future-minerals-countries-section/HomeFutureMineralsCountriesSection";
import HomeOpportunitiesSection from "../componenets/home/opportunities-section/HomeOpportunitiesSection";
import HomePlatformMessageSection from "../componenets/home/platform-message/HomePlatformMessageSection";
import PlatformNumbersSection from "../componenets/home/platform-numbers/PlatformNumbersSection";
import HomeFutureLastMonthPricesSection from "../componenets/home/future-minerals-last-month-prices/HomeFutureLastMonthPricesSection";
import HomeMineralsSection from "../componenets/home/future-minerals-section/HomeMineralsSection";
import HomeOverviewSection from "../componenets/home/overview-section/HomeOverviewSection";
import SponsorDiamondBanner from "../componenets/sponsor/SponsorDiamondBanner";
import SponsorDiamondSlider from "../componenets/sponsor/SponsorDiamondSlider";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Home() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{`APFM | ${t("PLATFORM_NAME")}`}</title>
            </Helmet>
            <Hero />
            <PlatformNumbersSection />
            <SponsorDiamondBanner isHome={true} />
            <HomePlatformMessageSection />
            <HomeMineralsSection />
            <HomeMineralsCountriesSection />
            <HomeOpportunitiesSection />
            <HomeFutureLastMonthPricesSection />
            <HomeArabicMapSection />
            <HomeOverviewSection />
            <SponsorDiamondSlider />
        </>
    );
}
