import { Link } from "react-router-dom";
import CleanEnergyMineralIcon from "../../icons/CleanEnergyMineralIcon";
import IndustrialMineralsIcon from "../../icons/IndustrialMineralsIcon";

export default function HomeOverviewGridCard({ title, image, clean_energy_item, industrial_item, icon }) {
    return <div data-aos="fade-down" className="col-span-12 sm:col-span-6 lg:col-span-3 group h-[360px] lg:h-[260px] relative overflow-hidden border-2 border-primary">
        <img src={image} alt={title} className="object-cover w-full h-full absolute group-hover:scale-110 transition duration-300 ease-in-out " />
        <div className="absolute w-full h-full bg-gradient-to-t from-primary to-transparent group-hover:to-primary/45 group-hover:via-primary/70 " />

        <div className="absolute bottom-0 rtl:right-0 ltr:left-0 text-white py-4 px-6 block opacity-100  group-hover:opacity-0 ">
            <p className="font-bold">{title}</p>

        </div>

        <div className="absolute top-0 text-white py-4 px-6  transition group-hover:translate-y-0 -translate-y-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform">
            <p className="font-bold">{title}</p>
        </div>
        <div className="absolute top-2 rtl:left-2 ltr:right-2 z-10 w-32 h-32 lg:w-16 lg:h-16 p-2 transition group-hover:translate-y-0 -translate-y-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform">
            <img src={icon} alt={title} />
        </div>
        <div className="absolute bottom-0 left-0 right-0 text-white p-4 transition group-hover:translate-y-0 translate-y-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform">
            <div class="flex flex-col gap-2 font-bold ">
                {
                    clean_energy_item
                    &&
                    <Link to={clean_energy_item.link} className="flex hover:bg-cleanEnergy hover:text-white bg-white text-cleanEnergy border-2 border-cleanEnergy items-center">
                        <div className="bg-cleanEnergy p-2">
                            <CleanEnergyMineralIcon size={22} color={"#ffffff"} />
                        </div>
                        <div className="rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 whitespace-nowrap">{clean_energy_item.label}</div>
                    </Link>
                }
                {industrial_item
                    &&
                    industrial_item.link ?
                    <Link to={industrial_item.link} className="flex border-2 border-industrial hover:bg-industrial hover:text-white bg-white text-industrial items-center">
                        <div className="bg-industrial p-2">
                            <IndustrialMineralsIcon size={22} color={"#ffffff"} />
                        </div>
                        <div className="rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 whitespace-nowrap">{industrial_item.label}</div>
                    </Link> :
                    <div className="flex border-2 border-industrial/20  bg-white/20 text-industrial/70 items-center cursor-default">
                        <div className="bg-industrial/20 p-2">
                            <IndustrialMineralsIcon size={22} color={"#ffffff"} />
                        </div>
                        <div className="rtl:pr-4 rtl:pl-8 ltr:pr-8 ltr:pl-4 whitespace-nowrap">{industrial_item.label}</div>
                    </div>
                }
            </div>
        </div>
    </div>
}
