import React from "react";
import { useParams } from "react-router";

export default function Error404Page() {
    const { lng } = useParams();
    return (
        <div className="min-h-screen w-full flex ">
            <div className="m-auto text-center">
                <h1 className="text-9xl font-bold mb-2">404</h1>
                <p className="text-2xl uppercase">{lng == "en" ? "oops! nothing was found" : "عفوًا! لم يتم العثور على شيء"}</p>
            </div>
        </div>
    );
}
