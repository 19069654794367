import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

export default function SponsorDiamondBanner({ isHome }) {
  const { lng } = useParams()
  const { t } = useTranslation()

  return (
    <div
      data-aos="fade-up"
      className={`app-container px-8 lg:px-0 ${isHome ? 'pb-16' : 'pt-16'}`}
    >
      <Link
        to={`/${lng}/sponsors/fnrc`}
        className="flex flex-col items-start justify-start"
      >
        <div
          title={t('SPONSOR_DIAMOND')}
          className="max-h-[276px] overflow-hidden border-2 border-info bg-muted"
        >
          <img
            src="/assets/sponsors/fnrc.jpg"
            alt={lng == 'ar' ? 'مؤسسة الفجيرة للموارد الطبيعية' : 'fujairah natural resources corporation'}
          />
        </div>
      </Link>
    </div>
  )
}
