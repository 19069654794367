import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import axiosClient from '../config/axios-client'
import Spacing from '../componenets/UI/Spacing'
import Pagination from '../componenets/UI/Pagination'
import FutureMineralCountriesCard from '../componenets/global/FutureMineralCountriesCard'
import FutureMineralsRegionGridLoading from '../componenets/future-minerals/FutureMineralsRegionGridLoading'
import { Helmet } from 'react-helmet'

export default function FutureMineralsRegionPage() {
  const { t } = useTranslation()
  const { lng } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  const searchQueryRef = useRef(null)

  const [minerals, setMinerals] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [pagination, setPagination] = useState(null)

  const [selectedMineralCategoryId, setSelectedMineralCategoryId] = useState(searchParams.get('category'))

  const handleOnChangeMineralCategoryId = useCallback(
    (id) => {
      searchParams.set('page', 1)
      searchQueryRef.current.value = ''
      searchParams.delete('search_query')
      if (id == selectedMineralCategoryId) {
        // Reset the category if the same id is clicked
        searchParams.delete('category')
        setSelectedMineralCategoryId(0)
      } else {
        searchParams.set('category', id)
        setSelectedMineralCategoryId(id)
      }
      setSearchParams(searchParams)
    },
    [searchParams, selectedMineralCategoryId, setSearchParams],
  )

  const getFutureMinerals = useCallback(() => {
    window.scrollTo(0, 0)
    setIsLoading(true)
    axiosClient
      .get(`/platform/minerals/region?${searchParams.toString()}`)
      .then(({ data }) => {
        setMinerals(data.minerals)

        setPagination({
          links: data.links,
          current_page: data.current_page,
          first_page: data.first_page,
          last_page: data.last_page,
        })
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [searchParams])

  useEffect(() => {
    getFutureMinerals()
  }, [getFutureMinerals])

  const handleOnSumitSearch = (event) => {
    event.preventDefault()
    if (searchQueryRef.current.value.length != 0) {
      searchParams.set('search_query', searchQueryRef.current.value)
      setSearchParams(searchParams)
    }
  }
  const handleOnChangeSearchQueryInput = (event) => {
    const inputValue = event.target.value
    if (inputValue.length == 0) {
      searchParams.set('page', 1)
      searchParams.delete('search_query')
      setSearchParams(searchParams)
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const categoryId = query.get('category')
    setSelectedMineralCategoryId(categoryId || '')
  }, [location])


  return (
    <>
      <Helmet>
        <title>{`APFM | ${t('FUTURE_MINERALS_REGION')}`}</title>
      </Helmet>
      <Spacing />
      <div className="app-container px-8 lg:px-0">
        <div className="mb-4">
          <h2
            data-aos={`fade-down`}
            data-aos-delay={100}
            className="text-2xl font-black"
          >
            {t('FUTURE_MINERALS_REGION')}
          </h2>
        </div>
        <div
          data-aos={`fade-up`}
          data-aos-delay={100}
          className="mb-4 flex gap-2"
        >
          <div className="flex items-center gap-2">
            <button
              onClick={() => handleOnChangeMineralCategoryId(1)}
              title={t('CLEAN_ENERGY_MINERALS')}
              className={`flex items-center border-2 p-2 outline-none ${selectedMineralCategoryId == 1 ? 'border-cleanEnergy bg-muted' : 'border-muted bg-white hover:border-cleanEnergy hover:bg-muted'}`}
            >
              <img
                src="/assets/icons/clean-energy-color/clean-energy-icon.svg"
                alt=""
                className="h-5 min-h-5 w-5 min-w-5"
              />
            </button>
            <button
              onClick={() => handleOnChangeMineralCategoryId(2)}
              title={t('INDUSTRIAL_MINERALS')}
              className={`flex items-center border-2 p-2 outline-none ${selectedMineralCategoryId == 2 ? 'border-industrial bg-muted' : 'border-muted bg-white hover:border-industrial hover:bg-muted'}`}
            >
              <img
                src="/assets/icons/industrial-color/industrial-icon.svg"
                alt=""
                className="h-5 min-h-5 w-5 min-w-5"
              />
            </button>
          </div>
          <form
            className="flex w-full gap-2"
            onSubmit={handleOnSumitSearch}
          >
            <input
              onChange={handleOnChangeSearchQueryInput}
              type="search"
              ref={searchQueryRef}
              className="w-full border-2 border-muted px-4 py-1.5 text-primary outline-none"
            />
            <button
              type="submit"
              className="border-2 border-primary bg-primary px-6 text-white hover:border-secondary hover:bg-muted hover:text-secondary"
            >
              {t('SEARCH')}
            </button>
          </form>
        </div>

        <div
          data-aos={`fade-up`}
          data-aos-delay={200}
          className="mb-4 grid grid-cols-12 gap-4"
        >
          {isLoading ? (
            <FutureMineralsRegionGridLoading />
          ) : (
            minerals?.map((mineral) => (
              <FutureMineralCountriesCard
                isGridChild={true}
                mineral={mineral}
                link={`/${lng}/future-minerals/region/${mineral.slug}${mineral.slug == 'altylyryom-tellurium' ? '?country=22' : ''}`}
                key={mineral.id}
                lng={lng}
                isMarqueeSlider={false}
              />
            ))
          )}
        </div>
        {!isLoading && <Pagination pagination={pagination} />}
      </div>
    </>
  )
}
