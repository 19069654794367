import OpportunitiesMineralsSlider from '../../opportunities/OpportunitiesMineralsSlider';
import FutureMineralsCountriesOpportunitiesGrid from '../../opportunities/FutureMineralsCountriesOpportunitiesGrid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GrInProgress } from 'react-icons/gr';
import { Link } from 'react-router-dom';

export default function FutureMineralsCountriesOpportunitiesContainer({ country, lng }) {

    const { t } = useTranslation();
    const [opportunitiesCount, setOpportunitiesCount] = useState(0);

    if (!country.opportunities || country.opportunities.length == 0)
        return <div className="mt-8">
            <h2 className="text-3xl font-black mb-4">
                {country[`name_${lng}`]}
            </h2>
            <div
                className={`flex items-center justify-center border-2 border-muted bg-light p-8 h-[256px] col-span-12`}
            >
                <div className={`flex flex-col items-center space-y-6`}>
                    <GrInProgress size={40} />
                    <h2 className={`font-bold text-xl`}>
                        {t("DATA_LOADING_IN_PROGRESS")}
                    </h2>
                </div>
            </div>
        </div>

    else
        return (
            <div className="mt-8">
                <div className="mb-4">
                    <h2 data-aos-delay="300" data-aos="fade-up" className="text-3xl font-black mb-4">
                        {country[`name_${lng}`]}
                    </h2>
                    <OpportunitiesMineralsSlider
                        minerals={country.minerals}
                    />
                    <div data-aos-delay="300" data-aos="fade-up" className="flex items-center sm:items-end gap-4">
                        <span className="text-8xl font-bold bg-primary inline-block py-1 px-6 text-white select-none">
                            {opportunitiesCount}
                        </span>
                        <h3 className="text-4xl leading-relaxed font-black text-secondary">
                            {t("INVESTMENT_OPPORTUNITIES_CURRENTLY_AVAILABLE")}
                        </h3>
                    </div>
                </div>
                <FutureMineralsCountriesOpportunitiesGrid opportunities={country.opportunities} onChangeCount={(count) => setOpportunitiesCount(count)} />
                {country.source &&

                    <div className=" mt-16">
                        <h4 className="mb-1 font-bold">{t('SOURCE')}</h4>
                        <Link
                            target="_blank"
                            className="underline hover:text-secondary"
                            to={country.source.link}
                        >
                            {country.source[`source_${lng}`]}
                        </Link>
                    </div>
                }
            </div>
        )
}
