import React from 'react'

export default function FutureMineralsRegionDetailsParagraphLoading() {
    return (
        <div className="animate-pulse">
            <div className="h-16 bg-light border-2 border-muted text-white p-4" />
            <div className="p-4 border-2 border-y-0 border-muted">
                <div className="h-20 w-[120px] bg-muted mb-4" />
                <div className="h-4 w-full bg-muted mb-4" />
                <div className="h-4 w-full bg-muted mb-4" />
                <div className="h-4 w-full bg-muted mb-4" />
                <div className="h-4 w-2/3 bg-muted mb-4" />
            </div>
            <div className="bg-light p-4 border-2 border-muted">
                <div className="mb-2 font-bold">
                    <div className="h-6 w-20 bg-muted mb-4" />
                </div>
                <div className="h-4 w-2/3 bg-muted my-4" />
                <div className="h-4 w-2/3 bg-muted mb-4" />
            </div>
        </div>
    )
}
