import Marquee from 'react-fast-marquee';
import FutureMineralCard from './FutureMineralCard';

export default function FutureMineralCountriesCard({ mineral, lng, link, isGridChild, isMarqueeSlider = true }) {
    const isArabic = lng === 'ar';
    const hasCountries = mineral.countries && mineral.countries.length > 0;
    const marqueeDirection = isArabic ? 'left' : 'right';

    const renderCountries = (countries, direction) => {
        const countryList = direction === 'reverse' ? countries : [...countries].reverse();

        return countryList.map((country) => (
            <img
                key={`${mineral.id}-${country.id}`}
                className="h-[32px] border-2 mr-2"
                src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                alt={country[`name_${lng}`]}
            />
        ));
    };

    return (
        <FutureMineralCard mineral={mineral} lng={lng} link={link} isGridChild={isGridChild}>
            <div dir="ltr" className="bg-muted py-1 z-10 group-hover:bg-light">
                {mineral.slug !== 'altylyryom-tellurium' ? (
                    hasCountries && (
                        <Marquee speed={15} play={true} direction={marqueeDirection}>
                            {isArabic ? renderCountries(mineral.countries, 'normal') : renderCountries(mineral.countries, 'reverse')}
                        </Marquee>
                    )
                ) : (
                    <div className="h-[40px]" />
                )}
            </div>
        </FutureMineralCard>
    );
}