import React from "react";

export default function HeroMessageEn() {
  return (
    <p className="text-center text-3xl text-white md:text-left lg:p-0 px-8 md:text-5xl  font-bold !leading-[2.5] md:!leading-[2]">
      First Information & Interactive Platform for
      <br />
      <span className="text-primary font-black text-5xl md:text-6xl">
        {" "}
        FUTURE{" "}
      </span>
      <span className="text-secondary font-black text-5xl md:text-6xl">
        {" "}
        MINERALS{" "}
      </span>
      <br />
      In the Arab Region
    </p>
  );
}
