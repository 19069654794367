import React from 'react'

export default function HomeFutureLastMonthPricesSliderLoading() {
    return (
        <div className='grid grid-cols-12 gap-4'>
            <div className="col-span-12 lg:col-span-4 border-2 border-muted p-2 animate-pulse max-h-[214px]">
                <div className='flex ltr:flex-row-reverse justify-between items-start mb-4'>
                    <div className='flex ltr:flex-row-reverse rtl:text-start ltr:text-end gap-2'>
                        <div className='bg-light w-10 h-10 border-2 border-muted' />
                        <div className='space-y-1'>
                            <div className='h-4 w-20 bg-muted' />
                            <div className='h-4 w-12 bg-muted' />
                        </div>
                    </div>
                    <div className='flex ltr:flex-row-reverse justify-between border-2 bg-muted/20 border-muted bg-muted text-muted whitespace-nowrap'>
                        <div className='h-6 w-14' />
                        <div className='h-6 w-6 text-white bg-muted' />
                    </div>
                </div>
                <img alt='chart' src='/assets/icons/chart.svg' className='w-full h-[128px] object-cover object-top ' />

            </div>
            <div className="hidden lg:block col-span-4 border-2 border-muted p-2 animate-pulse max-h-[214px]">
                <div className='flex ltr:flex-row-reverse justify-between items-start mb-4'>
                    <div className='flex ltr:flex-row-reverse rtl:text-start ltr:text-end gap-2'>
                        <div className='bg-light w-10 h-10 border-2 border-muted' />
                        <div className='space-y-1'>
                            <div className='h-4 w-20 bg-muted' />
                            <div className='h-4 w-12 bg-muted' />
                        </div>
                    </div>
                    <div className='flex ltr:flex-row-reverse justify-between border-2 bg-muted/20 border-muted bg-muted text-muted whitespace-nowrap'>
                        <div className='h-6 w-14' />
                        <div className='h-6 w-6 text-white bg-muted' />
                    </div>
                </div>
                <img alt='chart' src='/assets/icons/chart.svg' className='w-full h-[128px] object-cover object-top ' />

            </div>
            <div className="hidden lg:block col-span-4 border-2 border-muted p-2 animate-pulse max-h-[214px]">
                <div className='flex ltr:flex-row-reverse justify-between items-start mb-4'>
                    <div className='flex ltr:flex-row-reverse rtl:text-start ltr:text-end gap-2'>
                        <div className='bg-light w-10 h-10 border-2 border-muted' />
                        <div className='space-y-1'>
                            <div className='h-4 w-20 bg-muted' />
                            <div className='h-4 w-12 bg-muted' />
                        </div>
                    </div>
                    <div className='flex ltr:flex-row-reverse justify-between border-2 bg-muted/20 border-muted bg-muted text-muted whitespace-nowrap'>
                        <div className='h-6 w-14' />
                        <div className='h-6 w-6 text-white bg-muted' />
                    </div>
                </div>
                <img alt='chart' src='/assets/icons/chart.svg' className='w-full h-[128px] object-cover object-top ' />

            </div>
        </div>
    )
}
