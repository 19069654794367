import { Splide } from '@splidejs/react-splide';
import React from 'react'
import { useParams } from 'react-router';
import FutureMineralCard from '../global/FutureMineralCard';
import { homeMineralsOptions } from '../../config/splide-slide-options';

export default function FutureMineralsValueChainsSlider({ minerals, futureMineralsValueChainsSliderRef, selectedMineralCategoryId }) {
    const { lng } = useParams();

    const filteredMinerals = selectedMineralCategoryId == 0
        ? minerals
        : minerals?.filter(mineral => mineral.category_id == selectedMineralCategoryId);

    return <Splide
        ref={futureMineralsValueChainsSliderRef}
        aria-label={`Future Minerals Valua Chain Slider`}
        options={homeMineralsOptions}
    >
        {filteredMinerals?.map((mineral) => <FutureMineralCard mineral={mineral} hasLink={false} lng={lng} />)}
    </Splide>
}
