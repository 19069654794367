import { useCallback, useEffect, useRef, useState } from "react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { Link, useParams } from "react-router-dom";
import axiosClient from "../../../config/axios-client";
import { useTranslation } from "react-i18next";

import HomeFutureMineralsCountriesSilder from "./HomeFutureMineralsCountriesSilder";
import HomeFutureMineralsCountriesSilderLoading from "./HomeFutureMineralsCountriesSilderLoading";

export default function HomeFutureMineralsCountriesSection() {
    const { lng } = useParams();
    const { t } = useTranslation();
    const futureMineralsCountriesSliderRef = useRef(null);

    const [minerals, setMinerals] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMineralCategoryId, setSelectedMineralCategoryId] = useState(0);

    const handleOnChangeMineralCategoryId = useCallback((id) => {
        setSelectedMineralCategoryId(prevId => (id === prevId ? 0 : id));
    }, []);

    const handleNavigation = useCallback((direction) => {
        const adjustment = (lng === "en") ? (direction === 1 ? "+1" : "-1") : (direction === 1 ? "-1" : "+1");
        futureMineralsCountriesSliderRef.current.splide.go(adjustment);
    }, [lng]);



    const getFutureMineralsRegionHome = useCallback(() => {
        setIsLoading(true);
        axiosClient
            .get('/platform/minerals/region/home')
            .then(({ data }) => {
                setMinerals(data.minerals);
            })
            .catch((error) => {

            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    useEffect(() => {
        getFutureMineralsRegionHome();
    }, [getFutureMineralsRegionHome]);

    return <div className="bg-light">
        <div className="app-container px-8 py-16 lg:px-0">
            <div className="flex gap-4 flex-col">
                <div data-aos="fade-down" className="flex flex-col gap-2 md:gap-2 md:flex-row md:items-center justify-between">
                    <h2 className='text-2xl font-black'>
                        {t("FUTURE_MINERALS_REGION")}
                    </h2>
                    <div className="flex justify-between gap-4 items-center">
                        <div className="flex gap-2 items-center">
                            <button 
                            onClick={() => handleOnChangeMineralCategoryId(1)} 
                            title={t("CLEAN_ENERGY_MINERALS")}
                            className={`flex items-center p-2 border-2 outline-none ${selectedMineralCategoryId === 1 ? "border-cleanEnergy bg-muted" : " border-muted bg-white hover:border-cleanEnergy hover:bg-muted"}`}
                            >
                                <img src='/assets/icons/clean-energy-color/clean-energy-icon.svg' alt="" className="w-5 h-5" />
                            </button>
                            <button onClick={() => handleOnChangeMineralCategoryId(2)} 
                                title={t("INDUSTRIAL_MINERALS")} 
                                className={`flex items-center p-2 border-2 outline-none ${selectedMineralCategoryId === 2 ? "border-industrial bg-muted" : "hover:border-industrial hover:bg-muted border-muted bg-white"}`}>
                                <img src='/assets/icons/industrial-color/industrial-icon.svg' alt="" className="w-5 h-5" />
                            </button>
                        </div>
                        <div className="flex gap-2 items-center">
                            <button
                                onClick={() => handleNavigation(1)}
                                aria-label={t("NEXT")}
                                className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
                            >
                                <GrFormNext className="h-5 w-5" />
                            </button>
                            <button
                                onClick={() => handleNavigation(-1)}
                                aria-label={t("PREVIOUS")}
                                className="flex ltr:rotate-180 items-center p-2 border-2 border-muted hover:border-secondary hover:bg-light bg-white"
                            >
                                <GrFormPrevious className="h-5 w-5" />
                            </button>
                            <Link title={t("MORE")} to={`/${lng}/future-minerals/region`} className="flex select-none items-center px-3 py-1.5 border-2 border-muted hover:border-secondary hover:bg-light bg-white">
                                {t("MORE")}
                            </Link>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up">
                    {
                        isLoading
                            ? <HomeFutureMineralsCountriesSilderLoading />
                            : <HomeFutureMineralsCountriesSilder minerals={minerals} selectedMineralCategoryId={selectedMineralCategoryId} futureMineralsCountriesSliderRef={futureMineralsCountriesSliderRef} />
                    }
                </div>
            </div>
        </div>

    </div>;
}
