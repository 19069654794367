import { Splide } from "@splidejs/react-splide";
import { homeMineralsOptions } from "../../../config/splide-slide-options";
import { useParams } from "react-router";
import FutureMineralCard from "../../global/FutureMineralCard";




export default function HomeFutureMineralsSilder({ minerals, futureMineralsSliderRef, selectedMineralCategoryId }) {
    const { lng } = useParams();

    const filteredMinerals = selectedMineralCategoryId === 0
        ? minerals
        : minerals?.filter(mineral => mineral.category_id === selectedMineralCategoryId);

    return (
        <Splide
            ref={futureMineralsSliderRef}
            aria-label={`Future Minerals Slider`}
            options={homeMineralsOptions}
        >
            {filteredMinerals?.map((mineral) => <FutureMineralCard mineral={mineral} lng={lng} link={`/${lng}/future-minerals/${mineral.slug}`} />)}
        </Splide>
    );
}